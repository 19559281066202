import axios from '@/utils/axios';

export interface IDeviceIdConfiguration {
	sdkKey: string;
	enabled: boolean;
}

const getDeviceIdConfiguration = async (): Promise<IDeviceIdConfiguration> => {
	try {
		const { data: deviceConfiguration } = await axios.get<IDeviceIdConfiguration>(
			`${process.env.BASE_URL}api/Application/Configuration/DeviceId`
		);

		return deviceConfiguration;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error; 
	}
};

export default { getDeviceIdConfiguration };
export { getDeviceIdConfiguration };
