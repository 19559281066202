<template>

    <OaoButton
        :title="title"
        type="plain"
        buttonType="button"
        @click="$emit('click')"
        fullwidth
    >
        <p class="button-container">
            <span>{{ title }}</span>
            <BaseIcon
                height="3rem"
                width="3rem"
                name="Add"
                viewBox="0 0 18 18"
            >
                <AddIcon/>
            </BaseIcon>            
        </p>
    </OaoButton>

</template>




<script>

    import { BaseIcon, AddIcon } from '@/components/icons';
    import OaoButton from './OaoButton';

    export default {

        name: 'AddButton',
        components: {

            BaseIcon,
            AddIcon,
            OaoButton

        },
        props: {

            title: {
                type: String,
                required: true
            }

        }

    }

</script>





<style lang="scss" scoped>

    .button-container {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        padding: 0 0.9375rem;

        span { flex: 1; }
    }

</style>