import { applicantAPI } from '@/api';
import { getCleanApplicant } from '@/utils';
import * as Sentry from "@sentry/vue";

const state = {

    applicantId: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: ''

};

function resetState(state) {

    state.applicantId = 0;
    state.firstName = '';
    state.lastName = '';
    state.emailAddress = '';
    state.phoneNumber = '';

};

const mutations = {

    setApplicant(state, { firstName, lastName, emailAddress, phoneNumber }) {

        state.firstName = firstName;
        state.lastName = lastName;
        state.emailAddress = emailAddress;
        state.phoneNumber = phoneNumber;

    }

};

const actions = {

    async getApplicant({ commit, state }) {

        const applicantId = state.applicantId;

        if (!state.applicantId) { return getCleanApplicant() };

        try {

            const applicant = await applicantAPI.getApplicant(applicantId);

            commit('setApplicant', applicant);

            return applicant;

        } catch (error) {
            throw error;
        };

    },
    async updateApplicant({ commit, rootState }, applicant) {

        try {

            const applicationId = rootState.application.applicationId;

            const { emailVerificationResult } = await applicantAPI.updateApplicant({
                applicationId,
                applicant
            });
            
            commit('setApplicant', applicant);

            return {
                applicant: applicant,
                emailVerificationResult: emailVerificationResult
            };

        } catch (error) {
            throw error;
        };

    }

};

export {

    state,
    resetState,
    mutations,
    actions

};

export default {

    state,
    resetState,
    mutations,
    actions

};