import { axios } from '@/utils/axios';

const getDocumentUploadConfiguration = async () => {
	try {
		const {
			data: { documentUploadConfiguration }
		} = await axios.get(`${process.env.BASE_URL}api/Configuration/Document`);

		return { documentUploadConfiguration };
	} catch (error) {
		throw error;
	}
};

const uploadDocuments = async (applicationId, applicantType, data) => {
	let endpoint = `${process.env.BASE_URL}api/Application/${applicationId}/ApplicantType/${applicantType}/Document`;
	const {
		data: { posted, hasDuplicate }
	} = await axios.post(endpoint, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return { posted, hasDuplicate };
};

export default {
	getDocumentUploadConfiguration,
	uploadDocuments
};

export { getDocumentUploadConfiguration, uploadDocuments };
