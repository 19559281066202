export default {
    params: ['years'],
    validate: (value, { years }) => {

        const newDate = typeof value !== "object" ? new Date(value) : value;

        const today = new Date();

        const day = today.getDate();
        const month = today.getMonth();
        const year = today.getFullYear() + years;
        const maxAllowedDate = new Date(year, month, day);

        const isValid = maxAllowedDate >= newDate;

        if (!isValid) { return 'Valid Expiration Date must not exceed {years} years in the future.' };

        return true;

    }
};