<template>

    <ValidationProvider
        :rules="validationRules"
        :name="title"
        v-slot="{ errors }"
        tag="section"
        class="funding-card"
    >
        <main :class="{ 'has-error': errors.length > 0 }">

            <div class="icon-circle">
                <BaseIcon :name="productName">
                    <component :is="productIcon" />
                </BaseIcon>
            </div>

            <label :for="inputId">{{ title }}</label>

            <v-popover
                trigger="hover click focus"
                placement="left-start"
                offset="5"
            >
                <p>?</p>

                <template #popover>

                    <section class="tooltip">
                        <h3>{{ title }}</h3>
                        <p v-html="description"></p>
                    </section>

                </template>

            </v-popover>
            
            <div class="currency-input-container">
                <CurrencyInput
                    type="tel"
                    :id="inputId"
                    :name="title"
                    currency="USD"
                    placeholder="0.00"
                    :distraction-free="false"
                    v-model="innerValue"
                />
            </div>

        </main>

        <transition name="fade">
            <p v-if="errors.length > 0" class="error-block" >
                {{ errors[0] }}
            </p>
        </transition>

    </ValidationProvider>

</template>





<script>

    import { ValidationProvider } from 'vee-validate';
    import { CurrencyInput } from 'vue-currency-input';

    import { BaseIcon } from '@/components/icons';

    export default {

        name: 'FundingCard',
        components: {

            ValidationProvider,
            CurrencyInput,
            BaseIcon

        },
        props: {

            value: {
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            categoryId: {
                type: Number,
                required: true
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },

        },
        computed: {

            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },
            productName() {

                const nameMap = {
                    0: 'None',
                    1: 'Membership',
                    2: 'Savings',
                    3: 'Checking',
                    4: 'Money Market',
                    5: 'Checking'
                };

                return nameMap[this.categoryId];

            },
            productIcon() {

                switch(this.categoryId) {

                    case 1:
                    case 2:
                        return () => import('@/components/icons').then(({ SavingsIcon }) => SavingsIcon);
                        break;
                    case 3:
                    case 5:
                        return () => import('@/components/icons').then(({ CheckingIcon }) => CheckingIcon);
                        break;
                    case 4:
                        return () => import('@/components/icons').then(({ MoneyIcon }) => MoneyIcon);
                        break;
                    default:
                        return null;

                }

            },
            inputId() {

                return this.title.replace(/\s/g, '');

            }

        }

    }

</script>





<style lang="scss" scoped>

    .funding-card {
        color: var(--darkGrey);

        main {
            position: relative;
            display: flex;
            align-items: center;
            padding: 1rem 0;

            &:after {
                content: "";
                background: var(--tertiary);
                height: 1.5px;
                position: absolute;
                bottom: 0;
                width: 95%;
                left: 2.5%;
                right: 2.5%;
            }

            & > *:not(:last-child) { margin-right: 0.75rem; }

            .icon-circle {
                box-sizing: border-box;
                width: 2.8125rem;
                height: 2.8125rem;
                border: 5px solid var(--tertiary);
                color: var(--tertiary);
                padding: 0.4rem;
                border-radius: 100%;
                display: flex;

                svg {
                    width: 100%;
                    height: auto;
                }

            }

            label { flex: 1; }

            input {
                box-sizing: border-box;
                border: 1px solid var(--darkGrey);
                border-radius: 4px;
                font-size: 1.25rem;
                color: var(--primary);
                padding: 0.5rem;
                background: transparent;
                text-align: right;
                max-width: 7rem;

                &:active,
                &:focus {
                    border: 1px solid var(--secondary);
                    outline: none;
                    box-shadow: 2px 1px 5px rgba(var(--secondary-50));
                }

            }

            &.has-error {

                &:after { background: var(--danger); }

                .icon-circle {
                    border: 5px solid var(--danger);
                    color: var(--danger);
                }

                label { color: var(--danger); }

                .tooltip-button {
                    border: 1px solid var(--danger);
                    background-color: var(--danger);

                    &:hover {
                        background-color: transparent;
                        color: var(--danger);
                    }

                }

                input {
                    color: var(--danger);
                    border: 1px solid var(--danger); 
                }
                
            }

        }
        
        .error-block {
            color: var(--danger);
            text-align: center;
            font-size: 80%;
            margin-top: 0.5rem;
        }

    }

    ::v-deep .trigger {
        text-align: center;
        font-size: 0.8125rem;
        font-weight: 400;
        height: 1.25rem;
        width: 1.25rem;
        border: 1px solid var(--primary);
        border-radius: 100%;
        background-color: var(--primary);
        color: var(--white);
        transition: background-color 0.5s, color 0.5s;
        cursor: pointer;

        //p { margin-top: -0.0625rem; }

        &:hover {
            background-color: transparent;
            color: var(--primary);
        }

    }

    .tooltip {
        text-align: center;
        padding: 0.75rem 0.25rem;
        border: 2px solid var(--tertiary);
        border-radius: 7px;
        background-color: var(--white);
        max-width: 11.25rem;
        box-sizing: border-box;

        h3 {
            color: var(--primary);
            font-size: 1rem;
            line-height: 1.125rem;
            margin-bottom: 1rem;
        }

        p {
            color: var(--darkGrey);
            font-size: 1rem;
            line-height: 1.4375rem;
            font-weight: 300;
        }

    }

    @media (max-width: 375px) {

        main {
            flex-wrap: wrap;
        }

        .funding-card main input {
            margin-top: 1rem;
            width: 100%;
            max-width: none;
        }

        .currency-input-container {
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 280px) {

        label {
            font-size: 0.875rem;
        }

    }

</style>