import _cloneDeep from 'lodash.clonedeep';

import { incomeAPI } from '@/api';
import { MemberType } from '@/constants';
import * as Sentry from "@sentry/vue";

const state = {

    incomeSources: []

};

function resetState(state) { state.incomeSources = [] };

const actions = {

    async getIncomeSources({ commit, state }) {

        if (state.incomeSources.length) { return _cloneDeep(state.incomeSources) };

        try {

            const incomeSources = await incomeAPI.getIncomeSources(state.applicantId);

            commit('setData', { objName: 'incomeSources', data: incomeSources });

            return _cloneDeep(incomeSources);

        } catch (error) {

            throw error;

        };

    },
    async createIncomeSource({ commit, state }, incomeSource) {

        try {

            const newIncome = await incomeAPI.createIncomeSource({
                applicantId: state.applicantId,
                incomeSource
            });

            commit('addItem', { objName: 'incomeSources', data: newIncome });

            return newIncome;

        } catch (error) {

            throw error;

        };

    },
    async deleteIncomeSource({ commit, state }, { id }) {

        try {

            await incomeAPI.deleteIncomeSource({ applicantId: state.applicantId, id });

            commit('deleteItem', { objName: 'incomeSources', id });

            return id;

        } catch (error) {

            throw error;

        };

    },
    async updateIncomeSource({ commit, state }, incomeSource) {

        try {

            await incomeAPI.updateIncomeSource({
                applicantId: state.applicantId,
                incomeSource
            });

            commit('updateItem', { objName: 'incomeSources', data: incomeSource });

            return incomeSource;

        } catch (error) {

            throw error;

        };

    }

};

export {

    state,
    resetState,
    actions

};

export default {

    state,
    resetState,
    actions

};