import axios from '@/utils/axios';

const confirm = async applicantId => {

    try {

        const {
            data: {
                confirmed
            }
        } = await axios.post(`${process.env.BASE_URL}api/Review`, { applicantId });

        if (!confirmed) { throw 'There was an error confirming your loan details' };

        return applicantId;
        
    } catch (error) {
        
        throw error;

    };

};

const getConfig = async () => {

    try {

        const {
            data: {
                includeEmploymentForReview
            }
        } = await axios.get(`${process.env.BASE_URL}api/Review/Configuration`);

        return { includeEmploymentForReview };

    } catch (error) {

        throw error;

    };

};

export { confirm, getConfig };
export default { confirm, getConfig };