<template>

	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		:viewBox="viewBox"
		:aria-labelledby="name"
		role="presentation"
        :fill="color"
	>

		<title :id="name" lang="en">{{ name }}</title>
		<g>
			<slot />
		</g>

	</svg>

</template>





<script>

    export default {

        name: 'BaseIcon',
        props: {

            name: {
                type: String,
                required: true
            },
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            viewBox: {
                type: String,
                default: '0 0 18 18'
            },
            color: {
                type: String,
                default: "currentColor"
            }

        }

    };

</script>





<style scoped>

    svg {
        display: inline-block;
        vertical-align: middle;
        /* margin-bottom: -2px; */
    }

</style>