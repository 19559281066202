export default () => ({
    id: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    dateOfBirth: '',
    taxpayerIdentificationNumber: '',
    maritalStatusConfigurationId: null,
    citizenshipStatusConfigurationId: null
});