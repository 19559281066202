import axios from '@/utils/axios';

import incomeAPI from './income';

const getConfig = async () => {
	try {
		const [employmentConfig, incomeConfig] = await Promise.all([
			axios.get(`${process.env.BASE_URL}api/Employment/Configuration`),
			incomeAPI.getConfig()
		]);

		const {
			data: {
				employmentStatuses,
				occupations,
				employmentHistoryRequiredInMonths,
				additionalEmploymentHistoryThresholdInMonths
			}
		} = employmentConfig;
		const { incomeSources } = incomeConfig;

		return {
			incomeSources,
			employmentStatuses: employmentStatuses || [],
			occupations: occupations || [],
			employmentHistoryRequiredInMonths: employmentHistoryRequiredInMonths || 0,
			additionalEmploymentHistoryThresholdInMonths: additionalEmploymentHistoryThresholdInMonths || 0
		};
	} catch (error) {
		throw error;
	}
};

const getEmploymentHistory = async applicantId => {
	try {
		const {
			data: { employments }
		} = await axios.get(`${process.env.BASE_URL}api/Employment/${applicantId}`);

		return employments || [];
	} catch (error) {
		throw error;
	}
};

const createEmployment = async ({ applicantId, employment }) => {
	try {
		const {
			data: { employmentId: id, isEmploymentHistorySufficient }
		} = await axios.post(`${process.env.BASE_URL}api/Employment`, {
			applicantId,
			employment
		});

		const newEmployment = { ...employment, id };

		return { employment: newEmployment, isEmploymentHistorySufficient };
	} catch (error) {
		throw error;
	}
};

const deleteEmployment = async ({ applicantId, employmentId }) => {
	try {
		const {
			data: { deleted }
		} = await axios.post(`${process.env.BASE_URL}api/Employment/Delete`, {
			applicantId,
			employmentId
		});

		if (!deleted) {
			throw 'There was an error removing an employment record';
		}

		return employmentId;
	} catch (error) {
		throw error;
	}
};
const updateEmployment = async ({ applicantId, employment }) => {
	try {
		const {
			data: { updated, isEmploymentHistorySufficient }
		} = await axios.post(`${process.env.BASE_URL}api/Employment/Update`, {
			applicantId,
			employment
		});

		if (!updated) {
			throw 'There was an error updating an employment record';
		}

		return { employment, isEmploymentHistorySufficient };
	} catch (error) {
		throw error;
	}
};

export default {
	getConfig,
	getEmploymentHistory,
	createEmployment,
	deleteEmployment,
	updateEmployment
};

export { getConfig, getEmploymentHistory, createEmployment, deleteEmployment, updateEmployment };
