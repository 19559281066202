import meta from './defaultMeta';

export default [
	{
		path: 'resume/:appId?',
		name: 'ResumeByAppId',
		component: () => import(/* webpackChunkName: "Resume" */ '@/views/Resume'),
		meta: {
			backButton: {
				clientWebsite: true
			},
			overflow: false,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			bypassAudit: true
		}
	},
	{
		path: ':productType/authentication',
		name: 'Authentication',
		component: () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication'),
		meta: {
			backButton: {
				clientWebsite: true
			},
			overflow: false,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: ':productType/address/current',
		name: 'CurrentAddress',
		component: () => import(/* webpackChunkName: "CurrentAddress" */ '@/views/CurrentAddress'),
		meta: {
			...meta(),
			leavingModal: true
		}
	},
	{
		path: ':productType/employment/current',
		name: 'CurrentEmployment',
		component: () => import(/* webpackChunkName: "CurrentEmployment" */ '@/views/CurrentEmployment'),
		meta: {
			...meta(),
			leavingModal: true
		}
	},
	{
		path: ':productType/identification',
		name: 'Identification',
		component: () => import(/* webpackChunkName: "Identification" */ '@/views/Identification'),
		meta: { ...meta() }
	},
	{
		path: ':productType/disclosures',
		name: 'Disclosures',
		component: () => import(/* webpackChunkName: "Disclosures" */ '@/views/Disclosures'),
		meta: { ...meta() }
	},
	{
		path: ':productType/verify',
		name: 'Verify',
		component: () => import(/* webpackChunkName: "Verify" */ '@/views/Verify'),
		meta: {
			...meta(),
			forceNavigation: true
		}
	}
];
