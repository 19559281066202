<template>
    <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="handleSubmit"
        v-slot="{ invalid }"
    >
        <p>We would love to know why…</p>

        <fieldset>
            <OaoFormRadioGroup
                v-model="selected"
                name="leaving prompt"
                :options="leavingOptions"
                :disabled="submitting"
                validationRules="required"
                isCondensed
            >
                <OaoFormTextArea
                    v-if="showOtherField"
                    v-model="otherText"
                    label="Reason"
                    name="other reason"
                    validationRules="required|max:250"
                    style="margin-top: 1.5rem"
                    hideLabel
                />
            </OaoFormRadioGroup>
        </fieldset>

        <section class="button-container">
            <OaoButton
                buttonType="submit"
                title="Thank you"
                :disabled="invalid || submitting"
            />
        </section>
    </ValidationObserver>
</template>

<script>
    import {
        OaoButton,
        OaoFormRadioGroup,
        OaoFormTextArea,
    } from "@/components";
    import showSpinner from "@/mixins/showSpinner";
    import store from "@/store";
    import { logError } from "@/utils";
    import { extend, ValidationObserver } from "vee-validate";
    import locale from "vee-validate/dist/locale/en";
    import { max, required } from "vee-validate/dist/rules";
    import { mapActions, mapState } from "vuex";

    extend("required", {
        ...required,
        message: locale.messages["required"],
    });

    extend("max", {
        ...max,
        message: locale.messages["max"],
    });

    const handleLogError = logError("@/views/Partials/LeavingModal.vue");

    export default {
        name: "LeavingModal",
        mixins: [showSpinner],
        components: {
            ValidationObserver,
            OaoButton,
            OaoFormRadioGroup,
            OaoFormTextArea,
        },
        props: {
            preventClose: {
                type: Boolean,
                default: false,
                required: false,
            },
            leavingReasons: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                selected: null,
                otherText: "",
                isSuccess: false,
            };
        },
        computed: {
            ...mapState("modal", { showModal: (state) => state.isOpen }),
            ...mapState("application", {
                applicationId: (state) => state.applicationId,
                productTypeId: (state) => state.productTypeId,
            }),
            leavingOptions() {
                const options = this.leavingReasons;

                return options.map((obj) => ({
                    label: obj.reason,
                    value: obj.reason,
                }));
            },
            selectedOption() {
                if (this.selected) {
                    return this.leavingReasons.find(x => x.reason == this.selected);
                }
            },
            showOtherField() {
                return (this.selectedOption && this.selectedOption.allowCustomEntry)
            }
        },
        watch: {
            showModal(isOpen) {
                if (!isOpen && !this.isSuccess) {
                    return this.$emit("close");
                }
            },
        },
        created() {},
        destroyed() {
            return this.triggerModal();
        },
        methods: {
            ...mapActions("modal", ["triggerModal"]),
            ...mapActions("leavingModal", ["createApplicationLeaveReason"]),
            async handleSubmit() {
                try {
                    this.showSpinner({ submitting: true });
                    const isValid = await this.$refs.observer.validate();

                    if (!isValid) {
                        return this.$toast.error("Form is invalid.");
                    }

                    const request = {
                        applicationId: this.applicationId,
                        loanTypeId: this.productTypeId,
                        isMember: await store.getters["applicant/isMember"],
                        url: location.href,
                        applicationLeaveReasonConfigurationId: this.selectedOption.id,
                        customEntry: this.selectedOption.allowCustomEntry ?
                            this.otherText :
                            null
                    };

                    this.createApplicationLeaveReason(request);

                    this.isSuccess = true;

                    this.$emit("submit", this.selected);
                } catch (error) {
                    handleLogError({
                        action: "handleSubmit",
                        error,
                    });

                    return this.$toast.error(error.message || error);
                } finally {
                    this.showSpinner({ submitting: false });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    fieldset {
        border: none;
        margin: 1.5rem 0;
    }
</style>
