<template>

    <ValidationProvider
        :rules="validationRules"
        :name="title"
        v-slot="{ errors }"
        slim
    >

        <label
            :for="id"
            :class="[
                'product',
                {
                    'is-required': required,
                    'is-selected': !required && valuesMatch,
                    'is-disabled': isDisabled,
                    'display-only':  displayOnly
                }
            ]"
        >

            <input
                type="checkbox"
                :id="id"
                :name="title"
                :value="optionValue"
                v-model="innerValue"
                :disabled="isDisabled"
            />
            <p class="required" v-if="required && !displayOnly">REQUIRED</p>

            <section>
                <BaseIcon
                    :name="productName"
                    width="2.8125rem"
                    height="2.8125rem"
                >
                    <component :is="productIcon" />
                </BaseIcon>
                <div>
                    <h1>{{ title }}</h1>
                    <p>{{ description }}</p>
                </div>
            </section>
            
            <BaseIcon
                v-if="!displayOnly"
                class="add-button"
                :name=" required || value === optionValue ? 'Product Selected' : 'Product Unselected' "
            >
                <SelectedIcon v-if="required || valuesMatch" />
                <AddIcon v-else />
            </BaseIcon>

        </label>

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';

    import { BaseIcon, AddIcon, CheckingIcon, SelectedIcon } from '@/components/icons';

    export default {

        name: 'ProductSelection',
        components: {
            
            ValidationProvider,
            BaseIcon,
            AddIcon,
            CheckingIcon,
            SelectedIcon

        },
        props: {

            value: {
                required: true
            },
            optionValue: {
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            required: {
                type: Boolean,
                required: false
            },
            categoryId: {
                type: Number,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: String,
                required: false
            },
            displayOnly: {
                type: Boolean,
                required: false
            }

        },
        computed: {

            id() {

                return `${ this.title.replace(/\s/g, '') }-${ this._uid }`;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },
            isDisabled() {

                return this.required || this.disabled;

            },
            selected() {

                return this.required || this.value === this.optionValue;

            },
            productName() {

                const nameMap = {
                    0: 'None',
                    1: 'Membership',
                    2: 'Savings',
                    3: 'Checking',
                    4: 'Money Market',
                    5: 'Checking'
                };

                return nameMap[this.categoryId];

            },
            productIcon() {

                switch(this.categoryId) {

                    case 1:
                    case 2:
                        return () => import('@/components/icons').then(({ SavingsIcon }) => SavingsIcon);
                        break;
                    case 3:
                    case 5:
                        return () => import('@/components/icons').then(({ CheckingIcon }) => CheckingIcon);
                        break;
                    case 4:
                        return () => import('@/components/icons').then(({ MoneyIcon }) => MoneyIcon);
                        break;
                    default:
                        return null;

                }

            },
            valuesMatch() {

                if (this.value.constructor === Array) {

                    return this.value.some(product => product.productId === this.optionValue.productId);

                };

                return this.value.productId === this.optionValue.productId;
            }

        }

    }

</script>




<style lang="scss" scoped>

    .product {
        position: relative;
        display: block;
        padding: 0.75rem;
        border: 2px solid var(--primary);
        border-radius: 3px;
        transition: background-color 0.5s, color 0.5s;
        color: var(--primary);
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        svg {
            transition: fill 0.5s;
        }

        &.is-required {
            border: 2px solid var(--tertiary);
            color: var(--tertiary);
        }

        &.is-selected {
            background-color: var(--primary);
            color: var(--white);
        }

        &.is-disabled {
            cursor: not-allowed;
        }

        &.display-only {
            cursor: default;
        }

        .required {
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            font-size: 0.625rem;
            font-weight: 800;
            color: var(--primary);
        }

        input {
            position: absolute;
            left: -9999px;
        }

        section {
            display: flex;
            align-items: center;

            div {
                flex: 1;
                margin-left: 1rem;

                h1 {
                    font-size: 1.25rem;
                    font-weight: 400;
                }

                p {
                    font-size: 0.75rem;
                    font-weight: 400;
                    font-style: italic;
                    padding-right: 2.25rem;
                }

            }

        }

        .add-button {
            position: absolute;
            bottom: 0.25rem;
            right: 0.25rem;
            height: 1.5625rem;
            width: 1.5625rem;
            color: var(--primary);
        }

        &.is-required .add-button,
        &.is-selected .add-button {
            color: var(--success);
        }

    }

</style>