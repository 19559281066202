<template>

    <ValidationProvider
        :rules="completeValidationRules"
        :name="name || label"
        :vid="validationId"
        v-slot="{ errors }"
        slim
    >

        <OaoInput
            v-model="innerValue"
            :label="label"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :error="errors[0]"
            :dateConfig="dateConfig"
            :mask="mask"
            :maskOutput="maskOutput"
            :isOptional="isOptional"
            :disableAutocomplete="disableAutocomplete"
            :isSmall="isSmall"
            @change="$emit('change', $event)"
            :id="id"
            :tooltip="tooltip"
            :hideLabel="hideLabel"
            :sublabel="sublabel"
            :sublabelWarning="sublabelWarning"
            :forceCapitalization="forceCapitalization"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
        />

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';

    import { OaoInput } from '@/components/form';
    import {
        ruleBuilder,
    } from "@/utils";

    ruleBuilder(["custom_ascii_characters"]);

    export default {

        name: 'FormInput',
        components: {

            ValidationProvider,
            OaoInput

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: false,
                default: 'text'
            },
            placeholder: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },
            dateConfig: {
                type: Object,
                required: false
            },
            mask: {
                type: [String, Array],
                required: false
            },
            maskOutput: {
                type: Boolean,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            validationId: {
                type: String,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            id: {
                type: [ String, Number ],
                required: false
            },
            tooltip: {
                type: Boolean,
                required: false
            },
            hideLabel: {
                type: Boolean,
                required: false
            },
            sublabel: {
                type: String,
                required: false
            },
            sublabelWarning: {
                type: Boolean,
                required: false
            },
            forceCapitalization: {
                type: Boolean,
                required: false
            }

        },
        computed: {

            completeValidationRules() {
                if (typeof this.validationRules == 'object') {
                    return { ...this.validationRules, custom_ascii_characters: true}
                } else if (typeof this.validationRules == 'string') {
                    return this.validationRules + "|custom_ascii_characters";
                } else if (typeof this.validationRules == 'undefined') {
                    return "custom_ascii_characters";
                }
            },

            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>
