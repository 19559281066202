<template>

	<LottieAnimation v-if="source" :path="source" loop :autoPlay="false" :speed="1" :width="512" :height="291"
		@AnimControl="createListeners" />

</template>





<script>

import LottieAnimation from "@sts-application/lottie-vuejs/src/LottieAnimation.vue";
import handleInstance from './handleInstance';

export default {

	name: 'MemberLoader',
	mixins: [handleInstance],
	components: { LottieAnimation },

};

</script>