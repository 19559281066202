<template>

	<button
        v-on="$listeners"
        :type="buttonType"
        :title="title"
        :class="[
            'is-' + type,
            {
                'is-fullwidth': fullwidth,
                'is-small': small,
                'is-lowercase': lowercase
            }
        ]"
        :disabled="disabled"
    >
        <div v-if="$slots.default" class="default-slot">
            <slot/>
        </div>
        <span v-else>{{ title }}</span>
        <BaseIcon
            v-if="type === 'text'"
            name="arrow" 
        >
            <ArrowIcon />
		</BaseIcon>
    </button>

</template>





<script>

    export default {

        name: 'Button',
        props: {

            title: {
                type: String,
                required: true
            },
            buttonType: {
                type: String,
                required: false,
                default: 'button'
            },
            type: {
                type: String,
                required: false,
                default: 'primary'
            },
            disabled: {
                type: Boolean,
                required: false
            },
            fullwidth: {
                type: Boolean,
                required: false
            },
            small: {
                type: Boolean,
                required: false
            },
            lowercase: {
                type: Boolean,
                required: false
            }

        },
        components: {

            BaseIcon: () => import('@/components/icons').then(({ BaseIcon }) => BaseIcon),
            ArrowIcon: () => import('@/components/icons').then(({ ArrowIcon }) => ArrowIcon)

        }

    };

</script>





<style lang="scss" scoped>

    button {
        box-sizing: border-box;
        background-color: var(--primary);
        padding: 0.9375rem 3rem;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--white);
        transition: all 0.3s linear;
        text-decoration: none;
        border: 2px solid var(--primary);

        .default-slot {
			display: flex;
			align-items: center;
			justify-content: center;
        }
    
        &:hover {
            background-color: transparent;
            border: 2px solid var(--primary);
            color: var(--primary);
        }

        &:disabled:hover,
        &:disabled,
        &[disabled] {
            border: 2px solid var(--disabled);
            background-color: var(--disabled);
            color: var(--lightGrey);
            cursor: not-allowed;
        }

        &.is-small {
            font-size: 0.85rem !important;
            padding: 0.75rem !important;
        }

        &.is-lowercase { text-transform: initial; }

        &.is-secondary {
            background-color: var(--secondary);
            border: 2px solid var(--secondary);

            &:hover {
                background-color: transparent;
                border: 2px solid var(--secondary);
                color: var(--secondary);
            }

        }

        &.is-tertiary {
            background-color: var(--tertiary);
            border: 2px solid var(--tertiary);

            &:hover {
                background-color: transparent;
                border: 2px solid var(--tertiary);
                color: var(--tertiary);
            }

        }

        &.is-hollow {
            background-color: transparent;
            border: 2px solid var(--primary);
            color: var(--primary);

            &:hover {
                background-color: var(--primary);
                border: 2px solid var(--primary);
                color: var(--white);
            }

        }

        &.is-plain {
            display: block;
            padding: 0.9375rem;
            margin: 0 auto;
            background-color: initial;
            border: none;
            color: var(--primary);
            font-size: 1.25rem;
            font-weight: 400;
            text-decoration: none;
            text-transform: initial;
            transition: opacity 0.5s;

            &:hover { opacity: 0.7; }

        }

        &.is-text {
            padding: 0;
            margin: 0;
            background-color: initial;
            border: none;
            text-align: left;
            color: var(--primary);
            font-size: 0.9375rem;
            font-weight: 600;
            text-decoration: none;

            svg {
                margin-left: 0.375rem;
                transition: padding 0.3s linear;
            }

            &:hover svg {
                padding-left: .75rem;
            }

        }

        &.is-fullwidth {
            padding: 0.9375rem 0;
            width: 100%;
        }

    }

</style>