<template functional>

    <g>
        <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4
            s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z"/>
        <path class="primary-fill" d="M9.7,9.8h0.4V9.2h0.4v0.6H11v-1H9.7V9.8z M9.7,11.5h0.4v-0.6h0.4v0.6H11v-1.1H9.7V11.5z M12,9.2h0.4v0.6h0.4
            v-1h-1.2v1H12V9.2z M9.7,8.2h0.4V7.5h0.4v0.7H11V7.1H9.7V8.2z M12,7.5h0.4v0.7h0.4V7.1h-1.2v1.1H12V7.5z M12,10.9h0.4v0.6h0.4v-1.1
            h-1.2v1.1H12V10.9z M14.9,7l-3.3-3.4l0,0l-0.2-0.2l-0.1-0.1c-0.1,0-0.1,0-0.1,0.1L8.8,5.7l-2-2.1l0,0L6.6,3.3L6.5,3.2
            c-0.1,0-0.1,0-0.1,0.1L2.8,7c-0.1,0-0.1,0.2,0,0.2l0.6,0.7L3.5,8c0.1,0,0.1,0,0.1-0.1l0.1-0.1v6.6h1.9h1.5H9l0,0h1.5H12h1.9V7.8
            L14,7.9c0,0,0,0.1,0.1,0.1l0,0c0.1,0,0.1,0,0.1-0.1l0.6-0.7C15,7.2,15,7,14.9,7z M3.6,7.5L3.3,7.1l3.2-3.3l2,2.1L8.1,6.2L6.6,4.7
            L6.5,4.6c-0.1,0-0.1,0-0.2,0.1L3.6,7.5z M6.2,14v-1.2h0.6V14H6.2z M7.2,14v-1.7H5.8V14H4.3V7.3l0,0l2.2-2.2L8,6.7l0,0
            c0,0,0,0.1,0.1,0.1l0.6,0.7l0,0V14H7.2z M11,14v-1.2h0.6V14H11z M13.4,14H12v-1.7h-1.5V14H9.1V7.3l0,0l2.1-2.2l2.2,2.3
            C13.4,7.4,13.4,14,13.4,14z M14.1,7.5l-2.7-2.8l-0.1-0.1l0,0c-0.1,0-0.1,0-0.1,0.1L8.8,7L8.5,6.7l2.8-2.9l3.2,3.3L14.1,7.5z
            M4.9,9.8h0.4V9.2h0.5v0.6h0.4v-1H4.9V9.8z M4.9,11.5h0.4v-0.6h0.5v0.6h0.4v-1H4.9V11.5z M6.8,9.8h0.4V9.2h0.4v0.6H8v-1H6.8V9.8z
            M4.9,8.2h0.4V7.5h0.5v0.7h0.4V7.1H4.9V8.2z M6.8,8.2h0.4V7.5h0.4v0.7H8V7.1H6.8V8.2z M6.8,11.5h0.4v-0.6h0.4v0.6H8v-1H6.8V11.5z"
            />
    </g>

</template>




<script>

    export default {

        name: 'TownhomeIcon'
        
    };

</script>