export default ({
	applicationId = 0,
	collateralType = null,
	vehicleLoanPurposeConfigurationId = null,
	vehicleSellerConfigurationId = null,
	applicationType = null,
	vehicleCondition = null,
	vehicleLoanType = null,
	branchPreferenceConfigurationId = null
}) => ({
	id: 0,
	applicationId,
	applicationType,
	collateralType,
	vehicleLoanType,
	vehicleLoanPurposeConfigurationId,
	vehicleSellerConfigurationId,
	vehicleCondition,
	downPaymentAmount: null,
	loanConfigurationId: null,
	requestedLoanTermInMonths: null,
	requestedLoanAmount: null,
	additionalComments: null,
	year: null,
	make: '',
	model: '',
	type: null,
	branchPreferenceConfigurationId
});
