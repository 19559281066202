export default () => ({
    loanApplicationId: null,
    loanAmount: null,
    estimatedPropertyValue: null,
    loanPurposeConfigurationId: null,
    mortgageStatusConfigurationId: null,
    additionalComments: '',
    payOffPrimaryMortgage: null,
    payOffSecondaryMortgage: null,
    primaryMortgageAmount: null,
    secondaryMortgageAmount: null
});