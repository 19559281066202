import { CaptchaActions } from '@/constants';
import { getDateString } from '@/utils/dates';
import axios from '@/utils/axios';

const handleAuthResult = ({
	applicantId = 0,
	applicationId = 0,
	loanApplicationId = 0,
	firstName = '',
	lastName = '',
	authenticationResult = 0,
	captchaAction = CaptchaActions.NONE,
	hasDuplicateApplication = false,
	isEligibleToUseOLA = false,
	applicationToken = '',
	applicantToken = ''
}) => {
	switch (authenticationResult) {
		case 0:
			if (captchaAction === CaptchaActions.NONE) {
				throw 'There was an error verifying login credentials.';
			}
			break;
		case 1:
			// Success, let's return the applicant info
			break;
		case 2:
		case 3:
			throw 'The provided user name and password combination is incorrect.';
		case 4:
			throw 'The account provided is locked.';
		case 5:
			throw 'The account provided requires a password change.';
		default:
			if (captchaAction === CaptchaActions.NONE) {
				throw 'There was an error verifying login credentials.';
			}
	}

	return {
		applicantId,
		applicationId,
		loanApplicationId,
		captchaAction,
		firstName,
		lastName,
		hasDuplicateApplication,
		isEligibleToUseOLA,
		applicationToken,
		applicantToken
	};
};

const authenticateApplicant = async request => {
	try {
		const { data } = await axios.post(`${process.env.BASE_URL}api/Authenticate`, {
			...request,
			loadUrl: window.location.href
		});

		return handleAuthResult(data);
	} catch (error) {
		throw error;
	}
};

const authenticateSSO = async request => {
	try {
		const { data } = await axios.post(`${process.env.BASE_URL}api/SSO`, {
			...request,
			loadUrl: window.location.href
		});

		return data;
	} catch (error) {
		throw error;
	}
};

const getApplicant = async applicantId => {
	try {
		const {
			data: { applicant }
		} = await axios.get(`${process.env.BASE_URL}api/Applicant/${applicantId}`);

		return {
			...applicant,
			dateOfBirth: getDateString(applicant.dateOfBirth)
		};
	} catch (error) {
		throw error;
	}
};

const createApplicant = async request => {
	try {
		const { data } = await axios.post(`${process.env.BASE_URL}api/Applicant`, {
			...request,
			loadUrl: window.location.href
		});

		return data;
	} catch (error) {
		throw error;
	}
};

const updateApplicant = async ({ applicationId, applicant }) => {
	try {
		const {
			data: { updated, emailVerificationResult }
		} = await axios.post(`${process.env.BASE_URL}api/Applicant/Update`, {
			applicationId,
			applicant
		});

		if (!updated && emailVerificationResult.emailIsValid) {
			throw 'There was an error updating the Co-Applicant record';
		}

		return {
			applicant: applicant,
			emailVerificationResult: emailVerificationResult
		};
	} catch (error) {
		throw error;
	}
};

export default {
	authenticateApplicant,
	getApplicant,
	createApplicant,
	updateApplicant,
	authenticateSSO
};

export { authenticateApplicant, getApplicant, createApplicant, updateApplicant, authenticateSSO };
