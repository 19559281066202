<template functional>

    <section
        :class="[ data.class, data.staticClass, 'box-shadow' ]"
        :style="[ data.style, data.staticStyle ]"
    >
        <slot/>
    </section>

</template>




<script>

    export default {

        name: 'Card'

    }

</script>




<style lang="scss" scoped>

    .box-shadow {
        background: var(--white);
        padding: 1.75rem;
    }

    @media only screen and (min-width: 641px) {
        .box-shadow {
            //max-width: 60%;
            margin: 0 auto;
        }
    }

</style>