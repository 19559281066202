import axios from '@/utils/axios';

const getConfig = async () => {

    try {

        const {
            data: {
                ethnicGroups,
                genders,
                races
            }
        } = await axios.get(`${process.env.BASE_URL}api/Demographic/Configuration`);

        return { ethnicGroups, genders, races };

    } catch (error) {

        throw error;

    };

};

const getDemographic = async applicantId => {

    try {

        const {
            data: {
                demographic
            }
        } = await axios.get(`${process.env.BASE_URL}api/Demographic/${applicantId}`);

        return demographic || { ethnicGroups: [], genders: [], races: [] };

    } catch (error) {

        throw error;

    };

};

const createDemographic = async ({ applicantId, demographic }) => {

    try {
      
        const {
            data: {
                created
            }
        } = await axios.post(`${process.env.BASE_URL}api/Demographic`, {
            applicantId,
            demographic
        });

        return demographic;

    } catch (error) {
        
        throw error;

    };

};

const updateDemographic = async ({ applicantId, demographic }) => {

    try {

        const {
            data: {
                updated
            }
        } = await axios.post(`${process.env.BASE_URL}api/Demographic/Update`, {
            applicantId,
            demographic
        });

        if (!updated) { throw 'There was an error updating the Demographic record' };

        return demographic;

    } catch (error) {

        throw error;

    };

};

const skipDemographic = async applicantId => {

    try {

        const {
            data: {
                updated
            }
        } = await axios.post(`${process.env.BASE_URL}api/Demographic/Default`, { applicantId });

        if (!updated) { throw 'There was an error updating the Demographic record' };

        return applicantId;

    } catch (error) {

        throw error;

    };

};

export default {

    getConfig,
    getDemographic,
    createDemographic,
    updateDemographic,
    skipDemographic

};

export {

    getConfig,
    getDemographic,
    createDemographic,
    updateDemographic,
    skipDemographic

};