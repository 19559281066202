import axios from '@/utils/axios';

const getDisplayLeavingModalConfig = async () => {

    try {

        const {
            data: {
                displayLeavingModalForMembers,
                displayLeavingModalForNonMembers
            }
        } = await axios.get(`${process.env.BASE_URL}api/Application/DisplayLeavingModal`);

        return {
            displayLeavingModalForMembers,
            displayLeavingModalForNonMembers
        };

    } catch (error) {

        throw error;

    }

};

const getLeaveReasons = async () => {

    try {

        const {
            data: {
                leaveReasons
            }
        } = await axios.get(`${process.env.BASE_URL}api/ApplicationLeaveReason/Configurations`);

        return leaveReasons;

    } catch (error) {

        throw error;

    }

};

const createApplicationLeaveReason = async (request) => {

    try {
        const {
            data: {
                created
            }
        } = await axios.post(
            `${process.env.BASE_URL}api/ApplicationLeaveReason`,
            request
        );

        return created;

    } catch (error) {

        throw error;

    };

};
export default {

    getDisplayLeavingModalConfig,
    getLeaveReasons,
    createApplicationLeaveReason

};

export {

    getDisplayLeavingModalConfig,
    getLeaveReasons,
    createApplicationLeaveReason

};
