import store from '@/store';
import * as Sentry from "@sentry/vue";

export default async incomingRoute => {
    
    try {
      
        await store.dispatch('setDefaults', incomingRoute.meta.isError);

        const incomingHeader = store.state.branding.images && incomingRoute.name ? store.state.branding.images[incomingRoute.name.toLowerCase()] : null;

        return store.commit('pageOptions/setData', { objName: 'currentHeader', data: incomingHeader ? incomingHeader : store.state.branding.images.default });

    } catch (error) {
        throw error;

    };

};