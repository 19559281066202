<template>
	<div id="app">
		<transition name="fade">
			<OaoOverlay v-if="isLoading && getLoaderOption === loaderOptions.CLOCK">
				<BaseIcon height="6rem" width="6rem" name="Loader" viewBox="0 0 50 50">
					<ClockIcon />
				</BaseIcon>
			</OaoOverlay>
		</transition>

		<MemberLoaderOverlay :source="memberLoaderPath" :play="isLoading && getLoaderOption === loaderOptions.MEMBER " />
		<NonMemberLoaderOverlay :source="nonMemberLoaderPath" :play="isLoading && getLoaderOption === loaderOptions.NON_MEMBER " />

		<div v-if="isError ? true : defaultsLoaded">
			<OaoBrowserMessage v-if="badBrowser" />
			<OaoSessionExpired v-if="showSessionExpired" />

			<transition name="fade">
				<OaoModal v-if="modal.isOpen" :title="modal.title" :smallTitle="modal.smallTitle"
					:preventClose="modal.preventClose" :fit-content="modal.fitContent" @close="closeModal">
					<template>
						<OaoIconCallout v-if="showContact">
							<template slot="icon">
								<BaseIcon name="Phone" height="35" width="35" class="contact-icon">
									<PhoneIcon />
								</BaseIcon>
							</template>
							<a :href="`tel:${clientTelephone}`" title="Have a question? Call us!"
								class="contact-text">{{
				clientTelephone }}</a>
						</OaoIconCallout>
						<LeavingModal v-if="modal.isLeavingModal" @submit="handleLeavingModalSubmit"
							:leaving-reasons="leavingReasons" />
						<PortalTarget v-if="hasPortal.content" name="modal-content" />
					</template>

					<template slot="footer">
						<PortalTarget v-if="hasPortal.footer" name="modal-footer" />
					</template>
				</OaoModal>
			</transition>

			<transition name="fade">
				<OaoModal v-if="modal.secondaryIsOpen" :title="modal.secondaryTitle"
					:smallTitle="modal.secondarySmallTitle" :preventClose="modal.secondaryPreventClose"
					@close="triggerSecondaryModal({ isOpen: false })">
					<template>
						<PortalTarget v-if="hasSecondaryPortal.content" name="secondary-modal-content" />
					</template>

					<template slot="footer">
						<PortalTarget v-if="hasSecondaryPortal.footer" name="secondary-modal-footer" />
					</template>
				</OaoModal>
			</transition>

			<OaoNav v-if="!$route.meta.hideNav" :backRoute="backButton.route" :backTitle="backButton.title"
				:hideHelp="$route.meta.hideHelp" @contact="handleContact" />

			<div class="wrapper" v-if="!badBrowser">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import { OaoIconCallout, OaoModal, OaoNav, OaoOverlay, OaoSessionExpired } from '@/components';
import { MemberLoaderOverlay, NonMemberLoaderOverlay } from '@/components/animations';
import { BaseIcon, ClockIcon, PhoneIcon } from '@/components/icons';
import LeavingModal from '@/views/Partials/LeavingModal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import loaderOptions from '@/constants/LoaderOption'

export default {
	name: 'App',
	components: {
		BaseIcon,
		ClockIcon,
		OaoBrowserMessage: () => import('@/components/OaoBrowserMessage'),
		PhoneIcon,
		OaoIconCallout,
		OaoModal,
		OaoNav,
		OaoOverlay,
		OaoSessionExpired,
		MemberLoaderOverlay,
		NonMemberLoaderOverlay,
		LeavingModal
	},
	data() {
		return {
			loaderOptions: loaderOptions,
			showContact: false,
			resumeApplicationEnabled: false,
			play: false,
			displayLeavingModalForMembers: false,
			displayLeavingModalForNonMembers: false,
			leavingReasons: null
		};
	},
	async created() {
		await this.getInputConfig();
		this.mountListeners();
		await this.getResumeApplicationConfig();

		const [
			leaveReasons,
			displayLeavingModalConfig
		] = await Promise.all([
			this.getLeaveReasons(),
			this.getDisplayLeavingModalConfig()
		]);

		this.leavingReasons = leaveReasons;
		this.displayLeavingModalForMembers = displayLeavingModalConfig.displayLeavingModalForMembers;
		this.displayLeavingModalForNonMembers = displayLeavingModalConfig.displayLeavingModalForNonMembers
	},
	computed: {
		...mapState({
			isLoading: state => state.isLoading,
			loaderOption: state => state.loaderOption,
			defaultsLoaded: state => state.defaultsLoaded,
			modal: state => state.modal,
			badBrowser: state => state.unsupportedBrowser,
			showSessionExpired: state => state.sessionTimer.sessionExpired,
		}),
		...mapGetters('applicant', ['isMember']),
		...mapGetters('modal', ['hasPortal', 'hasSecondaryPortal']),
		...mapGetters('routeEnforcer', ['previous']),
		...mapGetters(['clientWebsiteLink', 'clientTelephone']),
		...mapGetters('branding', ['clientName', 'nonMemberLoaderPath', 'memberLoaderPath']),
		isError() {
			return this.$route.meta.isError;
		},
		backButton() {
			const explicitRoute = this.$route.meta.backButton;
			const computedRoute = {
				route: this.previous || 'ProductSelect',
				title: `Return to the ${this.previous ? this.previous.name : 'Loan Select'} page`
			};

			if (explicitRoute && explicitRoute.clientWebsite) {
				return {
					route: this.clientWebsiteLink,
					title: `Return to ${this.clientName} Home`
				};
			}

			return explicitRoute ? explicitRoute : computedRoute;
		},
		getLoaderOption(){
			//check if lottie loaders are available on branding, if not available use non-lottie loader
			if(this.loaderOption === loaderOptions.MEMBER && !this.memberLoaderPath) return loaderOptions.CLOCK
			if(this.loaderOption === loaderOptions.NON_MEMBER && !this.nonMemberLoaderPath) return loaderOptions.CLOCK

			return this.loaderOption;
		}
	},
	methods: {
		...mapActions('modal', ['triggerModal', 'triggerSecondaryModal', 'openLeavingModal', 'closeLeavingModal']),
		...mapActions('leavingModal', ['getLeaveReasons', 'getDisplayLeavingModalConfig']),
		...mapActions({
			getInputConfig: 'config/getInputConfig'
		}),
		handleContact() {
			this.showContact = true;

			this.triggerModal({ isOpen: true, title: 'Have a question?' });
		},
		closeModal() {
			this.showContact = false;

			this.triggerModal({ isOpen: false });
		},
		async getResumeApplicationConfig() {
			const { resumeApplicationEnabled } = await this.$store.dispatch(
				'resumeApplicationConfiguration/getResumeApplicationConfig'
			);
		},
		mountListeners() {
			document.addEventListener('visibilitychange', ev => {
				if (document.visibilityState === 'hidden') {
					this.handleShowLeavingModal(ev);
				}
			});
			document.addEventListener('mouseleave', ev => {
				if (ev.clientX < 0 || ev.clientY < 0 || ev.clientX > window.innerWidth || ev.clientY > window.innerHeight){
					this.handleShowLeavingModal(ev)
				}
			});
			document.addEventListener('blur', ev => this.handleShowLeavingModal(ev));
		},
		handleLeavingModalSubmit(selected) {
			this.closeLeavingModal();
			const reasonObject = this.leavingReasons.find(x => x.reason === selected);
			return window.location.replace(reasonObject.externalLink);
		},
		async canDisplayLeavingModalForMembers() {
			return this.displayLeavingModalForMembers;
		},
		async canDisplayLeavingModalForNonMembers() {
			return this.displayLeavingModalForNonMembers;
		},
		eventCameFromBody(event) {
			return event.target.activeElement.localName === 'body';
		},
		async handleShowLeavingModal(event) {
			if (this.eventCameFromBody(event) && !this.showSessionExpired && this.$route.meta.leavingModal) {
				const canDisplay = this.isMember
					? await this.canDisplayLeavingModalForMembers()
					: await this.canDisplayLeavingModalForNonMembers();
				if (canDisplay) {
					this.openLeavingModal({
						title: 'Sorry to see you leave!',
						smallTitle: false,
						preventClose: this.preventClose
					});
				}
			}

			return this.sessionExpired;
		},

	}
};
</script>

<style lang="scss">
@import './assets/globalStyles.css';
@import './assets/animations.css';
</style>

<style lang="scss" scoped>
#app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.contact-icon {
		color: var(--primary);
	}

	.contact-text {
		color: var(--secondary);
		text-decoration: none;
		font-size: 2.25rem;
		line-height: 2.25rem;
		font-weight: 600;
		transition: color 0.3s;

		&:hover {
			color: var(--primary);
		}
	}

	.wrapper {
		flex: 1;

		&.no-nav {
			padding-top: 0;
		}
	}
}
</style>
