<template functional>

    <path d="M4.1,12.1l0.6-0.6l1.8,1.8l-0.6,0.6H5.3v-1.1H4.1V12.1z M9.2,6.9c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.2,0,0.4l-3.4,3.4
        c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.2,0-0.4L9.2,6.9z M3,15h3.4l6.4-6.4L9.4,5.3L3,11.6V15z M10.1,4.5l3.4,3.4l1.1-1.1
        c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8l-1.8-1.8c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3L10.1,4.5z M17,1
        c0.7,0.7,1,1.5,1,2.4v11.3c0,0.9-0.3,1.7-1,2.4s-1.5,1-2.4,1H3.4c-0.9,0-1.7-0.3-2.4-1s-1-1.5-1-2.4V3.4C0,2.4,0.3,1.7,1,1
        s1.5-1,2.4-1h11.3C15.6,0,16.3,0.3,17,1z"/>
    </svg>

</template>





<script>

    export default {

        name: 'EditIcon'

    }

</script>