import {
	addressAPI,
	demographicAPI,
	disclosuresAPI,
	eligibilityAPI,
	employmentAPI,
	errorAPI,
	identificationAPI,
	incomeAPI,
	paymentProtectionAPI,
	propertyTypeAPI,
	propertyStatusAPI,
	formInputAPI,
	coApplicantAPI,
	reviewAPI
} from '@/api';
import { acceptDisclosure } from '@/api/disclosures';
import { getEnumFromConfigObject, getListValueFromConfigObject } from '@/utils';
import axios from '@/utils/axios';

import * as Sentry from '@sentry/vue';

export default {
	namespaced: true,
	state: {
		states: [{ stateAbbreviation: '' }],
		residenceHistoryRequiredInMonths: 0,
		disclosures: [],
		disclosureId: 0,
		eligibilityCounties: [],
		eligibilityOptions: [],
		ineligiblePageText: '',
		eligibilityPageHeader: '',
		eligibilityPageSuboptionLabel: '',
		ineligiblePageCardText: '',
		//occupations: [],
		employmentHistoryRequiredInMonths: 0,
		employmentStatuses: [],
		citizenshipStatuses: [],
		maritalStatuses: [],
		identificationTypes: [],
		countries: [],
		acceptedCountryCodes: [],
		incomeSources: [],
		declinePageContent: '',
		reviewPageContent: '',
		primaryDocumentUploadEnabled: false,
		coborrowerDocumentUploadEnabled: false,
		productTypes: [],
		productTypeEnum: {},
		propertyTypes: [],
		propertyTypeEnum: {},
		propertyStatuses: [],
		propertyStatusEnum: {},
		ethnicGroups: [],
		genders: [],
		races: [],
		phoneTypes: [],
		additionalResidentialHistoryThresholdInMonths: 0,
		additionalEmploymentHistoryThresholdInMonths: 0,
		showSlideShowAtSubmit: false,
		inputConfigs: [],
		displayCitizenshipStatusField: false,
		displayRecentGraduateField: false,
		displayMaritalStatusField: false,
		residenceStatuses: [],
		performPersonVerification: false,
		displayPhoneTypeField: false,
		coApplicantModalTitle: '',
		coApplicantModalContent: '',
		displayPreferredContactMethodField: false,
		preferredContactMethods: [],
		paymentProtectionProductConfigs: [],
		paymentProtectionSectionDescription: '',
		paymentProtectionSectionTitle: '',
		requireFormOfIdentificationEntry: false,
		autoPopulateCountyByPostalCode: false,
		coApplicantIdentificationIsRequired: false,
		allowCoApplicantFormOfIdentificationEntry: false,
		includeEmploymentForReview: false,
		taxpayerIdentificationNumberConfigurations: [],
		addressVendorToken: '',
		enablePrimaryAddressAutoComplete: false,
		enableMailingAddressAutoComplete: false,
		enableHELOCAddressAutoComplete: false,
		enableReviewModalAddressAutoComplete: false,
		enablePreviousAddressAutoComplete: false
	},
	mutations: {
		setData(state, { objName, data }) {
			state[objName] = data;
		},
		resetState(state) {
			state.states = [];
			state.residenceHistoryRequiredInMonths = 0;
			state.disclosures = [];
			state.disclosureId = 0;
			state.eligibilityCounties = [];
			state.eligibilityOptions = [];
			//state.occupations = [];
			state.employmentHistoryRequiredInMonths = 0;
			state.employmentStatuses = [];
			state.citizenshipStatuses = [];
			state.maritalStatuses = [];
			state.identificationTypes = [];
			state.countries = [];
			state.acceptedCountryCodes = [];
			state.incomeSources = [];
			state.declinePageContent = '';
			state.reviewPageContent = '';
			state.primaryDocumentUploadEnabled = false;
			state.coborrowerDocumentUploadEnabled = false;
			state.productTypes = [];
			state.productTypeEnum = {};
			state.propertyTypes = [];
			state.propertyTypeEnum = {};
			state.propertyStatuses = [];
			state.propertyStatusEnum = {};
			state.ethnicGroups = [];
			state.genders = [];
			state.races = [];
			state.phoneTypes = [];
			state.additionalResidentialHistoryThresholdInMonths = 0;
			state.additionalEmploymentHistoryThresholdInMonths = 0;
			state.showSlideShowAtSubmit = false;
			state.residenceStatuses = [];
			state.performPersonVerification = false;
			state.displayPhoneTypeField = false;
			state.coApplicantModalTitle = '';
			state.coApplicantModalContent = '';
			state.displayPreferredContactMethodField = false;
			state.preferredContactMethods = [];
			state.paymentProtectionProductConfigs = [];
			state.paymentProtectionSectionTitle = '';
			state.paymentProtectionSectionDescription = '';
			state.requireFormOfIdentificationEntry = false;
			state.autoPopulateCountyByPostalCode = false;
			state.includeEmploymentForReview = false;
			state.taxpayerIdentificationNumberConfigurations = false;
			state.addressVendorToken = '';
			state.enablePrimaryAddressAutoComplete = false;
			state.enableMailingAddressAutoComplete = false;
			state.enableHELOCAddressAutoComplete = false;
			state.enableReviewModalAddressAutoComplete = false;
			state.enablePreviousAddressAutoComplete = false;
		}
	},
	actions: {
		async getAddressConfig({ commit, state }) {
			if (
				state.states.length &&
				state.requiredAddressMonths &&
				state.additionalAddressThreshold &&
				state.residenceStatuses.length
			) {
				return {
					states: state.states,
					residenceHistoryRequiredInMonths: state.residenceHistoryRequiredInMonths,
					additionalResidentialHistoryThresholdInMonths: state.additionalResidentialHistoryThresholdInMonths,
					residenceStatuses: state.residenceStatuses,
					enablePrimaryAddressAutoComplete: state.enablePrimaryAddressAutoComplete,
					enableMailingAddressAutoComplete: state.enableMailingAddressAutoComplete,
					enableHELOCAddressAutoComplete: state.enableHELOCAddressAutoComplete,
					enableReviewModalAddressAutoComplete: state.enableReviewModalAddressAutoComplete,
					enablePreviousAddressAutoComplete: state.enablePreviousAddressAutoComplete
				};
			}

			try {
				const {
					states,
					residenceHistoryRequiredInMonths,
					additionalResidentialHistoryThresholdInMonths,
					residenceStatuses,
					enablePrimaryAddressAutoComplete,
					enableMailingAddressAutoComplete,
					enableHELOCAddressAutoComplete,
					enableReviewModalAddressAutoComplete,
					enablePreviousAddressAutoComplete
				} = await addressAPI.getConfig();

				commit('setData', { objName: 'states', data: states });
				commit('setData', {
					objName: 'residenceHistoryRequiredInMonths',
					data: residenceHistoryRequiredInMonths
				});
				commit('setData', {
					objName: 'additionalResidentialHistoryThresholdInMonths',
					data: additionalResidentialHistoryThresholdInMonths
				});
				commit('setData', {
					objName: 'residenceStatuses',
					data: residenceStatuses
				});
				commit('setData', {
					objName: 'enablePrimaryAddressAutoComplete',
					data: enablePrimaryAddressAutoComplete
				});
				commit('setData', {
					objName: 'enableMailingAddressAutoComplete',
					data: enableMailingAddressAutoComplete
				});
				commit('setData', {
					objName: 'enableHELOCAddressAutoComplete',
					data: enableHELOCAddressAutoComplete
				});
				commit('setData', {
					objName: 'enableReviewModalAddressAutoComplete',
					data: enableReviewModalAddressAutoComplete
				});
				commit('setData', {
					objName: 'enablePreviousAddressAutoComplete',
					data: enablePreviousAddressAutoComplete
				});

				return {
					states,
					residenceHistoryRequiredInMonths,
					additionalResidentialHistoryThresholdInMonths,
					residenceStatuses,
					enablePrimaryAddressAutoComplete,
					enableMailingAddressAutoComplete,
					enableHELOCAddressAutoComplete,
					enableReviewModalAddressAutoComplete,
					enablePreviousAddressAutoComplete
				};
			} catch (error) {
				throw error;
			}
		},
		async getAddressVendorToken({ commit, state }) {
			if (state.addressVendorToken.length) {
				return state.addressVendorToken;
			}

			try {
				const token = await addressAPI.getAddressVendorToken();

				commit('setData', { objName: 'addressVendorToken', data: token });

				return token;
			} catch (error) {
				throw error;
			}
		},
		async getPropertyAddressConfig({ commit, state }) {
			try {
				const { autoPopulateCountyByPostalCode } = await addressAPI.getPropertyAddressConfig();

				commit('setData', { objName: 'autoPopulateCountyByPostalCode', data: autoPopulateCountyByPostalCode });

				return {
					autoPopulateCountyByPostalCode
				};
			} catch (error) {
				throw error;
			}
		},
		async getDisclosuresConfig({ commit, rootState, state }, disclosureId) {
			//welcome letter is unique to each applicant so caching the value may screw things up if they run two applications in a single browser session

			try {
				const { disclosures, showSlideShowAtSubmit, performPersonVerification } =
					await disclosuresAPI.getConfig({
						loanTypeId: disclosureId,
						applicationId: rootState.application.applicationId
					});

				commit('setData', { objName: 'disclosureId', data: disclosureId });
				commit('setData', { objName: 'disclosures', data: disclosures });
				commit('setData', { objName: 'showSlideShowAtSubmit', data: showSlideShowAtSubmit });
				commit('setData', { objName: 'performPersonVerification', data: performPersonVerification });

				return { disclosures, showSlideShowAtSubmit };
			} catch (error) {
				throw error;
			}
		},
		async getInputConfig({ commit, rootState, state }) {
			try {
				const { inputConfigurations } = await formInputAPI.getConfig();

				commit('setData', { objName: 'inputConfigs', data: inputConfigurations });

				return { inputConfigurations };
			} catch (error) {
				throw error;
			}
		},
		async getEligibilityConfig({ commit, getters, state }) {
			if (getters.hasEligibility) {
				return { counties: state.eligibilityCounties, options: state.eligibilityOptions };
			}

			try {
				const {
					counties,
					options,
					ineligiblePageText,
					eligibilityPageHeader,
					eligibilityPageSuboptionLabel,
					ineligiblePageCardText
				} = await eligibilityAPI.getConfig();

				commit('setData', { objName: 'eligibilityCounties', data: counties });
				commit('setData', { objName: 'eligibilityOptions', data: options });
				commit('setData', { objName: 'ineligiblePageText', data: ineligiblePageText });
				commit('setData', { objName: 'eligibilityPageHeader', data: eligibilityPageHeader });
				commit('setData', { objName: 'eligibilityPageSuboptionLabel', data: eligibilityPageSuboptionLabel });
				commit('setData', { objName: 'ineligiblePageCardText', data: ineligiblePageCardText });

				return {
					counties,
					options,
					ineligiblePageText,
					eligibilityPageHeader,
					eligibilityPageSuboptionLabel,
					ineligiblePageCardText
				};
			} catch (error) {
				throw new Error(error);
			}
		},
		async getEmploymentConfig({ commit, state }) {
			if (
				state.incomeSources.length &&
				state.employmentStatuses.length &&
				state.occupations.length &&
				state.employmentHistoryRequiredInMonths &&
				state.additionalEmploymentHistoryThresholdInMonths
			) {
				return {
					incomeSources: state.incomeSources,
					employmentStatuses: state.employmentStatuses,
					occupations: state.occupations,
					requiredMonths: state.employmentHistoryRequiredInMonths,
					additionalEmploymentHistoryThresholdInMonths: state.additionalEmploymentHistoryThresholdInMonths
				};
			}

			try {
				const {
					incomeSources,
					employmentStatuses,
					occupations,
					employmentHistoryRequiredInMonths,
					additionalEmploymentHistoryThresholdInMonths
				} = await employmentAPI.getConfig();

				commit('setData', { objName: 'incomeSources', data: incomeSources });
				commit('setData', { objName: 'employmentStatuses', data: employmentStatuses });
				commit('setData', { objName: 'occupations', data: occupations });
				commit('setData', {
					objName: 'employmentHistoryRequiredInMonths',
					data: employmentHistoryRequiredInMonths
				});
				commit('setData', {
					objName: 'additionalEmploymentHistoryThresholdInMonths',
					data: additionalEmploymentHistoryThresholdInMonths
				});

				return {
					incomeSources,
					employmentStatuses,
					occupations,
					employmentHistoryRequiredInMonths,
					additionalEmploymentHistoryThresholdInMonths
				};
			} catch (error) {
				throw error;
			}
		},
		async getMaxMonthlyIncome({ commit, state }) {
			if (state.maxMonthlyIncome) {
				return state.maxMonthlyIncome;
			}

			try {
				const { maxMonthlyIncome } = await incomeAPI.getMaxMonthlyIncome();

				commit('setData', { objName: 'maxMonthlyIncome', data: maxMonthlyIncome });

				return maxMonthlyIncome;
			} catch (error) {
				throw error;
			}
		},
		async getIdentificationConfig({ commit, state }) {
			if (
				state.citizenshipStatuses.length &&
				state.maritalStatuses.length &&
				state.identificationTypes.length &&
				state.phoneTypes.length &&
				state.preferredContactMethods.length &&
				typeof state.displayMaritalStatusField !== 'undefined' &&
				typeof state.displayCitizenshipStatusField != 'undefined' &&
				typeof state.displayRecentGraduateField != 'undefined' &&
				typeof state.displayPhoneTypeField != 'undefined' &&
				typeof state.displayPreferredContactMethodField != 'undefined' &&
				state.recentGraduateLink &&
				typeof state.displayFirstTimeAutoBuyerField != 'undefined' &&
				state.firstTimeAutoBuyerFieldLabel &&
				typeof state.requireFormOfIdentificationEntry !== 'undefined' &&
				typeof state.taxpayerIdentificationNumberConfigurations !== 'undefined' &&
				state.countries.length
			) {
				return {
					citizenshipStatuses: state.citizenshipStatuses,
					maritalStatuses: state.maritalStatuses,
					identificationTypes: state.identificationTypes,
					phoneTypes: state.phoneTypes,
					preferredContactMethods: state.preferredContactMethods,
					displayMaritalStatusField: state.displayMaritalStatusField,
					displayCitizenshipStatusField: state.displayCitizenshipStatusField,
					displayRecentGraduateField: state.displayRecentGraduateField,
					displayPhoneTypeField: state.displayPhoneTypeField,
					displayPreferredContactMethodField: state.displayPreferredContactMethodField,
					recentGraduateLink: state.recentGraduateLink,
					displayFirstTimeAutoBuyerField: state.displayFirstTimeAutoBuyerField,
					firstTimeAutoBuyerFieldLabel: state.firstTimeAutoBuyerFieldLabel,
					requireFormOfIdentificationEntry: state.requireFormOfIdentificationEntry,
					taxpayerIdentificationNumberConfigurations: state.taxpayerIdentificationNumberConfigurations,
					countries: state.countries,
					acceptedCountryCodes: state.acceptedCountryCodes
				};
			}

			try {
				const {
					citizenshipStatuses,
					maritalStatuses,
					identificationTypes,
					phoneTypes,
					preferredContactMethods,
					displayMaritalStatusField,
					displayCitizenshipStatusField,
					displayRecentGraduateField,
					displayPhoneTypeField,
					displayPreferredContactMethodField,
					recentGraduateLink,
					displayFirstTimeAutoBuyerField,
					firstTimeAutoBuyerFieldLabel,
					requireFormOfIdentificationEntry,
					taxpayerIdentificationNumberConfigurations,
					countries,
					acceptedCountryCodes
				} = await identificationAPI.getConfig();

				commit('setData', { objName: 'citizenshipStatuses', data: citizenshipStatuses });
				commit('setData', { objName: 'maritalStatuses', data: maritalStatuses });
				commit('setData', { objName: 'identificationTypes', data: identificationTypes });
				commit('setData', { objName: 'phoneTypes', data: phoneTypes });
				commit('setData', { objName: 'preferredContactMethods', data: preferredContactMethods });
				commit('setData', { objName: 'displayMaritalStatusField', data: displayMaritalStatusField });
				commit('setData', { objName: 'displayCitizenshipStatusField', data: displayCitizenshipStatusField });
				commit('setData', { objName: 'displayRecentGraduateField', data: displayRecentGraduateField });
				commit('setData', { objName: 'displayPhoneTypeField', data: displayPhoneTypeField });
				commit('setData', {
					objName: 'displayPreferredContactMethodField',
					data: displayPreferredContactMethodField
				});
				commit('setData', { objName: 'recentGraduateLink', data: recentGraduateLink });
				commit('setData', { objName: 'displayFirstTimeAutoBuyerField', data: displayFirstTimeAutoBuyerField });
				commit('setData', { objName: 'firstTimeAutoBuyerFieldLabel', data: firstTimeAutoBuyerFieldLabel });
				commit('setData', {
					objName: 'requireFormOfIdentificationEntry',
					data: requireFormOfIdentificationEntry
				});
				commit('setData', {
					objName: 'taxpayerIdentificationNumberConfigurations',
					data: taxpayerIdentificationNumberConfigurations
				});

				commit('setData', { objName: 'countries', data: countries.filter(config => config.countryCode) });

				commit('setData', { objName: 'acceptedCountryCodes', data: acceptedCountryCodes });

				return {
					citizenshipStatuses,
					maritalStatuses,
					identificationTypes,
					phoneTypes,
					preferredContactMethods,
					displayMaritalStatusField,
					displayCitizenshipStatusField,
					displayRecentGraduateField,
					displayPhoneTypeField,
					displayPreferredContactMethodField,
					recentGraduateLink,
					displayFirstTimeAutoBuyerField,
					firstTimeAutoBuyerFieldLabel,
					requireFormOfIdentificationEntry,
					taxpayerIdentificationNumberConfigurations,
					countries: state.countries,
					acceptedCountryCodes
				};
			} catch (error) {
				throw error;
			}
		},
		async getCoApplicantConfig({ commit, state }) {
			try {
				const { allowCoApplicantFormOfIdentificationEntry, coApplicantIdentificationIsRequired } =
					await coApplicantAPI.getConfig();

				commit('setData', {
					objName: 'requireCoApplicantFormOfIdentificationEntry',
					data: allowCoApplicantFormOfIdentificationEntry
				});

				return {
					allowCoApplicantFormOfIdentificationEntry,
					coApplicantIdentificationIsRequired
				};
			} catch (error) {
				throw error;
			}
		},
		async getIncomeConfig({ commit, state }) {
			if (state.incomeSources.length) {
				return { incomeSources: state.incomeSources };
			}

			try {
				const { incomeSources } = await incomeAPI.getConfig();

				commit('setData', { objName: 'incomeSources', data: incomeSources });

				return { incomeSources };
			} catch (error) {
				throw error;
			}
		},
		async getDeclineConfig({ commit, state }) {
			if (state.declinePageContent) {
				return { content: declinePageContent };
			}

			try {
				const content = await errorAPI.getDeclineConfig();

				commit('setData', { objName: 'declinePageContent', data: content });

				return content;
			} catch (error) {
				throw error;
			}
		},
		async getReviewConfig({ commit, state, rootState }) {
			if (
				state.reviewPageContent &&
				state.primaryDocumentUploadEnabled &&
				state.coborrowerDocumentUploadEnabled
			) {
				return {
					content: state.reviewPageContent,
					primaryDocumentUploadEnabled: state.primaryDocumentUploadEnabled,
					coborrowerDocumentUploadEnabled: state.coborrowerDocumentUploadEnabled
				};
			}

			try {
				const { content, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled } =
					await errorAPI.getReviewConfig(rootState.application.productTypeId);

				commit('setData', { objName: 'reviewPageContent', data: content });
				commit('setData', { objName: 'primaryDocumentUploadEnabled', data: primaryDocumentUploadEnabled });
				commit('setData', {
					objName: 'coborrowerDocumentUploadEnabled',
					data: coborrowerDocumentUploadEnabled
				});

				return { content, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled };
			} catch (error) {
				throw error;
			}
		},
		async getDocumentUploadConfig({ commit, state }) {
			if (state.documentUploadConfiguration) {
				return {
					documentUploadConfiguration: state.documentUpload
				};
			}

			try {
				const { documentUploadConfiguration } = await documentAPI.getDocumentUploadConfig();

				commit('setData', { objName: 'documentUploadConfig', data: documentUploadConfiguration });

				return { documentUploadConfiguration };
			} catch (error) {
				throw error;
			}
		},
		async getProductTypes({ commit, state }) {
			if (state.productTypes.length && state.productTypeEnum && Object.keys(state.productTypeEnum).length) {
				return { list: state.productTypes, enumObj: state.productTypeEnum };
			}

			try {
				const {
					data: { products }
				} = await axios.get(`${process.env.BASE_URL}api/SelectProduct/Configuration`);

				const productTypes = products.map(({ displayText, id, routePrefix }) => ({
					value: id,
					label: displayText,
					routePrefix
				}));

				const productTypeEnum = {};

				for (const object of products) {
					productTypeEnum[object.id] = object.routePrefix;
					productTypeEnum[object.routePrefix.replace(/ /g, '_').toUpperCase()] = object.id;
				}

				commit('setData', { objName: 'productTypes', data: productTypes });
				commit('setData', { objName: 'productTypeEnum', data: productTypeEnum });

				return { list: productTypes, enumObj: productTypeEnum };
			} catch (error) {
				throw error;
			}
		},
		async getPropertyTypes({ commit, state }) {
			if (state.propertyTypes.length && state.propertyTypeEnum && Object.keys(state.propertyTypeEnum).length) {
				return { list: state.propertyTypes, enumObj: state.propertyTypeEnum };
			}

			try {
				const {
					data: { propertyTypes }
				} = await axios.get(`${process.env.BASE_URL}api/PropertyType/Configuration`);

				const propertyTypesList = getListValueFromConfigObject(propertyTypes);
				const propertyTypeEnum = getEnumFromConfigObject(propertyTypes);

				commit('setData', { objName: 'propertyTypes', data: propertyTypesList });
				commit('setData', { objName: 'propertyTypeEnum', data: propertyTypeEnum });

				return { list: propertyTypesList, enumObj: propertyTypeEnum };
			} catch (error) {
				throw error;
			}
		},
		async getPropertyStatuses({ commit, state }) {
			if (
				state.propertyStatuses.length &&
				state.propertyStatusEnum &&
				Object.keys(state.propertyStatusEnum).length
			) {
				return { list: state.propertyStatuses, enumObj: state.propertyStatusEnum };
			}

			try {
				const {
					data: { propertyStatuses }
				} = await axios.get(`${process.env.BASE_URL}api/PropertyStatus/Configuration`);

				let displayedPropertyStatuses = propertyStatuses.filter(x => x.displayed);

				const propertyStatusesList = getListValueFromConfigObject(displayedPropertyStatuses);
				const propertyStatusEnum = getEnumFromConfigObject(propertyStatuses);

				commit('setData', { objName: 'propertyStatuses', data: propertyStatusesList });
				commit('setData', { objName: 'propertyStatusEnum', data: propertyStatusEnum });

				return { list: propertyStatusesList, enumObj: propertyStatusEnum };
			} catch (error) {
				throw error;
			}
		},
		async getDemographicConfig({ commit, state }) {
			if (state.ethnicGroups.length && state.genders.length && state.races.length) {
				return { ethnicGroups: state.ethnicGroups, genders: state.genders, races: state.races };
			}

			try {
				const { ethnicGroups, genders, races } = await demographicAPI.getConfig();

				commit('setData', { objName: 'ethnicGroups', data: ethnicGroups });
				commit('setData', { objName: 'genders', data: genders });
				commit('setData', { objName: 'races', data: races });

				return { ethnicGroups, genders, races };
			} catch (error) {
				throw new Error(error);
			}
		},
		async getCoApplicantModalConfig({ commit, state }) {
			if (state.coApplicantModalTitle.length && state.coApplicantModalContent.length) {
				return {
					coApplicantModalTitle: state.coApplicantModalTitle,
					coApplicantModalContent: state.coApplicantModalContent
				};
			}

			try {
				const { coApplicantModalTitle, coApplicantModalContent } = await coApplicantAPI.getModalConfig();

				commit('setData', { objName: 'coApplicantModalTitle', data: coApplicantModalTitle });
				commit('setData', { objName: 'coApplicantModalContent', data: coApplicantModalContent });

				return { coApplicantModalTitle, coApplicantModalContent };
			} catch (error) {
				throw new Error(error);
			}
		},
		async getPaymentProtectionProductConfigurations({ commit, rootState, state }) {
			if (
				state.paymentProtectionSectionTitle &&
				state.paymentProtectionSectionDescription &&
				state.paymentProtectionProductConfigs.length
			) {
				return {
					paymentProtectionProductConfigs: state.paymentProtectionProductConfigs,
					paymentProtectionSectionTitle: state.paymentProtectionSectionTitle,
					paymentProtectionSectionDescription: state.paymentProtectionSectionDescription
				};
			}

			try {
				const { productConfigurations, paymentProtectionSectionTitle, paymentProtectionSectionDescription } =
					await paymentProtectionAPI.getConfig({
						applicationId: rootState.application.applicationId
					});

				commit('setData', { objName: 'paymentProtectionProductConfigs', data: productConfigurations });
				commit('setData', { objName: 'paymentProtectionSectionTitle', data: paymentProtectionSectionTitle });
				commit('setData', {
					objName: 'paymentProtectionSectionDescription',
					data: paymentProtectionSectionDescription
				});

				return { productConfigurations, paymentProtectionSectionTitle, paymentProtectionSectionDescription };
			} catch (error) {
				throw error;
			}
		},
		async getReviewPageConfig({ commit, state }) {
			try {
				const { includeEmploymentForReview } = await reviewAPI.getConfig();

				commit('setData', { objName: 'includeEmploymentForReview', data: includeEmploymentForReview });

				return {
					includeEmploymentForReview
				};
			} catch (error) {
				throw error;
			}
		}
	},
	getters: {
		hasEligibility: state => {
			const hasExistingOptions = Object.keys(state.eligibilityOptions).length !== 0;

			return hasExistingOptions && state.eligibilityCounties ? true : false;
		},
		getInputConfig: state => label => {
			return state.inputConfigs.find(x => x.helpTextTitle.toLowerCase() == label.toLowerCase());
		},
		getIneligiblePageText: state => state.ineligiblePageText,
		getEligibilityPageHeader: state => state.eligibilityPageHeader,
		getEligibilityPageSuboptionLabel: state => state.eligibilityPageSuboptionLabel,
		getIneligiblePageCardText: state => state.ineligiblePageCardText
	}
};
