import { CaptchaImplementation } from '@/constants';
import { buildCaptchaImplementationConfigurations } from '@/utils';
import axios from '@/utils/axios';
import _cloneDeep from 'lodash.clonedeep';
import * as modules from './modules';
import { IModuleState } from './modules';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Sentry from '@sentry/vue';
import setData from './setData';

export interface ICaptcha {
	enabled: boolean;
	implementation: number;
}

export interface ICollateralValuationAvailability {
	isCollateralValuationAutoDisabled: boolean;
	isCollateralValuationAutoRefiDisabled: boolean;
	isCollateralValuationMarineDisabled: boolean;
	isCollateralValuationMotorcycleDisabled: boolean;
	isCollateralValuationRvDisabled: boolean;
}

export interface IRootState extends IModuleState {
	isLoading: boolean;
	defaultsLoaded: boolean;
	siteAvailability: null;
	token: string | null;
	unsupportedBrowser: boolean;
	captchaUseConfig: null;
	captchaV3: ICaptcha | null;
	captchaV2: ICaptcha | null;
	captchaV2Hidden: ICaptcha | null;
	loaderOption: number;
	collateralValuationAvailability: ICollateralValuationAvailability | null;
	redirectUrl: string;
	defaultAuthenticationViewToExistingMember: boolean;
	existingMemberFlowEnabled: boolean;
	clientWebsiteLink: string;
	clientOpenAnywhereLink: string;
	clientTelephone: string;
	clientLocationsWebsiteLink: string;
	endOfApplicationFlowButtonText: string;
}

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
	strict: process.env.NODE_ENV !== 'production',
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
			paths: ['applicant', 'application', 'coApplicant', 'config', 'error', 'routeEnforcer', 'sessionTimer', 'personVerification']
		})
	],
	modules,
	state: {
		isLoading: false,
		loaderOption: 1,
		defaultsLoaded: false,
		siteAvailability: null,
		collateralValuationAvailability: null,
		token: null,
		unsupportedBrowser: false,
		captchaUseConfig: null,
		captchaV3: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV3
		},
		captchaV2: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV2
		},
		captchaV2Hidden: {
			enabled: false,
			implementation: CaptchaImplementation.RECAPTCHAV2HIDDEN
		},
		redirectUrl: '',
		defaultAuthenticationViewToExistingMember: false,
		existingMemberFlowEnabled: false,
		clientWebsiteLink: '',
		clientOpenAnywhereLink: '',
		clientTelephone: '',
		clientLocationsWebsiteLink: '',
		endOfApplicationFlowButtonText: ''
	},
	mutations: {
		setLoading(state, { isLoading = false, loaderOption = 1 }) {
			state.isLoading = isLoading;
			state.loaderOption = loaderOption;
		},
		setData,
		resetState(state) {
			state.isLoading = false;
			state.loaderOption = 1;
			state.defaultsLoaded = false;
			state.siteAvailability = null;
			state.collateralValuationAvailability = null;
			state.token = null;
			state.unsupportedBrowser = false;
			state.captchaUseConfig = null;
			state.captchaV3 = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV3
			};
			state.captchaV2 = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV2
			};
			state.captchaV2Hidden = {
				enabled: false,
				implementation: CaptchaImplementation.RECAPTCHAV2HIDDEN
			};
			state.redirectUrl = '';
			state.defaultAuthenticationViewToExistingMember = false;
			state.existingMemberFlowEnabled = false;
			state.clientOpenAnywhereLink = '';
			state.clientTelephone = '';
			state.clientLocationsWebsiteLink = '';
			state.endOfApplicationFlowButtonText = '';
		}
	},
	actions: {
		async setDefaults({ commit, dispatch, state }, isError) {
			try {
				if (!isError) {
					commit('setLoading', { isLoading: true });

					await Promise.all([dispatch('getApplicationConfiguration')]).then(
						async () =>
							await Promise.all([
								dispatch('config/getProductTypes'),
								dispatch('getSiteAvailability'),
								dispatch('getCollateralValuationAvailability')
							])
					);

					commit('setData', {
						objName: 'defaultsLoaded',
						data: true
					});
					commit('setLoading', { isLoading: false });

					return state.defaultsLoaded;
				}
			} catch (error) {

				throw error;
			}
		},
		async getApplicationConfiguration({ commit, state }: any) {
			if (state.captcha) {
				return state.captcha;
			}

			try {
				const {
					data: {
						captchaConfigurations: captcha,
						captchaUseConfigurations: captchaUseConfig,
						redirectUrl,
						defaultAuthenticationViewToExistingMember,
						existingMemberFlowEnabled,
						clientWebsiteLink,
						clientOpenAnywhereLink,
						clientTelephone,
						clientLocationsWebsiteLink,
						endOfApplicationFlowButtonText
					}
				} = await axios.get(`${process.env.BASE_URL}api/Application/Configuration`);

				if (captcha) {
					let captchaConfigs = buildCaptchaImplementationConfigurations(captcha);

					if (captchaConfigs.v2.enabled) {
						commit('setData', {
							objName: 'captchaV2',
							data: captchaConfigs.v2
						});
					}
					if (captchaConfigs.v2Hidden.enabled) {
						commit('setData', {
							objName: 'captchaV2Hidden',
							data: captchaConfigs.v2Hidden
						});
					}
					if (captchaConfigs.v3) {
						commit('setData', {
							objName: 'captchaV3',
							data: captchaConfigs.v3
						});
					}
				}

				if (captchaUseConfig) {
					commit('setData', {
						objName: 'captchaUseConfig',
						data: captchaUseConfig
					});
				}

				if (redirectUrl) {
					commit('setData', {
						objName: 'redirectUrl',
						data: redirectUrl
					});
				}

				if (defaultAuthenticationViewToExistingMember) {
					commit('setData', {
						objName: 'defaultAuthenticationViewToExistingMember',
						data: defaultAuthenticationViewToExistingMember
					});
				}

				if (existingMemberFlowEnabled) {
					commit('setData', {
						objName: 'existingMemberFlowEnabled',
						data: existingMemberFlowEnabled
					});
				}

				commit('setData', {
					objName: 'clientWebsiteLink',
					data: clientWebsiteLink || ''
				});

				commit('setData', {
					objName: 'clientOpenAnywhereLink',
					data: clientOpenAnywhereLink || ''
				});

				commit('setData', {
					objName: 'clientTelephone',
					data: clientTelephone || ''
				});

				commit('setData', {
					objName: 'clientLocationsWebsiteLink',
					data: clientLocationsWebsiteLink || ''
				});

				commit('setData', {
					objName: 'endOfApplicationFlowButtonText',
					data: endOfApplicationFlowButtonText || ''
				});

				return { captcha, redirectUrl };
			} catch (error) {

				throw error;
			}
		},
		async getSiteAvailability({ commit, state }: any) {
			if (state.siteAvailability) {
				return state.siteAvailability;
			}

			try {
				const {
					data: {
						isMemberChannelDisabled: memberDisabled = false,
						memberChannelDisabledMessage: memberMessage = '',
						isNonMemberChannelDisabled: nonMemberDisabled = false,
						nonMemberChannelMessage: nonMemberMessage = '',
						isResumeDisabled: resumeDisabled = false,
						resumeDisabledMessage: resumeMessage = '',
						isSSODisabled: ssoDisabled = false,
						ssoDisabledMessage: ssoMessage = '',
						isBrowserUnsupported,
						token
					}
				} = await axios.get(`${process.env.BASE_URL}api/Availability`);

				const data = {
					memberDisabled,
					nonMemberDisabled,
					memberMessage,
					nonMemberMessage,
					resumeDisabled,
					resumeMessage,
					ssoDisabled,
					ssoMessage
				};

				commit('setData', {
					objName: 'siteAvailability',
					data
				});

				commit('setData', {
					objName: 'unsupportedBrowser',
					data: isBrowserUnsupported ? true : false
				});

				commit('setData', {
					objName: 'token',
					data: token
				});

				return data;
			} catch (error) {
				throw error;
			}
		},
		async getCollateralValuationAvailability({ commit, state }: any) {
			if (state.collateralValuationAvailability) {
				return state.collateralValuationAvailability;
			}

			try {
				const {
					data: {
						isCollateralValuationAutoDisabled = false,
						isCollateralValuationAutoRefiDisabled = false,
						isCollateralValuationMarineDisabled = false,
						isCollateralValuationMotorcycleDisabled = false,
						isCollateralValuationRvDisabled = false
					}
				} = await axios.get(`${process.env.BASE_URL}api/CollateralValuation/Availability`);

				const data = {
					isCollateralValuationAutoDisabled,
					isCollateralValuationAutoRefiDisabled,
					isCollateralValuationMarineDisabled,
					isCollateralValuationMotorcycleDisabled,
					isCollateralValuationRvDisabled
				};

				commit('setData', {
					objName: 'collateralValuationAvailability',
					data
				});

				return data;
			} catch (error) {
				throw error;
			}
		},
		resetState({ commit, dispatch }) {
			dispatch('applicant/resetState');
			dispatch('coApplicant/resetState');
			commit('application/resetState');
			commit('config/resetState');
			commit('error/resetState');
			commit('personVerification/resetState');
		},
		updateCaptcha({ commit, state }: any, { implementation, captcha }) {
			if (captcha) {
				switch (implementation) {
					case CaptchaImplementation.RECAPTCHAV2:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2), captcha);
							commit('setData', {
								objName: 'captchaV2',
								data: merged
							});
						}
						break;
					case CaptchaImplementation.RECAPTCHAV2HIDDEN:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2Hidden), captcha);
							commit('setData', {
								objName: 'captchaV2Hidden',
								data: merged
							});
						}
						break;
					case CaptchaImplementation.RECAPTCHAV3:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV3), captcha);
							commit('setData', {
								objName: 'captchaV3',
								data: merged
							});
						}
						break;
				}
			}
		},
		updateCaptchaUse({ commit, state }: any, captchaUseConfig: any) {
			const origUseConfigs = _cloneDeep(state.captchaUseConfig);

			const index = origUseConfigs.findIndex(
				(config: { implementation: any; processingStage: any }) =>
					config.implementation === captchaUseConfig.implementation &&
					config.processingStage === captchaUseConfig.processingStage
			);

			if (index) {
				origUseConfigs.splice(index, 1);
				origUseConfigs.push(captchaUseConfig);
			}

			commit('setData', {
				objName: 'captchaUseConfig',
				data: origUseConfigs
			});
		}
	},
	getters: {
		xsrfToken: state => state.token,
		captchaV3: state => state.captchaV3,
		captchaV2: state => state.captchaV2,
		captchaV2Hidden: state => state.captchaV2Hidden,
		captchaConfig: state => [state.captchaV2, state.captchaV2Hidden, state.captchaV3],
		captchaUseConfig: state => state.captchaUseConfig,
		clientWebsiteLink: state => state.clientWebsiteLink,
		clientOpenAnywhereLink: state => state.clientOpenAnywhereLink,
		clientTelephone: state => state.clientTelephone,
		clientLocationsWebsiteLink: state => state.clientLocationsWebsiteLink,
		getEndOfApplicationFlowButtonText: state => state.endOfApplicationFlowButtonText
	}
};

export default new Vuex.Store(store);
