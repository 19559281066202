export default {
    params: ['fieldName', 'regex'],
    validate(value, { fieldName, regex }) {

        const tinError = `The ${fieldName} you have provided does not match format requirements. Please check the value entered.`;

        if (!regex) return true;

        return regex.test(value) || tinError;
    }
};