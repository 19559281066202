<template functional>
<g>
    <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9s3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4S13.6,0.6,9,0.6z"></path>
    <g id="Layer_2" data-name="Layer 2" transform="matrix(0.227273, 0, 0, 0.229108, -1.350007, -1.718536)">
        <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
            <g id="Layer_1-2-2" data-name="Layer 1-2"></g>
        </g>
        <polyline class="cls-4" points="36.54 33.96 36.54 27.96 30.54 27.96 30.54 39.96"></polyline>
        <line class="cls-4" x1="27.54" y1="64.96" x2="27.54" y2="49.96"></line>
        <polygon class="cls-4" points="45.54 24.96 23.54 46.96 27.54 50.96 45.54 32.96 63.54 50.96 67.54 46.96 45.54 24.96 45.54 24.96"></polygon>
        <polygon class="cls-4" points="42.54 49.96 34.54 49.96 34.54 64.96 42.54 64.96 42.54 49.96 42.54 49.96"></polygon>
        <line class="cls-4" x1="23.54" y1="64.96" x2="45.54" y2="64.96"></line>
        <path class="cls-4" d="M67.25,59.79a11,11,0,1,1-11-11,11,11,0,0,1,11,11Z"></path>
        <path class="cls-4" d="M53.25,62.79h0a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2v-.56a2,2,0,0,0-1.36-1.9l-3.27-1.09a2,2,0,0,1-1.37-1.9v-.55a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2h0"></path>
        <line class="cls-4" x1="56.25" y1="54.79" x2="56.25" y2="51.79"></line>
        <line class="cls-4" x1="56.25" y1="64.79" x2="56.25" y2="67.79"></line>
        </g>
    </g>
</g>
</template>

<script>
    export default {
        name: "InvestmentPropertyIcon",
    };
</script>

<style scoped>
    .cls-1,
    .cls-4 {
        fill: none;
    }
    .cls-1 {
        stroke: var(--success);
        stroke-width: 5px;
    }
    .cls-1,
    .cls-2 {
        stroke-miterlimit: 10;
    }
    .cls-2 {
        fill: var(--success);
        stroke: var(--white);
    }
    .cls-3 {
        fill: var(--white);
    }
    .cls-4 {
        stroke: var(--primary);
        stroke-linejoin: round;
        stroke-width: 2px;
    }
</style>
