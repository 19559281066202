<template>
	<div class="input-container" @dragover.prevent @drop.prevent>
		<div class="form-error-promt">{{ FormErrors }}</div>
		<p class="hint-text">You may drag or attach multiple files before clicking SUBMIT to upload your documents.</p>
		<div class="initial-block draggable-section" @drop="filesChanges($event.dataTransfer)">
			<p class="draggable">Drag File Here or Click to Upload</p>
			<input
				type="file"
				:multiple="allowMultiple"
				:name="name"
				:id="id || name"
				:disabled="disabled"
				@change="filesChanges($event.target)"
				class="input-file"
			/>
		</div>

		<div class="initial-block" v-if="Files.size">
			<div v-for="key in this.Files.keys()" :key="key">
				<div class="file-item-wrapper">
					<div class="center-section" center-section>
						<p>{{ key }}</p>
					</div>
					<div class="center-section file-item-delete" v-on:click="deleteFile(key)">
						<BaseIcon name="delete" height="24px" width="24px" color="var(--danger-50)" viewBox="0 0 20 20"
							><CloseIcon
						/></BaseIcon>
					</div>
				</div>
			</div>
		</div>

		<label class="initial-block">
			<p class="center-section" />
			<p class="button">SUBMIT</p>
			<button class="hidden" @click="submitFiles"></button>
		</label>
	</div>
</template>

<script>
	import _cloneDeep from 'lodash.clonedeep';
	import { BaseIcon, CloseIcon } from '@/components/icons';
	export default {
		name: 'Upload',
		components: {
			BaseIcon,
			CloseIcon
		},
		props: {
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			id: {
				type: String,
				required: false
			},
			name: {
				type: String,
				required: true
			},
			acceptedFiles: {
				type: String,
				required: true
			},
			acceptedFilesDisplayText: {
				type: String,
				required: true
			},
			maxUploadSizeMB: {
				type: Number,
				required: true
			},
			combinedMaxUploadSizeMB: {
				type: Number,
				required: true
			},
			allowMultiple: {
				type: Boolean,
				required: false
			}
		},
		data() {
			return {
				Files: {},
				FormErrors: '' //Could Not Submit Files: Invalid File Type
			};
		},
		methods: {
			filesChanges(target) {
				let newFiles = this.Files.size ? this.Files : new Map();

				Array.from(Array(target.files.length).keys()).map(idx => {
					if (!newFiles.has(target.files[idx].name)) {
						newFiles.set(target.files[idx].name, target.files[idx]);
					}
				});

				this.Files = _cloneDeep(newFiles);
			},
			submitFiles() {
				const data = new FormData();
				const fileCount = this.Files.size;
				const validationErrors = this.validateFiles(fileCount);

				this.FormErrors = validationErrors;
				if (validationErrors) {
					return;
				}

				//construct FormData object to emit
				this.Files.forEach(file => {
					data.append(this.name, file, file.name);
				});

				// Catch this emit on the parent and submit data to the backend, while using fileCount to display a success message on the UI (ie: 4 files uploaded)
				this.$emit('save', { data, fileCount });
			},
			validateFiles(fileCount) {
				let combinedFileSize = 0;
				let invalidTypeFound = '';
				let individualFileTooLarge = false;

				if (!fileCount) {
					return 'No Files Selected to Upload';
				}

				this.Files.forEach(file => {
					combinedFileSize += file.size;

					if (!this.acceptedFiles.includes(file.type.split('/')[1])) {
						invalidTypeFound = file.name.split('.')[1] ? '.' + file.name.split('.')[1] : file.type;
					}

					if (file.size / 1048576 > this.maxUploadSizeMB) {
						individualFileTooLarge = true;
					}
				});

				if (individualFileTooLarge) {
					return (
						'Oops! You are attempting to save a file that is too large. The maximum upload size is ' +
						this.maxUploadSizeMB +
						' MB per file.'
					);
				}

				if (combinedFileSize / 1048576 > this.combinedMaxUploadSizeMB) {
					return (
						'Oops! The total file upload size is too large. The maximum upload size is ' +
						this.combinedMaxUploadSizeMB +
						' MB'
					);
				}

				return invalidTypeFound
					? `Oops! You are attempting to save a file type ${invalidTypeFound} that is not allowed. To resolve this issue, please upload a ${this.acceptedFilesDisplayText} file type.`
					: '';
			},
			deleteFile(key) {
				const newFiles = new Map([...this.Files].filter(([k, v]) => k !== key));
				this.Files = _cloneDeep(newFiles);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		.draggable-section {
			.input-file {
				opacity: 0;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				cursor: pointer;
			}
			&:hover .draggable {
				background-color: var(--secondary-25);
				font-weight: 700;
			}
		}

		.initial-block {
			position: relative;

			.draggable {
				border: 4px solid var(--primary);
				border-style: dashed;
				margin: 0;
				padding: 0.75rem;
				text-align: center;
				font-weight: 300;
				font-size: 16px;
				color: var(--primary);
				transition: background-color 0.5s;
				cursor: pointer;
			}

			.center-section {
				padding: 0.5rem;
				text-align: center;
			}

			.button {
				box-sizing: border-box;
				background-color: var(--primary);
				padding: 0.5rem 2rem;
				cursor: pointer;
				text-align: center;
				vertical-align: middle;
				text-transform: uppercase;
				font-size: 1.25rem;
				font-weight: 600;
				color: var(--white);
				transition: all 0.3s linear;
				text-decoration: none;
				border: 2px solid var(--primary);
				max-width: 12rem;
				margin: 0 auto;
				cursor: pointer;

				&:hover {
					background-color: transparent;
					border: 2px solid var(--primary);
					color: var(--primary);
				}
			}
		}

		p,
		.uploading {
			font-size: 20px;
			font-weight: 700;
			color: var(--primary);
		}

		.uploading {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2rem 1rem;
			border: 1px solid var(--primary);
		}

		.hidden {
			visibility: hidden;
		}

		.file-item-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			border-style: solid;
			border-color: var(--primary);
			margin-top: 0.75rem;

			.file-item-delete:hover {
				opacity: 0.75;
			}

			p {
				font-weight: 100;
			}
		}

		.form-error-promt {
			color: var(--danger);
			margin-bottom: 0.5rem;
		}

		.hint-text{
			font-weight:normal;
			font-size:0.8rem;
			margin-bottom:0.4rem;
			text-align:left;
			color:var(--text-primary);
		}
	}
</style>
