<template functional>

    <path d="M25,50C11.2,50,0,38.8,0,25S11.2,0,25,0s25,11.2,25,25S38.8,50,25,50z M25,2.4C12.5,2.4,2.4,12.5,2.4,25
	S12.5,47.6,25,47.6S47.6,37.5,47.6,25S37.5,2.4,25,2.4z M21.1,36.4l-7.5-8.3c-0.4-0.5-0.4-1.2,0.1-1.7c0.5-0.4,1.2-0.4,1.7,0.1
	l5.6,6.2l15-18.9c0.4-0.5,1.2-0.6,1.7-0.2c0.5,0.4,0.6,1.2,0.2,1.7L21.1,36.4z"/>

</template>





<script>

    export default {

        name: 'ThinCheckIcon'

    }

</script>