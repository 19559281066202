export default {
    params: ['state', 'type'],
    validate(value, { state, type }) {

        const licenseError = "The {_field_} field must contain a valid license";

        if (type != 1) return true; //Temporary fix; stops us from performing this validation on State IDs

        switch(state) {

            case 'AZ':
                const az1 = /^[a-zA-Z]\d{8}$/g; // Starts with a letter and ends in 8 digits
                const az2 = /^\d{9}$/g; // Must be 9 digits
                const az3 = /^[a-zA-Z]{2}\d{2}$/g; // Starts with 2 letters and ends with 2 digits
                const az4 = /^[a-zA-Z]{2}\d{3}$/g; // Starts with 2 letters and ends with 3 digits
                const az5 = /^[a-zA-Z]{2}\d{4}$/g; // Starts with 2 letters and ends with 4 digits
                const az6 = /^[a-zA-Z]{2}\d{5}$/g; // Starts with 2 letters and ends with 5 digits
                return az1.test(value) || az2.test(value) || az3.test(value) || az4.test(value) || az5.test(value) || az6.test(value) || licenseError;

            case 'CA':
                const ca = /^[a-zA-Z]\d{7}$/g; // Starts with a letter and ends in 7 digits
                return ca.test(value) || licenseError;
                
            case 'HI':
                const hi1 = /^[hH]\d{8}$/g; // Starts with h and ends in 8 digits
                const hi2 = /^\d{9}$/g; // Must be 9 digits
                return hi1.test(value) || hi2.test(value) || licenseError;

            case 'TX':
                const tx = /^[0]\d{7}$/g; //starting with 0 ending with 7 digits
                const tx2 = /^[1]\d{7}$/g; //starting with 1 ending with 7 digits
                const tx3 = /^[2]\d{7}$/g; //starting with 2 ending with 7 digits
                const tx4 = /^[3]\d{7}$/g; //starting with 3 ending with 7 digits
                const tx5 = /^[4]\d{7}$/g; //starting with 4 ending with 7 digits
                return tx.test(value) || tx2.test(value) || tx3.test(value) || tx4.test(value) || tx5.test(value) || licenseError;
                
            default:
                return true;

        };

    }
};