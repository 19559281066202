import { Module } from 'vuex';

import axios from '@/utils/axios';
import {pageImagePaths, appleTouchIconPaths} from '@/defaultData/imagePaths';
import animationPaths from '@/defaultData/animationPaths';
import { IHashMap } from '@/interfaces';
import { IRootState } from '..';
import localStorage from '../localStorage';
import { logError } from '@/utils';

export interface IBrandingState {
	clientName: string | null;
	clientShortName: string | null;
	cdnBasePath: string | null;
	cssFilePath: string | null;
	theme: string | null;
	images: IHashMap<string> | null;
	brandingLoaded: boolean;
}

interface IBrandingRequest {
	clientName: string;
	clientShortName: string;
	cdnBasePath: string;
	cssFilePath: string;
	theme: string;
	images: IHashMap<string>;
}

const branding: Module<IBrandingState, IRootState> = {
	namespaced: true,
	state: {
		clientName: null,
		clientShortName: null,
		cdnBasePath: null,
		cssFilePath: null,
		theme: null,
		images: null,
		brandingLoaded: false
	},
	mutations: {
		setBrandingState(state, newState: IBrandingRequest) {
			state.clientName = newState.clientName;
			state.clientShortName = newState.clientShortName || newState.clientName;
			state.cdnBasePath = newState.cdnBasePath;
			state.cssFilePath = newState.cssFilePath;
			state.theme = newState.theme;
			state.images = newState.images;
			state.brandingLoaded = true;
		},
		setLogo(state, logoPath: string){
			if(state.images) state.images["logo"] = logoPath
		},
		resetState(state) {
			state.clientName = null;
			state.clientShortName = null;
			state.cdnBasePath = null;
			state.theme = null;
			state.images = null;
			state.brandingLoaded = true;
		}
	},
	actions: {
		async setBranding({ dispatch, commit, state }, { hostName, productName, themeCode }) {

			if (!state.brandingLoaded) {
				
				//if a non default theme is already stored locally
				if(localStorage.hasKey('branding')){
					const localBrandingData = localStorage.get('branding');

					addStyleSheetToDOM(localBrandingData.cssFilePath);
					addFavicon(localBrandingData.cdnBasePath);

					return commit('setBrandingState', { 
						clientName: localBrandingData.clientName,
						cdnBasePath: localBrandingData.cdnBasePath,
						cssFilePath: localBrandingData.cssFilePath,
						theme: localBrandingData.theme,
						images: localBrandingData.images
					});

				}

				const brandingQuery = themeCode ?
					`${process.env.BASE_URL}api/Domain/${hostName}/Product/${productName}/Theme/${themeCode}/Branding`
					: `${process.env.BASE_URL}api/Domain/${hostName}/Product/${productName}/Branding`;

				try {

					const { data: {
						branding
					} } = await axios(brandingQuery);

					addStyleSheetToDOM(branding.cssFilePath);
					addFavicon(branding.clientProductCDNBasePath);

					commit('setBrandingState', { 
						clientName: branding.clientDisplayName,
						cdnBasePath: branding.clientProductCDNBasePath,
						cssFilePath: branding.cssFilePath,
						theme: branding.defaultTheme ? 'default' : themeCode, 
						images: buildPageImagePaths(branding.clientProductCDNBasePath)
					});

					//store not default theme locally so that is will be used later
					if(state.theme !== 'default'){
						localStorage.set('branding', state, (24*60*60*1000));
					}

					addAppleTouchIcons(branding.clientProductCDNBasePath)

				} catch (error) {
					
					dispatch('setFallbackBranding');

				}
			}
		},

		setFallbackBranding({ commit, state }) {

			commit('setBrandingState', { 
				clientName: 'Online Account Opening',
				cdnBasePath: null,
				cssFilePath: '/default.css',
				theme: 'default',
				images: Object.fromEntries([])
			});

			addStyleSheetToDOM(state.cssFilePath);
		}
		
	},
	getters: {
		clientName: state => state.clientName,
		nonMemberLoaderPath: state => state.cdnBasePath ? state.cdnBasePath+animationPaths.nonMemberLoader : null,
		memberLoaderPath: state => state.cdnBasePath ? state.cdnBasePath+animationPaths.memberLoader : null
	}
};

const buildPageImagePaths = (clientProductCDNBasePath: string) => {
	const relativePageImagePaths = Object.entries(pageImagePaths);
	const CDNPageImageLinks = relativePageImagePaths.map(entry => [entry[0], clientProductCDNBasePath+entry[1]]);
	return Object.fromEntries(CDNPageImageLinks);
}


const addStyleSheetToDOM = (cssFilePath: string | null) => {
	if(!cssFilePath) return null;
	//Add stylesheet to dom
	const head = document.getElementsByTagName('HEAD')[0];
	let styleSheetLink = document.createElement('link');
	styleSheetLink.rel = 'stylesheet';
	styleSheetLink.type = 'text/css';
	styleSheetLink.href = cssFilePath;
	head.appendChild(styleSheetLink); 
}

const addFavicon = (clientProductCDNBasePath: string) => {
	let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
	if(!link) {
		link = document.createElement('link') as HTMLLinkElement;
		link.rel = 'icon';
		document.head.appendChild(link);
	}
	link.href = clientProductCDNBasePath+"/favicon.ico"
}

const addAppleTouchIcons = (clientProductCDNBasePath: string) => {
	const head = document.getElementsByTagName('HEAD')[0];
	for(const appleTouchIconPath of appleTouchIconPaths){
		let iconLink = document.createElement('link');
		iconLink.rel = "apple-touch-icon";
		iconLink.href = clientProductCDNBasePath+appleTouchIconPath.path

		head.appendChild(iconLink)
	}
}

export default branding;
