<template>

    <portal to="secondary-modal-content">

        <p>{{ message }}</p>

        <section class="button-container">
            <OaoButton :title="cancelText" type="plain" small fullwidth @click="$emit('close')" style="border-top: 2px solid var(--primary);"/>
            <OaoButton :title="acceptText" fullwidth @click="$emit('accept')" :small="isSmall" />
        </section>

    </portal>

</template>




<script>

    import { mapActions } from 'vuex';

    import { OaoButton } from '@/components';

    export default {

        name: 'OaoConfirmModal',
        components: { OaoButton },
        props: {

            title: {
                type: String,
                required: true
            },
            message: {
                type: String,
                required: true
            },
            acceptText: {
                type: String,
                required: false,
                default: 'Confirm'
            },
            cancelText: {
                type: String,
                required: false,
                default: 'Cancel'
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            preventClose: {
                type: Boolean,
                required: false,
                default: false
            }

        },
        created() {

            this.triggerModal({
                isOpen: true,
                title: this.title,
                smallTitle: true,
                preventClose: this.preventClose
            });

        },
        beforeDestroy() { return this.triggerModal({ isOpen: false }); },
        methods: {

            ...mapActions('modal', { triggerModal: 'triggerSecondaryModal' }),

        }

    }

</script>




<style lang="scss" scoped>

    .button-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: -1rem;
        margin-top: 1rem;
    }

</style>