import {
    resumeApplicationAPI
} from '@/api';

 const resumeApplicationConfiguration = {
    namespaced: true,
    state: {
        resumeApplicationEnabled: false
    },
    mutations: {
        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {
            state.resumeApplicationEnabled = false;
        }
    },
    actions: {
        async getResumeApplicationConfig({ commit, state }) {

            try {
                const { resumeApplicationEnabled = false } = await resumeApplicationAPI.getConfig();

                commit('setData',
                    {
                        objName: 'resumeApplicationEnabled',
                        data: resumeApplicationEnabled
                    });

                return { resumeApplicationEnabled };

            } catch (error) {

                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
        }
    },
     getters: {
         resumeApplicationEnabled: state => state.resumeApplicationEnabled
     }
};

export default resumeApplicationConfiguration;
