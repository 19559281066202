<template>

    <ValidationProvider
        :rules="validationRules"
        :name="name || label"
        v-slot="{ errors }"
        slim
    >

        <AutoInput
            v-if="showSearch"
            :value="innerValue"
            :id="id + 'Search'"
            :loading="isLoading"
            :label="label"
            :error="errors[0]"
            :searchKey="searchKey"
            :placeholder="placeholder"
            :options="multiSelectOptions"
            :internalSearch="internalSearch"
            :isSearchable="isSearchable"
            :isTaggable="isTaggable"
            :disabled="disabled"
            @input="$emit('input', $event)"
            @searchChange="$emit('change', $event)"
            @blur="toggleSearch(false)"
            @addTag="addTag"
        />

        <OaoInput
            v-else
            v-model="innerValue"
            :label="label"
            :placeholder="placeholder"
            :disabled="disabled"
            :error="errors[0]"
            @click="toggleSearch(true)"
        />

    </ValidationProvider>

</template>


<script>

    import {ValidationProvider} from 'vee-validate';

    import {OaoAutoInput, OaoInput} from '@/components/form';

    export default {

        name: 'FormSearchInput',
        components: {

            ValidationProvider,
            OaoAutoInput,
            OaoInput

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            },
            placeholderValue: {
                required: false,
                default: null
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [String, Object],
                required: false
            },
            internalSearch: {
                type: Boolean,
                required: false,
                default: true
            },
            isSearchable: {
                type: Boolean,
                required: false,
                default: true
            },
            isTaggable: {
                type: Boolean,
                required: false,
                default: true
            },
            insertTag: {
                type: Function,
                required: false
            }

        },
        data() {
            return {

                showSearch: false

            }
        },
        computed: {

            id() {

                return `${this.label.replace(/\s/g, '')}-${this._uid}`;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },
            multiSelectOptions() {

                return this.options ? this.options : [];

            }

        },
        methods: {

            toggleSearch(showSearch) {

                this.showSearch = showSearch;

                if (showSearch) {

                    this.$nextTick(() => {

                        document.getElementById(this.id + 'Search').focus();

                    });

                }

                this.$emit('change', '');

            },
            addTag(newTag) {

                this.multiSelectOptions.push(this.insertTag(newTag))

                this.$emit('input', tag);

            }

        }

    }

</script>