<template>

    <section ref="modal" class="modal">

        <div class="overlay" @click="closeModal"></div>

        <article :class="{'fit-content': fitContent}">

            <header>
                <h1 :class="{ 'small': smallTitle, 'title': !smallTitle }" v-html="title"></h1>
                <OaoButtonWrapper v-if="!preventClose"
                                  title="Close window"
                                  @click="closeModal"
                >
                    <BaseIcon
                        name="Close"
                        height="1.5rem"
                        width="1.5rem"
                    >
                        <CloseIcon />
                    </BaseIcon>
                </OaoButtonWrapper>
            </header>

            <main>
                <slot />
            </main>

            <footer v-if="!!$slots['footer']">
                <slot name="footer" />
            </footer>

        </article>

    </section>

</template>


<script>

    import { BaseIcon, CloseIcon } from "@/components/icons";
    import { OaoButtonWrapper } from "@/components";

    export default {

        name: "Modal",
        props: {

            title: {
                type: String,
                required: true
            },
            smallTitle: {
                type: Boolean,
                required: false
            },
            preventClose: {
                type: Boolean,
                required: false
            },
            fitContent: {
                type: Boolean,
                required: false
            }

        },
        components: {

            BaseIcon,
            CloseIcon,
            OaoButtonWrapper

        },
        mounted() {

            // Focus on modal component, when it opens (for accessibility)
            this.$refs.modal.focus();

        },
        methods: {

            closeModal() {

                if (!this.preventClose) {
                    this.$emit("close");
                }


            }

        }

    };

</script>


<style lang="scss" scoped>

  .modal {
    z-index: 10;
    position: fixed;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }

    article {
      margin: 0 auto;
      max-height: calc(90vh);
      width: 20.5rem;
      max-width: 90vw;
      overflow: auto;
      position: relative;
      background-color: var(--white);
      border-radius: 9px;
      padding: 1rem;

      header {
        position: relative;
        text-align: left;
        margin-bottom: 1rem;

        h1 {
          font-size: 1.25rem;
          font-weight: 700;
          color: var(--text-primary);

          &.small {
            font-size: 1rem;
          }

        }

        ::v-deep button {
          position: absolute;
          top: 0;
          right: 0.5rem;
          color: var(--lightGrey);
        }

      }

      footer {
        padding-top: 1rem;
      }

    }

  }

  article.fit-content {
    width: fit-content;
  }

</style>
