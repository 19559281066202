import meta from './defaultMeta';

export default [
    {
        path: 'auto/offer',
        name: 'AutoLoanOffer',
        component: () => import(/* webpackChunkName: "AutoLoanOffer" */ '@/views/Auto/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'autorefi/offer',
        name: 'AutoRefiLoanOffer',
        component: () => import(/* webpackChunkName: "AutoRefiLoanOffer" */ '@/views/AutoRefi/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'boat/offer',
        name: 'BoatLoanOffer',
        component: () => import(/* webpackChunkName: "BoatLoanOffer" */ '@/views/Boat/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'rv/offer',
        name: 'RVLoanOffer',
        component: () => import(/* webpackChunkName: "RVLoanOffer" */ '@/views/RV/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'motorcycle/offer',
        name: 'MotorcycleLoanOffer',
        component: () => import(/* webpackChunkName: "MotorcycleLoanOffer" */ '@/views/Motorcycle/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'heloc/offer',
        name: 'HELOCLoanOffer',
        component: () => import(/* webpackChunkName: "HELOCLoanOffer" */ '@/views/Heloc/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'ploc/offer',
        name: 'PLOCLoanOffer',
        component: () => import(/* webpackChunkName: "PLOCLoanOffer" */ '@/views/Personal/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    },
    {
        path: 'signature/offer',
        name: 'SignatureLoanOffer',
        component: () => import(/* webpackChunkName: "SignatureLoanOffer" */ '@/views/Signature/LoanOffer'),
        meta: {
            ...meta(),
            forceNavigation: false,
            bypassEnforcer: true
        }
    }
];