import * as Sentry from '@sentry/vue';

export default (error = null, requestId = null, applicantToken = null, applicationToken = null) => {
    
    Sentry.setContext('ERROR CONTEXT', {
        error,
		requestId,
        applicantToken,
        applicationToken,
    });    
    
    Sentry.captureException(error);
    
    return error;
};

