<template>
    <div class="input-container">
        <header :class="[
            'input-header',
            {
                'is-small': isSmall,
                'sr-only': hideLabel,
            },
        ]">
            <label :class="{ 'has-error': error }" :for="inputId" data-test-label>
                {{ label
                }}<span v-if="isOptional & !disabled" data-test-optional>
                    (OPTIONAL)</span>
            </label>

            <OaoTooltip v-if="tooltip" :description="filteredInputConfig.helpText"
                :title="filteredInputConfig.helpTextTitle" isSmall/>
        </header>

        <div class="input-control-container">
            <input v-if="type !== 'date' && !mask" :class="{
                'is-small': isSmall,
                'has-error': error,
                'force-capitals': forceCapitalization,
            }" :id="inputId" :name="label" :type="type" :placeholder="placeholder" v-model.trim="innerValue"
                :disabled="disabled" data-test-input :autocomplete="disableAutocomplete ? 'off' : 'on'"
                @change="$emit('change', $event)" @blur="focused = false" @focus="focused = true" />

            <TheMask v-if="type !== 'date' && mask" :class="{
                'is-small': isSmall,
                'has-error': error,
                'force-capitals': forceCapitalization,
            }" :id="inputId" :name="label" :type="type" :placeholder="placeholder" v-model.trim="innerValue"
                :mask="mask" :masked="maskOutput" :disabled="disabled" data-test-input
                :autocomplete="disableAutocomplete ? 'off' : 'on'" @change.native="$emit('change', $event)"
                @input="$emit('change', $event)" @blur.native="focused = false" @focus.native="focused = true" />

            <TheMask v-if="type === 'date'" :class="{
                'is-small': isSmall,
                'has-error': error,
                'force-capitals': forceCapitalization,
            }" :id="inputId" :name="label" type="tel" :placeholder="placeholder" v-model.trim="innerValue"
                mask="##/##/####" masked :disabled="disabled" data-test-input
                :autocomplete="disableAutocomplete ? 'off' : 'on'" @change.native="$emit('change', $event)"
                @input="$emit('change', $event)" @blur.native="focused = false" @focus.native="focused = true" />

            <slot name="input-control" />
        </div>

        <p class="input-sublabel" v-if="sublabel">
            <span class="sublabel-warning" v-if="sublabelWarning">⚠</span>
            {{ sublabel }}
        </p>

        <transition name="fade">
            <p class="has-error" v-if="error" data-test-error>
                {{ error }}
            </p>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Input",
    components: {
        TheMask: () =>
            import("vue-the-mask").then(({ TheMask }) => TheMask),
        OaoTooltip: () => import("@/components/OaoTooltip"),
    },
    data() {
        return {
            focused: false
        }
    },
    props: {
        value: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        placeholder: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        error: {
            type: String,
            required: false,
        },
        mask: {
            type: [String, Array],
            required: false,
        },
        maskOutput: {
            type: Boolean,
            required: false,
        },
        isOptional: {
            type: Boolean,
            required: false,
        },
        disableAutocomplete: {
            type: Boolean,
            required: false,
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: [String, Number],
            required: false,
        },
        tooltip: {
            type: Boolean,
            required: false,
            /*validator: ({ title, description }) => title && description,*/
            default: null,
        },
        hideLabel: {
            type: Boolean,
            required: false,
        },
        sublabel: {
            type: String,
            required: false,
        },
        sublabelWarning: {
            type: Boolean,
            required: false,
        },
        forceCapitalization: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        ...mapGetters("config", ["getInputConfig"]),

        inputId() {
            return (
                this.id || `${this.label.replace(/\s/g, "")}-${this._uid}`
            );
        },
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                if (this.forceCapitalization) {
                    val = val.toUpperCase();
                }

                //Keystone can't handle certain special whitespace characters so they need replacing for cases where user copy/pastes info
                const specialWhitespaceCharacters = new RegExp("[\t\r\n]", "g")
                val = val.replaceAll(specialWhitespaceCharacters, " ");
                const repeatingWhitespace = new RegExp("  *", "g");
                val = val.replaceAll(repeatingWhitespace, " ");

                this.$emit("input", val);
            },
        },
        filteredInputConfig() {
            return this.getInputConfig(this.label);
        },
    },
    watch: {
        "focused"(newVal, oldVal) {
            if (newVal) {
                this.$emit("focus", newVal);
            } else {
                this.$emit("blur", newVal);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.input-container {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    .has-error {
        color: var(--danger);
    }

    .input-header {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: baseline;

        label {
            display: block;
            color: var(--text-primary);
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    input {
        box-sizing: border-box;
        width: 100%;
        border: none;
        border-bottom: 1.5px solid rgb(215, 215, 215);
        background-color: transparent;
        font-size: 1.25rem;
        padding: 0.25rem;

        &::placeholder {
            color: var(--lightGrey);
            font-style: oblique;
        }

        &.has-error {
            border-bottom: 1.5px solid var(--danger);
        }

        &:disabled {
            color: var(--lightGrey);
            cursor: not-allowed;
        }
    }

    p {
        font-size: 0.85rem;
        margin-top: 0.25rem;
    }

    .is-small {
        width: auto;
        min-width: 12rem;
    }

    .force-capitals {
        text-transform: uppercase;
    }

    .input-sublabel {
        font-style: italic;
        font-size: 0.7rem;
        line-height: 1rem;

        .sublabel-warning {
            font-style: normal;
            color: orange;
        }
    }
}

.input-control-container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}
</style>
