<template functional>

    <g>
        <circle style="display:none;fill:none;stroke:#70B603;stroke-width:5;stroke-miterlimit:10;" cx="9" cy="9" r="43"/>
        <ellipse style="display:none;fill:#70B603;stroke:#FFFFFF;stroke-miterlimit:10;" cx="39.9" cy="-24" rx="9.8" ry="9.3"/>
        <path style="display:none;fill:#FFFFFF;" d="M45.8-27.1c0.4,0.5,1.2,1.1,0.4,1.9c-0.2,0.2-0.4,0.3-0.6,0.4c-2.2,1.8-4.3,3.8-6.5,5.6
            C39-19.1,38.9-19,38.7-19c-0.1,0-0.2-0.1-0.3-0.2c-1.2-1.3-2.3-2.6-3.5-3.9c-0.4-0.4-0.7-0.5-0.6-1c0,0,0.3-0.8,1-1.2
            c0,0,0.9-0.9,1.4-0.4c0.7,0.6,1.6,1.7,2.3,2.5c0.1,0.1,0.1,0.1,0.2,0.1s0.2-0.1,0.3-0.2c1-0.9,3-2.7,4.3-3.9
            C44.2-27.6,45.2-27.9,45.8-27.1z"/>
        <g>
            <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9c0,4.6,3.8,8.4,8.4,8.4
                c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
            <path class="primary-fill" d="M5.7,11.1c-0.4,0-0.7-0.1-0.9-0.3l0.3-0.3c0.5,0.5,1.8,0,3.1-1l0.3,0.3C7.5,10.7,6.4,11.1,5.7,11.1z"/>
            <path class="primary-fill" d="M9.5,8.8L9.2,8.6c1.3-1.4,1.9-3,1.4-3.5l0.3-0.3C11.6,5.5,11,7.2,9.5,8.8z"/>
            <path class="primary-fill" d="M7.2,13.1c-0.6,0-1.2-0.2-1.7-0.5l0.2-0.3c1.2,0.9,3.3,0.3,4.4-0.8l0.6-0.6l0.6,0.6L11.8,9L9.3,9.6l0.7,0.7
                l-0.4,0.4c-0.8,0.8-2,1.4-2.9,1.4c-0.4,0-0.7-0.1-0.9-0.3l-1-1C4,10,4.7,8.1,6.4,6.4c1.7-1.7,3.6-2.4,4.4-1.6l1,1
                c0.5,0.5,0.4,1.5-0.3,2.7l-0.4-0.2c0.6-1,0.7-1.8,0.4-2.2l-1-1c-0.5-0.5-2.2,0-3.9,1.6C5.1,8.3,4.6,10,5.1,10.5l1,1
                c0.1,0.1,0.4,0.2,0.6,0.2c0.7,0,1.7-0.4,2.6-1.3l0.1-0.1l-1-1l3.8-0.8l-0.8,3.8l-0.8-0.8l-0.3,0.3C9.6,12.6,8.3,13.1,7.2,13.1z"/>
            <path class="primary-fill" d="M7.1,8.6l-0.4,0c-0.2,0-0.3-0.1-0.3-0.2C6.3,8.2,6.4,8,6.5,7.9l0.2-0.2L7,8L6.8,8.2c0,0,0,0,0,0l0.3,0
                c0.2,0,0.4-0.1,0.6-0.3l0,0c0,0,0,0,0,0L7.3,7.7C7.2,7.6,7.2,7.5,7.1,7.4c0-0.1,0-0.2,0.2-0.4l0,0c0.2-0.2,0.6-0.4,0.9-0.4l0.4,0
                c0,0,0,0,0,0c0.2,0,0.3,0.1,0.3,0.2C9,7,9,7.2,8.8,7.4L8.6,7.5L8.4,7.2l0.2-0.2c0,0,0,0,0,0l-0.3,0C8.1,7,7.8,7.2,7.6,7.3l0,0
                c0,0,0,0,0,0L8,7.5c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.1,0.4L8,8.2C7.8,8.4,7.4,8.6,7.1,8.6z"/>
            <rect x="6.3" y="8.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.1296 7.2423)" class="primary-fill" width="0.7" height="0.4"/>
            <rect x="8.3" y="6.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1242 8.0608)" class="primary-fill" width="0.7" height="0.4"/>
        </g>
    </g>

</template>





<script>

    export default {

        name: 'New'

    }

</script>
