<template>

    <label class="input-container" data-test-label>

        <input
            :id="id"
            :name="name"
            type="radio"
            v-model.trim="innerValue"
            :value="optionValue"
            :disabled="disabled"
            data-test-input
        />

        <BaseIcon
            height="4rem"
            width="4rem"
            name="Check Icon"
            viewBox="0 0 18 18"
            class="selected"
            color="initial"
        >
            <component :is="optionValue === value ? selectedIcon : icon"/>
        </BaseIcon>

        <p>{{ label }}</p>

    </label>

</template>




<script>

    import { BaseIcon } from '@/components/icons';

    export default {

        name: 'SVGRadio',
        components: {

            BaseIcon

        },
        props: {

            value: {
                required: true
            },
            optionValue: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            icon: { required: true },
            selectedIcon: { required: true },
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            noPadding: {
                type: Boolean,
                required: false
            }

        },
        computed: {

            id() {

                return `${ this.label.replace(/\s/g, '') }-${ this._uid }`;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>




<style lang="scss" scoped>

    .input-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--bodyCopy);
        transition: opacity 0.5s;
        border: 1px solid var(--lightGrey);
        border-radius: 6px;
        padding: 0.5rem;
		
		&.no-padding { margin-bottom: 0; }

        &:hover { opacity: 0.7; }

        input {
            position: absolute;
            left: -9999px;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        p { margin-top: 0.5rem; }

	}

</style>