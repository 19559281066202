<template functional>

    <g>
        <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path class="primary-fill" d="M8.9,14H8.5v-0.8l0.1-0.1c0,0,0.5-0.2,0.5-0.6v-0.2h0.4v0.2c0,0.5-0.4,0.8-0.6,1V14z"/>
        <path class="primary-fill" d="M5.8,14H5.4v-3.3c0-0.5,0.2-0.7,0.5-1l1-0.8l0.3,0.3l-1,0.8c-0.3,0.2-0.3,0.3-0.3,0.7V14z"/>
        <path class="primary-fill" d="M7.3,13C7.3,13,7.3,13,7.3,13l0-0.4c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4l0-0.7
            l0.6-0.6c0.2-0.3,0.1-0.7-0.1-0.8c-0.1-0.1-0.1,0-0.1,0l-1.7,1.7l-0.3-0.3l1.7-1.7c0.2-0.2,0.5-0.2,0.7,0c0.3,0.3,0.5,1,0.1,1.4
            l0,0l-0.5,0.5V12c0,0.2-0.1,0.5-0.2,0.7C7.9,12.8,7.7,13,7.3,13C7.4,13,7.3,13,7.3,13z"/>
        <polygon class="primary-fill" points="11.4,11.7 8.1,11.7 8.1,11.3 11,11.3 11,4.5 7.3,4.5 7.3,10.7 6.8,10.7 6.8,4.1 11.4,4.1 	"/>
        <path class="primary-fill" d="M10,10.9H8.7v-0.4h0.9c0.1-0.3,0.3-0.5,0.6-0.6v-4C9.9,5.8,9.6,5.6,9.6,5.3H8.7C8.6,5.6,8.4,5.8,8.1,5.9v4H7.7
            V5.5h0.2c0.2,0,0.4-0.2,0.4-0.4V4.9H10v0.2c0,0.2,0.2,0.4,0.4,0.4h0.2v4.8h-0.2c-0.2,0-0.4,0.2-0.4,0.4V10.9z"/>
        <rect x="8.9" y="9" class="primary-fill" width="0.4" height="0.4"/>
        <rect x="8.9" y="6.1" class="primary-fill" width="0.4" height="0.4"/>
        <path class="primary-fill" d="M9.1,8.6c-0.5,0-0.8-0.4-0.8-0.8C8.3,7.3,8.7,7,9.1,7C9.6,7,10,7.3,10,7.8C10,8.3,9.6,8.6,9.1,8.6z M9.1,7.4
            c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C9.5,7.6,9.4,7.4,9.1,7.4z"/>
        <polygon class="primary-fill" points="12.2,12.6 8.1,12.6 8.1,12.2 11.8,12.2 11.8,5.3 11.2,5.3 11.2,4.9 12.2,4.9 	"/>
    </g>

</template>





<script>

    export default {

        name: 'Purchase'

    }

</script>