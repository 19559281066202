<template functional>

    <g>
        <path class="success-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,1C4.6,1,1,4.6,1,9c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8
            C17,4.6,13.4,1,9,1z"/>
        <g>
            <ellipse class="success-fill" cx="15.1" cy="2.5" rx="1.9" ry="1.8"/>
            <path style="fill: #FFFFFF;" d="M15.1,4.4c-1.1,0-2-0.9-2-1.9s0.9-1.9,2-1.9s2,0.9,2,1.9S16.2,4.4,15.1,4.4z M15.1,0.7c-1,0-1.8,0.8-1.8,1.7
                s0.8,1.7,1.8,1.7c1,0,1.8-0.8,1.8-1.7S16.1,0.7,15.1,0.7z"/>
        </g>
        <path style="fill: #FFFFFF;" d="M16.3,1.9c0.1,0.1,0.2,0.2,0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.9,0.8-1.3,1.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
            c-0.2-0.3-0.5-0.5-0.7-0.8C14,2.6,14,2.6,14,2.5c0-0.1,0.1-0.2,0.2-0.2c0,0,0.2-0.2,0.3-0.1c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0,0,0,0
            s0,0,0.1,0c0.2-0.2,0.6-0.5,0.9-0.8C16,1.7,16.2,1.7,16.3,1.9C16.3,1.8,16.3,1.9,16.3,1.9z"/>
        <path class="primary-fill" d="M8.9,13.7H8.5V13L8.6,13c0,0,0.5-0.2,0.5-0.6v-0.2h0.4v0.2c0,0.5-0.4,0.8-0.6,0.9V13.7z"/>
        <path class="primary-fill" d="M5.9,13.7H5.5v-3.2c0-0.5,0.1-0.6,0.5-0.9l1-0.8l0.2,0.3l-1,0.8c-0.2,0.2-0.3,0.3-0.3,0.6V13.7z"/>
        <path class="primary-fill" d="M7.4,12.8C7.4,12.8,7.3,12.8,7.4,12.8l0-0.4c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.4
            l0-0.7l0.5-0.5c0.2-0.3,0.1-0.6-0.1-0.8c-0.1-0.1-0.1,0-0.1,0l-1.6,1.6L6.4,11L8,9.5c0.2-0.2,0.5-0.2,0.7,0
            c0.3,0.3,0.5,0.9,0.1,1.3l0,0l-0.4,0.4v0.5c0,0.2-0.1,0.5-0.2,0.7C7.9,12.6,7.7,12.7,7.4,12.8C7.4,12.8,7.4,12.8,7.4,12.8z"/>
        <polygon class="primary-fill" points="11.3,11.6 8.1,11.6 8.1,11.2 10.9,11.2 10.9,4.6 7.3,4.6 7.3,10.6 6.9,10.6 6.9,4.3 11.3,4.3 	"/>
        <path class="primary-fill" d="M9.9,10.8H8.7v-0.4h0.8c0.1-0.3,0.3-0.5,0.6-0.6V6C9.8,5.9,9.6,5.7,9.5,5.4H8.7C8.6,5.7,8.4,5.9,8.1,6v3.8H7.7
            V5.6h0.2c0.2,0,0.4-0.2,0.4-0.4V5h1.6v0.2c0,0.2,0.2,0.4,0.4,0.4h0.2v4.5h-0.2c-0.2,0-0.4,0.2-0.4,0.4V10.8z"/>
        <rect x="8.9" y="9" class="primary-fill" width="0.4" height="0.4"/>
        <rect x="8.9" y="6.2" class="primary-fill" width="0.4" height="0.4"/>
        <path class="primary-fill" d="M9.1,8.6c-0.4,0-0.8-0.4-0.8-0.8S8.7,7,9.1,7c0.4,0,0.8,0.4,0.8,0.8S9.5,8.6,9.1,8.6z M9.1,7.4
            c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C9.5,7.6,9.3,7.4,9.1,7.4z"/>
        <polygon class="primary-fill" points="12.1,12.4 8.1,12.4 8.1,12 11.7,12 11.7,5.4 11.1,5.4 11.1,5 12.1,5 	"/>
    </g>

</template>





<script>

    export default {

        name: 'PurchaseSelected'

    }

</script>