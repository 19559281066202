import getTotalMonths from './getTotalMonths';

export default list => {

    let months = 0;

    for (const listItem of list) { months += getTotalMonths(listItem.years, listItem.months) };

    return months;

};