export default (loanAmountConfig) => {
    const hasMinAmount = loanAmountConfig.minLoanAmount > 0;
    const hasMaxAmount = !!loanAmountConfig.maxLoanAmount;

    if (hasMinAmount) {
        let placeholder = `$${loanAmountConfig.minLoanAmount} min`;

        if (hasMaxAmount) {
            placeholder = `${placeholder} - $${loanAmountConfig.maxLoanAmount} max`;
        }

        return placeholder;
    }

    if (hasMaxAmount) {
        return `$${loanAmountConfig.maxLoanAmount} max`;
    }

    return "";
};
