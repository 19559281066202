import axios from '@/utils/axios';

const getConfig = async () => {
	try {
		const {
			data: { counties, options, ineligiblePageText, eligibilityPageHeader, eligibilityPageSuboptionLabel, ineligiblePageCardText }
		} = await axios.get(`${process.env.BASE_URL}api/Eligibility/Configuration`);

		return {
			counties: counties || [],
			options: options || [],
			ineligiblePageText: ineligiblePageText || '',
			eligibilityPageHeader: eligibilityPageHeader || '',
			eligibilityPageSuboptionLabel: eligibilityPageSuboptionLabel || '',
			ineligiblePageCardText: ineligiblePageCardText || '',
		};
	} catch (error) {
		throw new Error(error);
	}
};

const recordEligibilityResponse = async ({ applicantId, eligibility }) => {
	try {
		return await axios.post(`${process.env.BASE_URL}api/Eligibility`, { applicantId, eligibility });
	} catch (error) {
		throw error;
	}
};

const getEligibility = async ({ applicantId }) => {
	try {
		return await axios.get(`${process.env.BASE_URL}api/Eligibility/${applicantId}`);
	} catch (error) {
		throw error;
	}
};

export default {
	getConfig,
	recordEligibilityResponse,
	getEligibility
};

export { getConfig, recordEligibilityResponse, getEligibility };
