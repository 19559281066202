import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../setData';

import { IDeviceIdConfiguration } from '@/api/configuration';
import { configurationAPI } from '@/api';

export interface IIdentityVerificationState {
	deviceIdEnabled: boolean;
	deviceIdSessionToken: string;
	deviceIdSDKKey: string;
}

const personVerification: Module<IIdentityVerificationState, IRootState> = {
	namespaced: true,
	state: {
		deviceIdEnabled: false,
		deviceIdSessionToken: '',
		deviceIdSDKKey: ''
	},
	mutations: {
		setData,
		setPersonVerificationConfiguration(state, data: IDeviceIdConfiguration) {
			state.deviceIdEnabled = data.enabled;
			state.deviceIdSDKKey = data.sdkKey;
		},
		resetState(state) {
			state.deviceIdEnabled = false,
			state.deviceIdSessionToken = '',
			state.deviceIdSDKKey = ''
		}
	},
	actions: {
		async initDeviceId({ commit }): Promise<void> {
			try {
				const setPersonVerificationConfiguration = await configurationAPI.getDeviceIdConfiguration();
				commit('setPersonVerificationConfiguration', setPersonVerificationConfiguration);
			} catch (error) {
				throw error
			}
		},
		attachDeviceIdSDK({ state, dispatch }): void {
			try {
				if (state.deviceIdEnabled && !hasSigmaDeviceManager()) {
					//place deviceId script on document header
					let deviceIdScript = document.createElement('script');
					deviceIdScript.src = 'https://cdn.dvnfo.com/device-risk-sdk.js';
					deviceIdScript.setAttribute('data-public-key', state.deviceIdSDKKey);

					//get session id when loaded
					deviceIdScript.addEventListener('load', () => {
						dispatch('refreshDeviceIdSessionToken');
					});

					document.head.appendChild(deviceIdScript);
				}
			} catch (error) {
				throw error
			}
		},
		async refreshDeviceIdSessionToken({ state, commit }): Promise<string> {
			let sessionToken = '';
			try {
				if (state.deviceIdEnabled && hasSigmaDeviceManager()) {
					// @ts-ignore because SigmaDeviceManager is an object from an external script
					sessionToken = await SigmaDeviceManager.getDeviceSessionId();
					commit('setData', {
						objName: 'deviceIdSessionToken',
						data: sessionToken
					});
				}
			} catch (error) {
				throw error
			}

			return sessionToken;
		}
	},
	getters: {
		deviceIdSessionToken: state => state.deviceIdSessionToken
	}
};

const hasSigmaDeviceManager = () => {
	// @ts-ignore because SigmaDeviceManager is an object from an external script
	return typeof SigmaDeviceManager !== 'undefined';
};

export default personVerification;
