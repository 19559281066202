import { Wormhole } from "portal-vue";

import { Module } from "vuex";
import { IRootState } from "..";

export interface IModalState {
    isOpen: boolean;
    isLeavingModal: boolean;
    title: string | null;
    centeredTitle: boolean;
    smallTitle: boolean;
    isWide: boolean;
    preventClose: boolean;
    fitContent: boolean;
    secondaryIsOpen: boolean;
    secondaryTitle: string | null;
    secondarySmallTitle: boolean;
    secondaryPreventClose: boolean;
    secondaryFitContent: boolean;
    leavingModalShown: boolean;
    appIdCopy: number | null;
    isProductRemovalModal: boolean;
}

const modal: Module<IModalState, IRootState> = {
    namespaced: true,
    state: {
        isOpen: false,
        isLeavingModal: false,
        title: null,
        centeredTitle: false,
        smallTitle: false,
        isWide: false,
        preventClose: false,
        fitContent: false,
        secondaryIsOpen: false,
        secondaryTitle: null,
        secondarySmallTitle: false,
        secondaryPreventClose: false,
        secondaryFitContent: false,
        leavingModalShown: false,
        appIdCopy: null,
        isProductRemovalModal: false,
    },
    mutations: {
        setData(
            state,
            {
                isOpen = false,
                isLeavingModal = false,
                title = null,
                centeredTitle = false,
                smallTitle = false,
                isWide = false,
                preventClose = false,
                isProductRemovalModal = false,
                fitContent = false,
            }
        ) {
            state.isOpen = isOpen;
            state.title = title;
            state.isLeavingModal = isLeavingModal;
            state.centeredTitle = centeredTitle;
            state.smallTitle = smallTitle;
            state.isWide = isWide;
            state.preventClose = preventClose;
            state.isProductRemovalModal = isProductRemovalModal;
            state.fitContent = fitContent;
        },
        setSecondaryData(
            state,
            { isOpen, title, smallTitle, preventClose, fitContent }
        ) {
            state.secondaryIsOpen = isOpen;
            state.secondaryTitle = title || null;
            state.secondarySmallTitle = smallTitle || false;
            state.secondaryPreventClose = preventClose || false;
            state.secondaryFitContent = fitContent || false;
        },
        setLeavingModalShown(state) {
            state.leavingModalShown = true;
        },
        setAppIdCopy(state, appIdCopy) {
            state.appIdCopy = appIdCopy;
        },
    },
    actions: {
        triggerModal({ commit }, { isOpen, fitContent, ...rest } = {}): void {
            // Destroy the Portal content for the modal before closing it,
            // so we don't have to manually do it somewhere else:
            if (!isOpen) {
                Wormhole.close({ to: "modal-content" }, true);
                Wormhole.close({ to: "modal-footer" }, true);
            }

            commit("setData", { ...rest, isOpen, fitContent });
        },
        triggerSecondaryModal({ commit }, { isOpen, fitContent, ...rest }) {
            if (!isOpen) {
                Wormhole.close({ to: "secondary-modal-content" }, true);
                Wormhole.close({ to: "secondary-modal-footer" }, true);
            }

            commit("setSecondaryData", { ...rest, isOpen, fitContent });
        },
        openLeavingModal(
            { commit, dispatch, state },
            { appIdCopy, ...rest } = {}
        ): void {
            commit("setAppIdCopy", appIdCopy);
            typeof rest.title === "undefined"
                ? (rest.title = "Sorry to see you leave!")
                : "";

            if (!state.leavingModalShown || rest.forceShow) {
                const openLeavingModalState = {
                    isOpen: true,
                    isLeavingModal: true,
                };

                dispatch("triggerModal", { ...openLeavingModalState, ...rest });
                commit("setLeavingModalShown");
            }
        },
        closeLeavingModal({ commit, dispatch }, { ...rest } = {}): void {
            const closeLeavingModalState = {
                isOpen: false,
                isLeavingModal: false,
            };

            dispatch("triggerModal", { ...closeLeavingModalState, ...rest });
        },
    },
    getters: {
        hasPortal() {
            return {
                content: Wormhole.hasContentFor("modal-content"),
                footer: Wormhole.hasContentFor("modal-footer"),
            };
        },
        hasSecondaryPortal() {
            return {
                content: Wormhole.hasContentFor("secondary-modal-content"),
                footer: Wormhole.hasContentFor("secondary-modal-footer"),
            };
        },
        leavingModalShown: (state) => state.leavingModalShown,
    },
};

export default modal;
