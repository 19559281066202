<template>

    <label :class="['radio-container', {
                    'is-selected': selected,
                }]">

        <input type="radio" v-model="innerValue" v-bind:value="option"/> 
    
        <BaseIcon v-if="selected" viewBox="0 0 22 22" :class="['radio-toggle', {'is-selected': selected}]">
            <RadioSelected></RadioSelected>
        </BaseIcon>

        <BaseIcon v-else viewBox="0 0 22 22">
            <RadioUnselected></RadioUnselected>
        </BaseIcon>

        <div class="radio-text">
            <p>{{ title }}</p>
            <p class="description">{{ description }}</p>
        </div>

    </label>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';

    import { BaseIcon, RadioSelected, RadioUnselected } from '@/components/icons';

    export default {

        name: 'Radio',
        components: {
    ValidationProvider,
    BaseIcon,
    RadioSelected,
    RadioUnselected,
    RadioSelected
},
        props: {

            value: {
                required: true
            },
            option: {
                required: true,
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: false
            },       
         

        },
        computed: {

            id() {

                return `${ this.title.replace(/\s/g, '') }-${ this._uid }`;

            },
            selected() {
                return this.value === this.option
            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },

        }

    }

</script>




<style lang="scss" scoped>

   .radio-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        border: 2px solid var(--bodyCopy-75);
        padding: .5rem;
        border-radius: 3px;

        .radio-text {
            margin-left: .5rem;
        }

        input[type="radio"] {
            appearance: none;
            margin: 0;
        }

        .radio-toggle {
            &.is-selected {
                color: var(--primary);
            }
        }

        &.is-selected {
            border: 2px solid var(--primary);
        }

        .description {
            font-size: .85rem;
        }

        p {
            margin-bottom: .25rem;
        }
   }




</style>