export const pageImagePaths = {
    cards: '/img/pages/cards.webp',
    contact: '/img/pages/contact.webp',
    disclosures: '/img/pages/disclosures.webp',
    eligibility: '/img/pages/eligibility.webp',
    employment: '/img/pages/employment.webp',
    funding: '/img/pages/funding.webp',
    fundingcard: '/img/pages/fundingcard.webp',
    fundingtransfer: '/img/pages/fundingtransfer.webp',
    home: '/img/pages/home.webp',
    identity: '/img/pages/identity.webp',
    joint: '/img/pages/joint.webp',
    print: '/img/pages/print.webp',
    select: '/img/pages/select.webp',
    sign: '/img/pages/sign.webp',
    verify: '/img/pages/verify.webp',
    reselect: '/img/pages/cards.webp',
    default: '/img/pages/default.webp',
    logo: '/img/logo.png',
};

export const appleTouchIconPaths = [
    { size:"120x120", path: '/img/apple-touch/apple-touch-icon-120x120.png' },
    { size: null, path: '/img/apple-touch/apple-touch-icon-precomposed.png'}
]
