const meta = () => ({
	hideNav: true,
	overflow: true,
	resetState: true,
	forceNavigation: true,
	bypassEnforcer: true,
	showFooter: true
});

export default [
	{
		path: 'error/:statusType',
		name: 'Error',
		component: () => import(/* webpackChunkName: "Error" */ '@/views/Error'),
		meta: {
			backButton: {
				route: 'ProductSelect',
				title: 'Return to the homepage'
			},
			overflow: true,
			isError: true,
			resetState: false,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'ineligible',
		name: 'Ineligible',
		component: () => import(/* webpackChunkName: "Ineligible" */ '@/views/Error/Ineligible'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'declined',
		name: 'Declined',
		component: () => import(/* webpackChunkName: "Declined" */ '@/views/Error/Declined'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'unverifiable',
		name: 'Unverifiable',
		component: () => import(/* webpackChunkName: "Unverifiable" */ '@/views/Error/Unverifiable'),
		meta: { ...meta() }
	},
	{
		path: 'duplicate/:type',
		name: 'Duplicate',
		component: () => import(/* webpackChunkName: "Duplicate" */ '@/views/Error/Duplicate'),
		meta: { ...meta() }
	},
	{
		path: 'review',
		name: 'Review',
		component: () => import(/* webpackChunkName: "InReview" */ '@/views/Error/Review'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: false,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'ineligible/member',
		name: 'MemberIneligible',
		component: () => import(/* webpackChunkName: "MemberIneligible" */ '@/views/Error/MemberIneligible'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'loan/type/unavailable',
		name: 'UnavailableLoanType',
		component: () => import(/* webpackChunkName: "UnavailableLoanType" */ '@/views/Error/UnavailableLoanType'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'ssoExpired',
		name: 'SSOExpired',
		component: () => import(/* webpackChunkName: "SSOExpired" */ '@/views/Error/SSOExpired'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'simplifiedLoanOffer',
		name: 'SimplifiedLoanOffer',
		component: () => import(/* webpackChunkName: "SimplifiedLoanOffer" */ '@/views/Error/SimplifiedLoanOffer'),
		meta: {
			hideNav: true,
			overflow: true,
			resetState: false,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	}
];
