import {collateralOptionsAPI} from '@/api';
import collateralValuationType from '@/constants';
import * as sentry from "@sentry/vue";

export default {
    namespaced: true,
    state: {

        yearOptions: [],
        makeOptions: [],
        modelOptions: [],
        trimOptions: []

    },
    mutations: {

        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {

            state.yearOptions = [];
            state.makeOptions = [];
            state.modelOptions = [];
            state.trimOptions = [];

        }

    },
    actions: {
        async getYearOptionsAsync({ commit, state }, request) {
            try {

                const yearOptions = await collateralOptionsAPI.getYearOptionsAsync(request.collateralValuationType, request.rvCategoryId);

                commit('setData', { objName: 'yearOptions', data: yearOptions });

                return yearOptions;

            } catch (error) {
                throw error;
            }
        },
        async getMakeOptionsAsync({ commit, state }, request) {
            try {

                const makeOptions = await collateralOptionsAPI.getMakeOptionsAsync(request.collateralValuationType, request.year, request.rvCategoryId);

                commit('setData', { objName: 'makeOptions', data: makeOptions });

                return makeOptions;

            } catch (error) {
                throw error;
            }
        },
        async getModelOptionsAsync({ commit, state }, request) {
            try {

                const modelOptions = await collateralOptionsAPI.getModelOptionsAsync(request.collateralValuationType, request.year, request.make, request.rvCategoryId);

                commit('setData', { objName: 'modelOptions', data: modelOptions });

                return modelOptions;

            } catch (error) {
                throw error;
            }
        },
        async getTrimOptionsAsync({ commit, state }, request) {
            try {

                const trimOptions = await collateralOptionsAPI.getTrimOptionsAsync(request.collateralValuationType, request.year, request.make, request.model, request.condition);

                commit('setData', { objName: 'trimOptions', data: trimOptions });

                return trimOptions;

            } catch (error) {
                throw error;
            }
        },
        async getVinDetailsAsync({commit, state}, request) {
            try {

                return await collateralOptionsAPI.getVinDetailsAsync(request.collateralValuationType, request.vin);

            } catch (error) {
                throw error;
            }
        }
    }
}
