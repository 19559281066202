<template>

    <section>
        <header>
            <button
                type="button"
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ 'is-active': tab.isActive }"
                @click="selectTab(tab, tab.id)"
            >
                {{ tab.name }}
            </button>
        </header>
        <main
            :id="containerId"
            :style="{ 'min-height': height }"
            class="box-shadow"
        >
            <slot/>
        </main>
    </section>
    
</template>





<script>

    export default {

        name: 'Tabs',
        data() {
            return {
                
                tabs: [],
                height: 'auto'
                
            }
        },
        computed: {

            containerId() {

                return `tabs-container-${ this._uid }`

            }

        },
        async created() {
            
            this.tabs = await this.$children;

            this.emitChange(this.setInitialData(this.tabs));

            //this.matchHeight(this.tabs);

        },
        methods: {

            setInitialData(tabs) {

                for (const tab of tabs) {

                    if (tab.isActive) {

                        return tab.id;

                    };

                };

            },
            selectTab(selectedTab, id) {

                this.tabs.forEach(tab => {

                    tab.isActive = (tab.name == selectedTab.name);

                });

                this.emitChange(id);

            },
            emitChange(id) {

                this.$emit('select', id);

            },
            matchHeight(children) {

                let heights = [];

                children.forEach(async child => {

                    if (child.$el.offsetHeight) {

                        heights.push(child.$el.offsetHeight);

                    } else {

                        child.isActive = true;
                        
                        this.$nextTick(() => {

                            heights.push(child.$el.offsetHeight);
                            
                            child.isActive = false;

                        });

                    };

                });

                this.height = `${ Math.max.apply(null, heights) }px`;

            }

        }

    };

</script>





<style lang="scss" scoped>

    section {

		header {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 0.25rem;

			button {
                text-align: center;
                border: 1px solid var(--white);
                border-bottom: 0;
				padding: 0.5rem;
				color: var(--text-primary-75);
				background-color: var(--white-75);
				cursor: pointer;
                font-weight: 600;
                font-size: 1rem;
                transition: color 0.5s, background-color 0.5s, border-bottom-color 0.5s;
                border-bottom: 2px solid var(--primary-25);

				&.is-active {
					background-color: var(--white);
                    color: var(--text-primary);
                    font-weight: 800;
                    text-shadow: none;
                    border-bottom-color: var(--white);

                    &:hover { border-bottom-color: var(--white) }
                }
                
                &:hover {
                    color: var(--text-primary);
                    text-shadow: none;
                    background-color: var(--white);
                    border-bottom-color: var(--primary);
                }

			}

        }

        main {
			background: var(--white);
			padding: 1.75rem;
        }
        
    }

</style>