import { applicationAPI, errorAPI, verificationAPI } from '@/api';
import { ApplicationType, DuplicateApplicationActionType } from '@/constants';
import * as Sentry from '@sentry/vue';

export default {
	namespaced: true,
	state: {
		applicationId: 0,
		applicationToken: '',
		productTypeId: 0,
		applicationType: ApplicationType.NONE,
		loanApplicationId: 0,
		loanDecision: 0,
		duplicateApplicationActionType: null,
		isDeclined: false,
		personVerificationId: 0,
		verificationQuestions: [],
		verificationName: '',
		loanOffer: null,
		loanApplication: null,
		missingApplicationToken: false
	},
	mutations: {
		setData(state, { objName, data }) {
			state[objName] = data;
		},
		resetState(state) {
			state.applicationId = 0;
			state.applicationToken = '';
			state.productTypeId = 0;
			state.applicationType = ApplicationType.NONE;
			state.loanApplicationId = 0;
			state.loanDecision = 0;
			state.duplicateApplicationActionType = DuplicateApplicationActionType.NOT_RUN;
			state.isDeclined = false;
			state.personVerificationId = 0;
			state.verificationQuestions = [];
			state.verificationName = '';
			state.loanOffer = null;
			state.loanApplication = null;
			state.missingApplicationToken = false;
		}
	},
	actions: {
		async getLoanOffer({ commit, state }) {
			if (
				state.loanOffer &&
				state.loanApplication &&
				state.primaryDocumentUploadEnabled &&
				state.coborrowerDocumentUploadEnabled,
				state.missingApplicationToken
			) {
				return {
					loanOffer: state.loanOffer,
					loanApplication: state.loanApplication,
					primaryDocumentUploadEnabled: state.primaryDocumentUploadEnabled,
					coborrowerDocumentUploadEnabled: state.coborrowerDocumentUploadEnabled,
					missingApplicationToken: state.missingApplicationToken
				};
			}

			try {
				const loanDetail = await applicationAPI.getLoanOffer(state.applicationToken);

				commit('setData', { objName: 'loanOffer', data: loanDetail.loanOffer });
				commit('setData', { objName: 'loanApplication', data: loanDetail.loanApplication });
				commit('setData', {
					objName: 'primaryDocumentUploadEnabled',
					data: loanDetail.primaryDocumentUploadEnabled
				});
				commit('setData', {
					objName: 'coborrowerDocumentUploadEnabled',
					data: loanDetail.coborrowerDocumentUploadEnabled
				});
				commit('setData', {
					objName: 'missingApplicationToken',
					data: loanDetail.missingApplicationToken
				});

				return loanDetail;
			} catch (error) {
				throw error;
			}
		},
		async getApplicationStatus({ commit, state }) {
			if (state.loanDecision) {
				return state.loanDecision;
			}

			try {
				const loanDecision = await applicationAPI.getApplicationStatus(state.applicationId);

				commit('setData', { objName: 'loanDecision', data: loanDecision });

				return loanDecision;
			} catch (error) {
				throw error;
			}
		},
		async submitApplication({ commit, state, rootState }) {
			if (state.loanDecision && state.submitCompleted) {
				return {
					loanDecision: state.loanDecision, submitCompleted: state.submitCompleted
				};
			}
			try {
				const { loanDecision, submitCompleted } = await applicationAPI.submitApplication(
					state.applicationId,
					rootState.personVerification.deviceIdSessionToken
				);

				commit('setData', { objName: 'loanDecision', data: loanDecision });
				commit('setData', { objName: 'submitCompleted', data: submitCompleted });

				return {
					loanDecision, submitCompleted
				}
;
			} catch (error) {
				throw error;
			}
		},
		async getVerificationQuestions({ commit, state, rootState, rootGetters }) {
			const name = rootGetters['applicant/fullName'];

			if (state.verificationQuestions.length && state.verificationName === name) {
				return { questions: state.verificationQuestions, isDeclined: rootState.application.isDeclined };
			}

			try {
				commit('setData', { objName: 'verificationName', data: name });

				const { questions, isDeclined, personVerificationId } = await verificationAPI.getVerificationConfig(
					rootState.applicant.applicantId
				);

				commit('setData', { objName: 'verificationQuestions', data: questions || [] });
				commit('setData', { objName: 'isDeclined', data: isDeclined });
				commit('setData', { objName: 'personVerificationId', data: personVerificationId });

				return { questions, isDeclined };
			} catch (error) {
				throw new Error(error);
			}
		},
		async createVerificationResponse({ commit, state, rootState }, challengeAnswers) {
			try {
				const isDeclined = await verificationAPI.createVerificationResponse({
					applicantId: rootState.applicant.applicantId,
					personVerificationId: state.personVerificationId,
					challengeAnswers
				});

				commit('setData', { objName: 'isDeclined', data: isDeclined });

				return isDeclined;
			} catch (error) {
				throw error;
			}
		},
		async getReviewError({ commit, state }) {
			try {
				const content = await errorAPI.getReviewError(state.applicationId);

				return content;
			} catch (error) {
				throw error;
			}
		},
		async getDeclinedError({ commit, state }) {
			try {
				const content = await errorAPI.getDeclinedError(state.applicationId);

				return content;
			} catch (error) {
				throw error;
			}
		},
		async getIneligibleErrorConfig({ commit, state }) {
			try {
				const content = await errorAPI.getEligibilityError(state.applicationId);

				return content;
			} catch (error) {
				throw error;
			}
		},
		async getMembershipIneligibleErrorConfig({ commit, state }) {
			try {
				const content = await errorAPI.getMembershipEligibilityError(state.applicationId);

				return content;
			} catch (error) {
				throw error;
			}
		},
		async getUnsupportedPropertyErrorConfig({ commit, state }) {
			try {
				const content = await errorAPI.getUnsupportedPropertyError(state.applicationId);

				return content;
			} catch (error) {
				throw error;
			}
		},
		async resumeApplication({ commit }, application) {
			if (application) {
				if (application.applicationId) {
					commit('setData', { objName: 'applicationId', data: application.applicationId });
				}

				if (application.loanApplicationId) {
					commit('setData', { objName: 'loanApplicationId', data: application.loanApplicationId });
				}

				if (application.applicationToken) {
					commit('setData', { objName: 'applicationToken', data: application.applicationToken });
				}

				if (application.applicationType) {
					commit('setData', { objName: 'applicationType', data: application.applicationType });
				}
			}
		},
		async setProductSelection({ commit }, productTypeId) {
			if (productTypeId) {
				commit('setData', { objName: 'productTypeId', data: productTypeId });
			}
		}
	},
	getters: {
		duplicateApplicationActionType: rootState => rootState.duplicateApplicationActionType,
		isJoint: state => state.applicationType === ApplicationType.JOINT,
		productType: (state, getters, rootState) => rootState.config.productTypeEnum[state.productTypeId],
		isHeloc: (state, getters, rootState) => state.productTypeId === rootState.config.productTypeEnum.HELOC,
		submitCompleted: state => state.submitCompleted
	}
};
