<template functional>

    <h1 v-bind="data.attrs" :class="[data.class, data.staticClass, { 'small': props.isSmall }]"
        :style="[data.style, data.staticStyle]">
        <slot />
    </h1>

</template>





<script>

export default {
    name: 'Title'
}

</script>





<style scoped>
h1 {
    color: var(--text-primary);
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: center;
    margin: .25rem 0 0 0;
}

.small {
    color: var(--text-primary);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    margin: 0 0 1rem 0;
}


@media only screen and (min-width: 850px) {
    h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: 700;
        color: var(--headerCopy);
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
    }

    .small {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 600;
        color: var(--headerCopy);
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
    }

}
</style>