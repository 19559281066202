export default {
    NONE: 0,
    INCOMPLETE: 1,
    PROCESSING: 2,
    COMPLETED: 3,
    0: 'None',
    1: 'Incomplete',
    2: 'Processing',
    3: 'Completed'
};
