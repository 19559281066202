import { mapMutations } from 'vuex';

export default {

    data() {
        return {

            loading: false,
            submitting: false,
            loaderOption: 1

        }
    },
    methods: {

        ...mapMutations([ 'setLoading' ]),
        showSpinner({ loading = false, submitting = false, loaderOption = 1 }) {

            this.loading = loading;
            this.submitting = submitting;
            this.loaderOption = loaderOption;

            this.setLoading({ isLoading: loading || submitting, loaderOption: loaderOption });

        }

    }

};