<template functional>

    <section v-bind="data.attrs"
        :class="[data.class, data.staticClass, 'loading-overlay', { 'is-submit': props.isSubmit }]"
        :style="[data.style, data.staticStyle]">
        <slot />
    </section>

</template>





<script>

export default {

    name: 'Overlay',
    props: {

        isSubmit: {
            type: Boolean,
            required: false
        }

    }

};

</script>





<style lang="scss" scoped>
.loading-overlay {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 20;
    background-color: var(--default-overlay-background);

    &.is-submit {
        background-color: var(--submit-overlay-background);
    }

}
</style>