import axios from '@/utils/axios';

const getConfig = async ({ applicationId }) => {

    try {

        const {
            data: {
                productConfigurations,
                paymentProtectionSectionTitle,
                paymentProtectionSectionDescription
            }
        } = await axios.get(`${process.env.BASE_URL}api/PaymentProtection/Configuration/Application/${applicationId}`);

        return {
            productConfigurations, paymentProtectionSectionTitle, paymentProtectionSectionDescription };

    } catch (error) {

        throw error;

    };

};


const updatePaymentProtectionSelections = async (applicationId, paymentProtectionSelections) => {

    try {

        const {
            data: {
                success
            }
        } = await axios.post(`${process.env.BASE_URL}api/PaymentProtection`, {
            applicationId,
            paymentProtectionSelections
        });

        return paymentProtectionSelections;

    } catch (error) {

        throw error;

    };

};


const getPaymentProtectionSelections = async (applicationId) => {

    try {

        const {
            data: {
                paymentProtectionSelections
            }
        } = await axios.get(`${process.env.BASE_URL}api/PaymentProtection/Application/${applicationId}`);


        return paymentProtectionSelections;

    } catch (error) {

        throw error;

    };

};

export default {

    getConfig,
    updatePaymentProtectionSelections,
    getPaymentProtectionSelections

};

export {

    getConfig,
    updatePaymentProtectionSelections

};