<template>
	<portal to="modal-content">
		<section class="session-expired">
			<OaoButton v-if="resumeApplicationEnabled" title="Resume Application" lowercase fullwidth small
				@click="launchResume()" style="margin-bottom: 1rem" />

			<OaoButton v-if="!resumeApplicationEnabled" title="Restart Application" lowercase fullwidth small
				@click="restart()" style="margin-bottom: 1rem" />

			<a :href="clientWebsiteLink">
				<OaoButton :title="`Return to ${clientName} homepage`" type="hollow" lowercase fullwidth small />
			</a>
		</section>
	</portal>
</template>





<script>
import { mapActions, mapGetters } from 'vuex';
import { OaoButton } from '@/components';


export default {
	name: 'SessionExpired',
	components: {
		OaoButton
	},
	computed: {
		applicationIdentifier: {
			get() {
				return this.$route.query.appId;
			}
		},
		...mapGetters('resumeApplicationConfiguration', ['resumeApplicationEnabled']),
		...mapGetters(['clientWebsiteLink']),
		...mapGetters('branding', ['clientName'])
	},
	created() {
		this.triggerModal({
			isOpen: true,
			title: 'Your session has expired.',
			smallTitle: true,
			preventClose: true
		});
	},
	destroyed() {
		this.triggerModal({ isOpen: false });
	},
	methods: {
		...mapActions('modal', ['triggerModal']),
		async launchResume() {
			return this.$router.push(this.$constructUrl('Resume'));
		},
		async restart() {
			return this.$router.push(this.$constructUrl('ProductSelect'));
		}
	}
};
</script>

<style lang="scss" scoped>
.session-expired {
	display: flex;
	flex-direction: column;

	p {
		color: var(--primary);
		font-weight: 300;
		transition: color 0.5s;
	}
}
</style>