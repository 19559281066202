<template>
	<div class="input-container" data-test-label>
		<header class="input-header">
			<label :class="{ 'has-error': error }" data-test-label>
				{{ label }}<span v-if="isOptional & !disabled" data-test-optional> (OPTIONAL)</span>
			</label>
		</header>

		<div>
			<input
				autocomplete="select-search"
				@click.prevent
				@keydown.up="moveSelectorUp"
				@keydown.down="moveSelectorDown"
				@keydown.enter.prevent="selectAtSelectorPosition"
				@focus="isInputFocused = true"
				@blur="isInputFocused = false; $emit('blur')"
				v-model="query"
				:placeholder="placeholder"
				:disabled="disabled"
				:class="{ 'has-error': error, 'dropdown-arrow': !allowFreeForm }"
				:id="inputId"
			/>

			<div class="dropdown" v-if="dropdownOpen" @mouseover="resetOptionSelector">
				<div
					@click="selectValue(option)"
					:id="getOptionElementId(option)"
					:class="['dropdown-option', { 'is-selected': option[searchKey] === optionOnSelectorPosition }]"
					v-for="option in filterOptions"
				>
					{{ option[searchKey] }}
				</div>
			</div>
		</div>

		<transition name="fade">
			<p class="has-error" v-if="error" data-test-error>
				{{ error }}
			</p>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'SearchSelect',
		data() {
			return {
				query: '',
				initialValue: '',
				filteredOptionsSelectorPosition: null,
				isInputFocused: false,
				fieldBeingUsed: false
			};
		},
		props: {
			value: {
				required: true
			},
			searchKey: {
				type: String,
				required: true
			},
			populatesWith: {
				type: String,
				required: true
			},
			label: {
				type: String,
				required: true
			},
			placeholder: {
				type: String,
				required: false,
				default: 'Please Select an Option'
			},
			options: {
				type: Array,
				required: true
			},
			disabled: {
				type: Boolean,
				required: false
			},
			error: {
				type: String,
				required: false
			},
			isOptional: {
				type: Boolean,
				required: false,
				default: false
			},
			dropdownOpenByDefault: {
				type: Boolean,
				required: false
			},
			useInternalFiltering: {
				type: Boolean,
				required: false,
				default: true
			},
			allowFreeForm: {
				type: Boolean,
				required: false,
				default: false
			},
			inputId: {
				type: String,
				required: false
			}
		},
		created() {
			this.initialValue = this.value;
			//For the value property a object or literal can be given
			//Handle both cases to allow the inital value to be displayed in the search
			if (typeof this.value === 'object') {
				this.query = this.value ? this.value[this.searchKey] : '';
			} else {
				this.query = this.value;
			}
		},
		computed: {
			dropdownOpen() {
				const validOptionsThatArentExactMatches = this.filterOptions.filter(
					x => x[this.searchKey] !== this.innerValue
				);
				const newOptionNotSelected = this.innerValue === this.initialValue || !this.initialValue;
				const queryDifferentThanSelected = this.innerValue
					? this.query !== this.innerValue[this.searchKey]
					: true;
				const hasFilteredItems = validOptionsThatArentExactMatches.length !== 0;
				return this.dropdownOpenByDefault
					? //only show if the user is interacting with the field and if they have not yet selected something or are changing their selection
					  queryDifferentThanSelected && this.fieldBeingUsed && hasFilteredItems
					: //the dropdown is set to not be open by default, so only show if the user is searching and have not be selected yet
					  queryDifferentThanSelected && this.isInputFocused && hasFilteredItems;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			},
			filterOptions() {
				if (this.query && this.useInternalFiltering) {
					const query = this.query.toLowerCase();
					let filtered = [];

					for (let obj of this.options) {
						if (obj[this.searchKey].toLowerCase().startsWith(query)) {
							filtered.push(obj);
							continue;
						}

						const splicedItem = obj[this.searchKey].toLowerCase().split(' ');

						for (var word of splicedItem) {
							if (word.startsWith(query.toLowerCase())) {
								filtered.push(obj);
								break;
							}
						}
					}

					return filtered;
				}

				return this.options;
			},
			optionOnSelectorPosition() {
				// note: specifically check filteredOptionsSelectorPosition !== null because
				// the first position is equal to zero which evaluates as falsey in javascript
				if (this.filterOptions.length && this.filteredOptionsSelectorPosition !== null) {
					const objectAtPosition = this.filterOptions[this.filteredOptionsSelectorPosition];
					return objectAtPosition[this.searchKey];
				}
				return null;
			},
			id() {
				return `${this.label.replace(/\s/g, '')}-${this._uid}`;
			}
		},
		watch: {
			query(newValue, oldValue) {
				this.$emit('query', newValue);
			},
			value(newValue) {
				if (typeof this.value === 'object') {
					this.query = this.value[this.populatesWith];
                } else {
					this.query = this.value;
				}
			},
			isInputFocused(newValue, oldValue) {
				if (!newValue && oldValue) {
					setTimeout(() => {
						this.fieldBeingUsed = false;
					}, 200);
				} else {
					this.fieldBeingUsed = true;
				}
			}
		},
        methods: {
			selectValue(option) {
				this.query = option[this.populatesWith];
                this.innerValue = option;
			},

			moveSelectorDown() {
				if (this.filteredOptionsSelectorPosition === null) {
					this.filteredOptionsSelectorPosition = 0;
				} else if (this.filteredOptionsSelectorPosition < this.filterOptions.length - 1) {
					this.filteredOptionsSelectorPosition++;

					//allow the dropdown to scroll if the selector is at the end of the dropdown block
					this.allowDropdownScrollBack();
				}
			},

			moveSelectorUp() {
				if (this.filteredOptionsSelectorPosition) {
					this.filteredOptionsSelectorPosition--;

					//allow the dropdown to scroll up if the selector is at the end of the dropdown block
					this.allowDropdownScrollBack();
				}
			},

			allowDropdownScrollBack() {
				const elementId = this.getOptionElementId(this.filterOptions[this.filteredOptionsSelectorPosition]);
				let newlySelectedElement = document.getElementById(elementId);
				newlySelectedElement.scrollIntoView();
			},

			selectAtSelectorPosition() {
				const option = this.filterOptions[this.filteredOptionsSelectorPosition];
                this.query = option[this.populatesWith];
				this.innerValue = option;
				this.resetOptionSelector();
			},

			resetOptionSelector() {
				this.filteredOptionsSelectorPosition = null;
			},

			getOptionElementId(option) {
				return 'option-' + option[this.searchKey].replace(' ', '-');
			},

			checkIfQueryAndSelectedDifferent() {
				return !(this.innerValue && this.query === this.innerValue[this.searchKey]);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		position: relative;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		.has-error {
			color: var(--danger);
		}

		.input-header {
			align-items: baseline;

			label {
				display: block;
				color: var(--text-primary);
				font-size: 0.8rem;
				margin-bottom: 0.5rem;
				font-weight: 400;
				text-transform: uppercase;
			}
		}

		.dropdown {
			z-index: 99;
			position: relative;
			display: inline-block;
		}

		input {
			width: 100%;
			border: none;
			border-bottom: 1.5px solid rgb(215, 215, 215);
			background-color: transparent;
			font-size: 1.25rem;
			padding: 0.25rem 0.25rem 0.25rem 0.5rem;
			box-sizing: border-box;

			div {
				display: flex;
				justify-content: space-between;
			}

			option {
				color: black;
				font-style: normal;
			}

			option:disabled,
			&.is-placeholder {
				color: var(--lightGrey);
				font-style: oblique;
			}

			&.has-error {
				border-bottom: 1.5px solid var(--danger);
			}

			&.dropdown-arrow {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 50 50' color='current' aria-labelledby='dropdownIcon'%3E%3Cpath d='M12.937 14.582 25 2.526 22.886.418l-9.95 9.949L2.989.41.88 2.526l12.057 12.063z'/%3E%3C/svg%3E");
				background-repeat: no-repeat, repeat;
				background-position: right .15em top 60%, 0 0;
			}
		}

		p {
			font-size: 0.85rem;
			margin-top: 0.25rem;
		}

		.dropdown {
			position: absolute;
			display: flex;
			flex-direction: column;
			background-color: white;
			width: 100%;

			max-height: 350px;
			min-height: fit-content;
			overflow-y: auto;

			border-style: solid;
			border-width: 1px;

			cursor: pointer;

			.is-selected {
				background-color: #1e90ff;
				color: white;
			}

			.dropdown-option {
				font-style: normal;
				font-size: 1.25rem;
				padding: 0 0 0 0.5rem;
			}

			.dropdown-option:hover {
				background-color: #1e90ff;
				color: white;
			}
		}
	}
</style>
