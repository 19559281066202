<template>

    <ValidationProvider
        :rules="validationRules"
        :name="name || label"
        v-slot="{ errors }"
        slim
    >

        <OaoAutoInput
            v-model="innerValue"
            :label="label"
            :searchKey="searchKey"
            :options="options"
            :placeholder="placeholder"
            :disabled="disabled"
            :loading="loading"
            :error="errors[0]"
            @blur="$emit('blur')"
        />

    </ValidationProvider>

</template>


<script>

    import {ValidationProvider} from 'vee-validate';

    import {OaoAutoInput} from '@/components/form';

    export default {

        name: 'FormAutoInput',
        components: {

            ValidationProvider,
            OaoAutoInput

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            loading: {
                type: Boolean,
                required: false
            },
            searchKey: {
                type: String,
                required: false
            },
            isOptional: {
                type: Boolean,
                requied: false,
                default: false
            },
            validationRules: {
                type: [String, Object],
                required: false
            }

        },
        computed: {

            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>