import meta from './defaultMeta';
import store from '@/store';

export default [
	{
		path: ':productType/address/previous',
		name: 'PreviousAddress',
		component: () => import(/* webpackChunkName: "PreviousAddress" */ '@/views/PreviousAddress'),
		meta: { ...meta() }
	},
	{
		path: ':productType/employment/previous',
		name: 'PreviousEmployment',
		component: () => import(/* webpackChunkName: "PreviousEmployment" */ '@/views/PreviousEmployment'),
		meta: { ...meta() }
	},
	{
		path: ':productType/eligibility',
		name: 'Eligibility',
		component: () => import(/* webpackChunkName: "Eligibility" */ '@/views/Eligibility'),
		meta: { ...meta() }
	},
	{
		path: 'auto/product/type',
		name: 'AutoProductType',
		component: () => import(/* webpackChunkName: "AutoProductType" */ '@/views/Auto/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'auto/product/details',
		name: 'AutoProductDetails',
		component: () => import(/* webpackChunkName: "AutoProductDetails" */ '@/views/Auto/ProductDetails'),
		meta: { ...meta() }
	},
	{
		path: 'auto/review',
		name: 'AutoReview',
		component: () => import(/* webpackChunkName: "AutoReview" */ '@/views/Auto/Review'),
		meta: { ...meta() }
	},
	{
		path: ':productType/co-applicant',
		name: 'CoApplicant',
		component: () => import(/* webpackChunkName: "CoApplicant" */ '@/views/CoApplicant'),
		meta: { ...meta() }
	},
	{
		path: ':productType/co-applicant/address/current',
		name: 'CoApplicantCurrentAddress',
		component: () => import(/* webpackChunkName: "CoApplicantCurrentAddress" */ '@/views/CurrentAddress'),
		meta: { ...meta() }
	},
	{
		path: ':productType/co-applicant/address/previous',
		name: 'CoApplicantPreviousAddress',
		component: () => import(/* webpackChunkName: "CoApplicantPreviousAddress" */ '@/views/PreviousAddress'),
		meta: { ...meta() }
	},
	{
		path: ':productType/co-applicant/employment/current',
		name: 'CoApplicantCurrentEmployment',
		component: () => import(/* webpackChunkName: "CoApplicantCurrentEmployment" */ '@/views/CurrentEmployment'),
		meta: { ...meta() }
	},
	{
		path: ':productType/co-applicant/employment/previous',
		name: 'CoApplicantPreviousEmployment',
		component: () => import(/* webpackChunkName: "CoApplicantPreviousEmployment" */ '@/views/PreviousEmployment'),
		meta: { ...meta() }
	},
	{
		path: 'heloc/property/type',
		name: 'HelocPropertyType',
		component: () => import(/* webpackChunkName: "HelocPropertyType" */ '@/views/Heloc/PropertyType'),
		meta: { ...meta() }
	},
	{
		path: 'heloc/loan/details',
		name: 'HelocLoanDetails',
		component: () => import(/* webpackChunkName: "HelocLoanDetails" */ '@/views/Heloc/LoanDetails'),
		meta: { ...meta(), leavingModal: true }
	},
	{
		path: 'heloc/property/details',
		name: 'HelocPropertyDetails',
		component: () => import(/* webpackChunkName: "HelocPropertyDetails" */ '@/views/Heloc/PropertyDetails'),
		meta: { ...meta() }
	},
	{
		path: 'heloc/review',
		name: 'HelocReview',
		component: () => import(/* webpackChunkName: "HelocReview" */ '@/views/Heloc/Review'),
		meta: { ...meta() }
	},
	{
		path: 'heloc/demographics',
		name: 'HelocApplicantDemographics',
		component: () =>
			import(/* webpackChunkName: "HelocApplicantDemographics" */ '@/views/Heloc/Demographics/Applicant'),
		meta: { ...meta() }
	},
	{
		path: 'heloc/co-applicant/demographics',
		name: 'HelocCoApplicantDemographics',
		component: () =>
			import(/* webpackChunkName: "HelocCoApplicantDemographics" */ '@/views/Heloc/Demographics/CoApplicant'),
		meta: { ...meta() }
	},
	{
		path: 'autorefi/product/details',
		name: 'AutoRefiProductDetails',
		component: () => import(/* webpackChunkName: "AutoRefiProductDetails" */ '@/views/AutoRefi/ProductDetails'),
		meta: { ...meta() }
	},
	{
		path: 'autorefi/product/type',
		name: 'AutoRefiProductType',
		redirect: to => ({ ...to, name: 'AutoRefiProductDetails' }) // Skip product type and go to the auto details
	},
	{
		path: 'autorefi/review',
		name: 'AutoRefiReview',
		component: () => import(/* webpackChunkName: "AutoRefiReview" */ '@/views/AutoRefi/Review'),
		meta: { ...meta() }
	},
	{
		path: 'signature/product/type',
		name: 'SignatureProductType',
		component: () => import(/* webpackChunkName: "SignatureProductType" */ '@/views/Signature/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'signature/review',
		name: 'SignatureLoanReview',
		component: () => import(/* webpackChunkName: "SignatureLoanReview" */ '@/views/Signature/Review'),
		meta: { ...meta() }
	},
	{
		path: 'ploc/product/type',
		name: 'PLOCProductType',
		component: () => import(/* webpackChunkName: "PLOCProductType" */ '@/views/Personal/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'ploc/review',
		name: 'PLOCLoanReview',
		component: () => import(/* webpackChunkName: "PLOCLoanReview" */ '@/views/Personal/Review'),
		meta: { ...meta() }
	},
	{
		path: 'creditcard/product/type',
		name: 'CreditCardProductType',
		component: () => import(/* webpackChunkName: "CreditCardProductType" */ '@/views/CreditCard/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'creditcard/review',
		name: 'CreditCardReview',
		component: () => import(/* webpackChunkName: "CreditCardReview" */ '@/views/CreditCard/Review'),
		meta: { ...meta() }
	},
	{
		path: 'seasonal/product/type',
		name: 'SeasonalProductType',
		component: () => import(/* webpackChunkName: "SeasonalProductType" */ '@/views/Seasonal/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'seasonal/review',
		name: 'SeasonalReview',
		component: () => import(/* webpackChunkName: "SeasonalReview" */ '@/views/Seasonal/Review'),
		meta: { ...meta() }
	},
	{
		path: 'motorcycle/product/details',
		name: 'MotorcycleProductDetails',
		component: () => import(/* webpackChunkName: "MotorcycleProductDetails" */ '@/views/Motorcycle/ProductDetails'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'motorcycle/product/type',
		name: 'MotorcycleProductType',
		component: () => import(/* webpackChunkName: "MotorcycleProductType" */ '@/views/Motorcycle/ProductType'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'motorcycle/review',
		name: 'MotorcycleReview',
		component: () => import(/* webpackChunkName: "MotorcycleReview" */ '@/views/Motorcycle/Review'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'boat/product/details',
		name: 'BoatProductDetails',
		component: () => import(/* webpackChunkName: "BoatProductDetails" */ '@/views/Boat/ProductDetails'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'boat/product/type',
		name: 'BoatProductType',
		component: () => import(/* webpackChunkName: "BoatProductType" */ '@/views/Boat/ProductType'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'boat/review',
		name: 'BoatReview',
		component: () => import(/* webpackChunkName: "BoatReview" */ '@/views/Boat/Review'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'rv/product/details',
		name: 'RVProductDetails',
		component: () => import(/* webpackChunkName: "RVProductDetails" */ '@/views/RV/ProductDetails'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'rv/product/type',
		name: 'RVProductType',
		component: () => import(/* webpackChunkName: "RVProductType" */ '@/views/RV/ProductType'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'rv/review',
		name: 'RVReview',
		component: () => import(/* webpackChunkName: "RVReview" */ '@/views/RV/Review'),
		meta: { ...meta(), bypassEnforcer: true, overrideTimeout: true }
	},
	{
		path: 'resume/:applicationToken?',
		name: 'Resume',
		component: () => import(/* webpackChunkName: "Resume" */ '@/views/Resume'),
		meta: {
			backButton: {
				clientWebsite: true
			},
			overflow: false,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'othersecured/product/type',
		name: 'OtherSecuredProductType',
		component: () => import(/* webpackChunkName: "OtherSecuredProductType" */ '@/views/OtherSecured/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'othersecured/review',
		name: 'OtherSecuredReview',
		component: () => import(/* webpackChunkName: "OtherSecuredReview" */ '@/views/OtherSecured/Review'),
		meta: { ...meta() }
	},
	{
		path: 'sharesecured/product/type',
		name: 'ShareSecuredProductType',
		component: () => import(/* webpackChunkName: "ShareSecuredProductType" */ '@/views/ShareSecured/ProductType'),
		meta: { ...meta() }
	},
	{
		path: 'sharesecured/review',
		name: 'ShareSecuredReview',
		component: () => import(/* webpackChunkName: "ShareSecuredReview" */ '@/views/ShareSecured/Review'),
		meta: { ...meta() }
	}
];
