<template functional>

    <g>
        <path class="secondary-fill" d="M9,17.6c-4.7,0-8.6-3.9-8.6-8.6S4.3,0.4,9,0.4c4.7,0,8.6,3.9,8.6,8.6S13.7,17.6,9,17.6z M9,1C4.6,1,1,4.6,1,9
            c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C17,4.6,13.4,1,9,1z"/>
        <path class="success-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S14,18,9,18z M9,1C4.6,1,1,4.6,1,9c0,4.4,3.6,8,8,8s8-3.6,8-8
            C17,4.6,13.4,1,9,1z"/>
        <g>
            <ellipse class="success-fill" cx="15.1" cy="2.5" rx="1.9" ry="1.8"/>
            <path style="fill:#FFFFFF;" d="M15.1,4.4c-1.1,0-2-0.9-2-1.9s0.9-1.9,2-1.9c1.1,0,2,0.9,2,1.9S16.2,4.4,15.1,4.4z M15.1,0.7
                c-1,0-1.8,0.8-1.8,1.7s0.8,1.7,1.8,1.7S17,3.4,17,2.5S16.1,0.7,15.1,0.7z"/>
        </g>
        <path style="fill:#FFFFFF;" d="M16.3,1.9c0.1,0.1,0.2,0.2,0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0.4-0.9,0.8-1.3,1.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
            c-0.2-0.3-0.5-0.5-0.7-0.8C14,2.6,14,2.6,14,2.5c0,0,0.1-0.2,0.2-0.2c0,0,0.2-0.2,0.3-0.1c0.1,0.1,0.3,0.3,0.5,0.5c0,0,0,0,0,0
            s0,0,0.1,0c0.2-0.2,0.6-0.5,0.9-0.8C16,1.8,16.2,1.7,16.3,1.9z"/>
        <polygon class="primary-fill" points="8.7,6.4 8.4,6.1 9,5.4 8.4,4.8 8.7,4.5 9.6,5.4 	"/>
        <polygon class="primary-fill" points="5.8,12.3 5.4,12.3 5.6,11 6.9,11.1 6.8,11.5 5.9,11.4 	"/>
        <polygon class="primary-fill" points="12,11.4 11.6,10.2 12,10.1 12.2,11 13.1,10.7 13.2,11.1 	"/>
        <path class="primary-fill" d="M5.3,10.7C5.1,10.2,5,9.7,5,9.2c0-2.2,1.8-4,4-4h0.4v0.4H8.9c-2,0-3.6,1.6-3.6,3.6c0,0.5,0.1,0.9,0.3,1.3
            L5.3,10.7z"/>
        <path class="primary-fill" d="M8.9,13.2c-1.4,0-2.6-0.7-3.4-1.9l0.3-0.2c0.7,1,1.8,1.7,3,1.7c1,0,2-0.4,2.6-1.2l0.3,0.3
            C11.1,12.7,10.1,13.2,8.9,13.2z"/>
        <path class="primary-fill" d="M12.3,11.3l-0.3-0.2c0.4-0.6,0.5-1.2,0.5-1.9c0-1.6-1.1-3-2.6-3.4L10,5.4c1.7,0.5,2.9,2,2.9,3.8
            C12.9,9.9,12.7,10.7,12.3,11.3z"/>
        <path class="primary-fill" d="M9.1,10.4H8.7c-0.3,0-0.6-0.3-0.6-0.6V9.6h0.4v0.2c0,0.1,0.1,0.2,0.2,0.2h0.4c0.1,0,0.2-0.1,0.2-0.2V9.7
            c0,0,0-0.1-0.1-0.1L8.5,9.4C8.3,9.3,8.1,9.1,8.1,8.9V8.8c0-0.3,0.3-0.6,0.6-0.6h0.4c0.3,0,0.6,0.3,0.6,0.6V9H9.3V8.8
            c0-0.1-0.1-0.2-0.2-0.2H8.7c-0.1,0-0.2,0.1-0.2,0.2v0.1C8.5,8.9,8.6,9,8.6,9l0.7,0.2c0.2,0.1,0.4,0.3,0.4,0.5v0.1
            C9.7,10.1,9.4,10.4,9.1,10.4z"/>
        <rect x="8.7" y="7.8" class="primary-fill" width="0.4" height="0.6"/>
        <rect x="8.7" y="10.2" class="primary-fill" width="0.4" height="0.6"/>
        <path class="primary-fill" d="M8.9,11.8c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6C11.5,10.6,10.3,11.8,8.9,11.8z
            M8.9,7C7.7,7,6.7,8,6.7,9.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S10.1,7,8.9,7z"/>
    </g>

</template>





<script>

    export default {

        name: 'UsedSelected'

    }

</script>