<template functional>
	<polygon points="5.6,0 3.4,2.1 10.3,9 3.4,15.9 5.6,18 14.6,9 " />
</template>

<script>
	// 256 x 256
	export default {
		name: 'ChevronRightIcon'
	};
</script>
