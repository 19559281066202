export default {
    validate: value => {

        if (value && value.length) {

            //Encompasses all ASCII printable characters from SPACE to DELETE
            const isValid = value.match(new RegExp("^[\x20-\x7F\n]*$", 'i'));

            if (!isValid) { return `Valid characters are Aa-Zz, 0-9, and simple punctuation.` };

        }

        return true;

    }
};