export default {
    NONE: 0,
    YEAR: 1,
    MAKE: 2,
    MODEL: 3,
    TRIM: 4,
    TYPE: 5,
    0: "None",
    1: "Year",
    2: "Make",
    3: "Model",
    4: "Trim",
    5: "Type"
};
