<template functional>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - 
        https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path d="M285.1 6.8c-4-5.7-11.3-8.2-17.9-6.1S256 9 256 16V336c0 8.8 7.2 16 16 16H496c6 0 
          11.4-3.3 14.2-8.6s2.3-11.7-1.1-16.6l-224-320zM288 320V66.8L465.3 320H288zM212.1 
          96.5c-7-1.9-14.4 1.2-18 7.5l-128 224c-2.8 5-2.8 11 .1 16s8.1 8 13.8 8H208c8.8 0 16-7.2 
          16-16V112c0-7.3-4.9-13.6-11.9-15.5zM107.6 320L192 172.2V320H107.6zM42.5 416H533.5c-13.5 
          38.1-49.6 64-90.5 64H133c-40.9 0-77-25.9-90.5-64zM21.1 384c-10.6 0-18.3 10.1-15.4 20.3l4 
          14.3C25.3 473.9 75.6 512 133 512H443c57.3 0 107.7-38.1 123.2-93.3l4-14.3c2.9-10.2-4.8-20.3-15.4-20.3H21.1z" />
    </svg>

</template>





<script>

    export default {

        name: 'BoatIcon'

    }

</script>