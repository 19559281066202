import _cloneDeep from 'lodash.clonedeep';
import * as Sentry from '@sentry/vue';

import { applicantAPI, eligibilityAPI, disclosuresAPI, identificationAPI, reviewAPI } from '@/api';
import { MemberType } from '@/constants';

import {
	addressActions,
	addressState,
	resetAddressState,
	applicantState,
	applicantActions,
	applicantMutations,
	resetApplicantState,
	arrayMutations,
	demographicActions,
	demographicState,
	resetDemographicState,
	employmentActions,
	employmentState,
	resetEmploymentState,
	incomeActions,
	incomeState,
	resetIncomeState,
	objectMutations
} from '../shared';

export default {
	namespaced: true,
	state: {
		...addressState,
		...applicantState,
		...demographicState,
		...employmentState,
		...incomeState,
		userName: '',
		memberType: MemberType.NONE,
		identificationId: 0
	},
	mutations: {
		...applicantMutations,
		...arrayMutations,
		...objectMutations,
		setUserData(
			state,
			{
				memberType = 0,
				firstName = '',
				lastName = '',
				emailAddress = '',
				phoneNumber = '',
				userName = '',
				applicantId = 0,
				applicantToken = ''
			}
		) {
			state.memberType = memberType;
			state.firstName = firstName;
			state.lastName = lastName;
			state.emailAddress = emailAddress;
			state.phoneNumber = phoneNumber;
			state.userName = userName;
			state.applicantId = applicantId;
			state.applicantToken = applicantToken;
		},
		resetContainedState(state) {
			state.userName = '';
			state.memberType = MemberType.NONE;
			state.identificationId = 0;
		},
		resetAddressState,
		resetApplicantState,
		resetDemographicState,
		resetEmploymentState,
		resetIncomeState
	},
	actions: {
		...addressActions,
		...applicantActions,
		...demographicActions,
		...employmentActions,
		...incomeActions,
		resetState({ commit }) {
			commit('resetContainedState');
			commit('resetAddressState');
			commit('resetApplicantState');
			commit('resetDemographicState');
			commit('resetEmploymentState');
			commit('resetIncomeState');
		},
		async authenticateSSO({ commit, dispatch }, request) {
			try {
				const {
					applicantId,
					applicationId,
					loanApplicationId,
					firstName,
					lastName,
					hasDuplicateApplication,
					applicationToken,
					isEligibleToUseOLA: isApplicantMembershipEligible,
					captchaAction,
					applicantToken,
					ssoLinkExpired
				} = await applicantAPI.authenticateSSO(request);

				commit('setUserData', {
					memberType: MemberType.EXISTING,
					firstName,
					lastName,
					applicantId,
					hasDuplicateApplication,
					applicantToken
				});

				commit('application/setData', { objName: 'applicationToken', data: applicationToken }, { root: true });
				commit('application/setData', { objName: 'applicationId', data: applicationId }, { root: true });
				commit(
					'application/setData',
					{ objName: 'loanApplicationId', data: loanApplicationId },
					{ root: true }
				);
				dispatch('personVerification/initDeviceId', {}, { root: true });

				return { hasDuplicateApplication, isApplicantMembershipEligible, captchaAction, ssoLinkExpired};
			} catch (error) {
				throw error;
			}
		},
		async authenticateApplicant({ commit, dispatch }, request) {
			try {
				const {
					applicantId,
					applicationId,
					loanApplicationId,
					firstName,
					lastName,
					hasDuplicateApplication,
					applicationToken,
					captchaAction,
					isEligibleToUseOLA: isApplicantMembershipEligible,
					applicantToken
				} = await applicantAPI.authenticateApplicant(request);

				commit('setUserData', {
					memberType: MemberType.EXISTING,
					firstName,
					lastName,
					applicantId,
					hasDuplicateApplication,
					applicantToken
				});

				commit('application/setData', { objName: 'applicationToken', data: applicationToken }, { root: true });
				commit('application/setData', { objName: 'applicationId', data: applicationId }, { root: true });
				commit(
					'application/setData',
					{ objName: 'loanApplicationId', data: loanApplicationId },
					{ root: true }
				);
				dispatch('personVerification/initDeviceId', {}, { root: true });
				return { hasDuplicateApplication, isApplicantMembershipEligible, captchaAction };
			} catch (error) {
				throw error;
			}
		},
		async createApplicant({ commit, dispatch }, request) {
			try {
				const {
					applicantId,
					applicationId,
					loanApplicationId,
					isApplicantMembershipEligible,
					captchaAction,
					applicationToken,
					applicantToken
				} = await applicantAPI.createApplicant(request);

				const { firstName, lastName, emailAddress, phoneNumber } = request;

				commit('setUserData', {
					memberType: MemberType.NEW,
					firstName,
					lastName,
					emailAddress,
					phoneNumber,
					applicantId,
					applicantToken
				});

				commit('application/setData', { objName: 'applicationToken', data: applicationToken }, { root: true });
				commit('application/setData', { objName: 'applicationId', data: applicationId }, { root: true });
				commit(
					'application/setData',
					{ objName: 'loanApplicationId', data: loanApplicationId },
					{ root: true }
				);

				dispatch('personVerification/initDeviceId', {}, { root: true });

				return { isApplicantMembershipEligible, captchaAction };
			} catch (error) {
				throw error;
			}
		},
		async createEligibilityResponse({ state }, eligibility) {
			try {
				return await eligibilityAPI.recordEligibilityResponse({ applicantId: state.applicantId, eligibility });
			} catch (error) {
				throw error;
			}
		},
		async getEligibility({ state }) {
			try {
				return await eligibilityAPI.getEligibility({ applicantId: state.applicantId });
			} catch (error) {
				throw error;
			}
		},
		async getIdentification({ commit, state }, request) {
			try {
				request.applicantToken = state.applicantToken;

				const {
					dateOfBirth,
					identification,
					citizenshipStatusConfigurationId,
					maritalStatusConfigurationId,
					taxpayerIdentificationNumber,
					emailAddress,
					phoneNumber,
					captchaAction,
					isRecentGraduate,
					phoneTypeConfigurationId,
					preferredContactMethodConfigurationId,
					isFirstTimeAutoBuyer,
					taxpayerIdentificationNumberConfigurationId
				} = await identificationAPI.getIdentification(request);

				commit('setData', { objName: 'identificationId', data: identification.id });

				return {
					dateOfBirth,
					identification,
					citizenshipStatusConfigurationId,
					maritalStatusConfigurationId,
					taxpayerIdentificationNumber,
					emailAddress,
					phoneNumber,
					captchaAction,
					isRecentGraduate,
					phoneTypeConfigurationId,
					preferredContactMethodConfigurationId,
					isFirstTimeAutoBuyer,
					taxpayerIdentificationNumberConfigurationId
				};
			} catch (error) {
				throw error;
			}
		},
		async createIdentification({ commit, state }, request) {
			try {
				request.identification.applicantId = state.applicantId;
				request.applicantId = state.applicantId;

				const { identification, ...rest } = await identificationAPI.createIdentification(request);

				commit('setData', { objName: 'identificationId', data: identification.id });

				return { identification, ...rest };
			} catch (error) {
				throw error;
			}
		},
		async updateIdentification({ commit, state }, request) {
			try {
				request.identification.applicantId = state.applicantId;
				request.applicantId = state.applicantId;
				const hasDuplicateApplication = await identificationAPI.updateIdentification(request);

				return { request, hasDuplicateApplication };
			} catch (error) {
				throw error;
			}
		},
		async acceptDisclosure({ state }, disclosureConfigurationId) {
			try {
				return await disclosuresAPI.acceptDisclosure({
					applicantId: state.applicantId,
					disclosureConfigurationId
				});
			} catch (error) {
				throw error;
			}
		},
		async getDisclosure({ state }) {
			try {
				return await disclosuresAPI.getDisclosureAcceptance({
					applicantId: state.applicantId
				});
			} catch (error) {
				throw error;
			}
		},
		async confirmReview({ state }) {
			try {
				return await reviewAPI.confirm(state.applicantId);
			} catch (error) {
				throw error;
			}
		},
		async resumeApplicant({ commit }, data) {
			if (data && data.applicant) {
				commit('setUserData', {
					memberType: data.applicant.memberType,
					firstName: data.applicant.firstName,
					lastName: data.applicant.lastName,
					emailAddress: data.applicant.emailAddress,
					phoneNumber: data.applicant.phoneNumber,
					applicantId: data.applicant.applicantId,
					applicantToken: data.applicantToken
				});
			}
		}
	},
	getters: {
		hasApplicant: state => state.firstName && state.lastName && state.memberType,
		fullName: state => (state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : ''),
		isMember: state => state.memberType === MemberType.EXISTING
	}
};
