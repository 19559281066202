<template functional>

    <g>
        <path style="display:none;fill:#FFFFFF;" d="M45.8-27.1c0.4,0.5,1.2,1.1,0.4,1.9c-0.2,0.2-0.4,0.3-0.6,0.4c-2.2,1.8-4.3,3.8-6.5,5.6
            C39-19.1,38.9-19,38.7-19c-0.1,0-0.2-0.1-0.3-0.2c-1.2-1.3-2.3-2.6-3.5-3.9c-0.4-0.4-0.7-0.5-0.6-1c0,0,0.3-0.8,1-1.2
            c0,0,0.9-0.9,1.4-0.4c0.7,0.6,1.6,1.7,2.3,2.5c0.1,0.1,0.1,0.1,0.2,0.1s0.2-0.1,0.3-0.2c1-0.9,3-2.7,4.3-3.9
            C44.2-27.6,45.2-27.9,45.8-27.1z"/>
        <g>
            <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9c0,4.6,3.8,8.4,8.4,8.4
                c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
            <polygon class="primary-fill" points="8.6,6.2 8.4,6 9,5.3 8.4,4.6 8.6,4.3 9.6,5.3 	"/>
            <polygon class="primary-fill" points="5.7,12.5 5.3,12.4 5.4,11.1 6.8,11.2 6.7,11.6 5.8,11.5 	"/>
            <polygon class="primary-fill" points="12.1,11.6 11.7,10.2 12.1,10.1 12.4,11.1 13.3,10.8 13.4,11.2 	"/>
            <path class="primary-fill" d="M5.1,10.8c-0.2-0.5-0.3-1-0.3-1.6c0-2.3,1.9-4.1,4.1-4.1h0.4v0.4H8.9c-2.1,0-3.7,1.7-3.7,3.7
                c0,0.5,0.1,0.9,0.3,1.4L5.1,10.8z"/>
            <path class="primary-fill" d="M8.9,13.4c-1.4,0-2.7-0.7-3.5-1.9l0.3-0.2c0.7,1.1,1.9,1.8,3.2,1.8c1.1,0,2.1-0.4,2.8-1.2L12,12
                C11.2,12.9,10.1,13.4,8.9,13.4z"/>
            <path class="primary-fill" d="M12.4,11.4l-0.4-0.2c0.4-0.6,0.6-1.3,0.6-2c0-1.7-1.1-3.2-2.8-3.6L10,5.2c1.8,0.5,3.1,2.1,3.1,4
                C13.1,10,12.8,10.7,12.4,11.4z"/>
            <path class="primary-fill" d="M9.1,10.5H8.7c-0.3,0-0.6-0.3-0.6-0.6V9.6h0.4v0.2c0,0.1,0.1,0.2,0.2,0.2h0.4c0.1,0,0.2-0.1,0.2-0.2V9.8
                c0,0,0-0.1-0.1-0.2L8.5,9.4C8.3,9.3,8.1,9.1,8.1,8.9V8.8c0-0.3,0.3-0.6,0.6-0.6h0.4c0.3,0,0.6,0.3,0.6,0.6V9H9.3V8.8
                c0-0.1-0.1-0.2-0.2-0.2H8.7c-0.1,0-0.2,0.1-0.2,0.2v0.1C8.5,8.9,8.5,9,8.6,9l0.7,0.2c0.3,0.1,0.4,0.3,0.4,0.6v0.1
                C9.7,10.2,9.5,10.5,9.1,10.5z"/>
            <rect x="8.7" y="7.8" class="primary-fill" width="0.4" height="0.6"/>
            <rect x="8.7" y="10.2" class="primary-fill" width="0.4" height="0.6"/>
            <path class="primary-fill" d="M8.9,11.9c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7C11.6,10.7,10.4,11.9,8.9,11.9z
                M8.9,6.9c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S10.2,6.9,8.9,6.9z"/>
        </g>
    </g>

</template>





<script>

    export default {

        name: 'Used',

    }

</script>