import getCleanAddress from './getCleanAddress';

export default (addresses, category) => {

    const foundAddress = addresses.find(address => address.category === category)

    return foundAddress
        ? foundAddress
        : getCleanAddress(category);

};