import { getDateString } from '@/utils/dates';
import axios from '@/utils/axios';

const getConfig = async () => {
	try {
		const {
			data: {
				citizenshipStatuses,
				maritalStatuses,
				identificationTypes,
				displayMaritalStatusField,
				displayCitizenshipStatusField,
				displayRecentGraduateField,
				recentGraduateLink,
				displayPhoneTypeField,
				phoneTypes,
				displayPreferredContactMethodField,
				preferredContactMethods,
				displayFirstTimeAutoBuyerField,
				firstTimeAutoBuyerFieldLabel,
				requireFormOfIdentificationEntry,
				taxpayerIdentificationNumberConfigurations,
				countries,
				acceptedCountryCodes
			}
		} = await axios.get(`${process.env.BASE_URL}api/Identification/Configuration`);

		return {
			citizenshipStatuses,
			maritalStatuses,
			identificationTypes,
			displayMaritalStatusField,
			displayCitizenshipStatusField,
			displayRecentGraduateField,
			recentGraduateLink,
			displayPhoneTypeField,
			phoneTypes,
			displayPreferredContactMethodField,
			preferredContactMethods,
			displayFirstTimeAutoBuyerField,
			firstTimeAutoBuyerFieldLabel,
			requireFormOfIdentificationEntry,
			taxpayerIdentificationNumberConfigurations,
			countries,
			acceptedCountryCodes
		};
	} catch (error) {
		throw error;
	}
};

const getIdentification = async request => {
	try {
		const {
			data: {
				dateOfBirth,
				identification,
				citizenshipStatusConfigurationId,
				maritalStatusConfigurationId,
				taxpayerIdentificationNumber,
				phoneNumber,
				emailAddress,
				captchaAction,
				isRecentGraduate,
				phoneTypeConfigurationId,
				preferredContactMethodConfigurationId,
				isFirstTimeAutoBuyer,
				taxpayerIdentificationNumberConfigurationId
			}
		} = await axios.post(`${process.env.BASE_URL}api/Identification/Applicant`, request);

		return {
			dateOfBirth: dateOfBirth ? getDateString(dateOfBirth) : '',
			identification: {
				...identification,
				expirationDate: identification.expirationDate ? getDateString(identification.expirationDate) : '',
				issueDate: identification.issueDate ? getDateString(identification.issueDate) : '',
				identificationTypeConfigurationId: identification.identificationTypeConfigurationId
			},
			citizenshipStatusConfigurationId,
			maritalStatusConfigurationId,
			taxpayerIdentificationNumber,
			phoneNumber,
			emailAddress,
			captchaAction,
			isRecentGraduate,
			phoneTypeConfigurationId,
			preferredContactMethodConfigurationId,
			isFirstTimeAutoBuyer,
			taxpayerIdentificationNumberConfigurationId
		};
	} catch (error) {
		throw error;
	}
};

const createIdentification = async request => {
	try {
		const {
			data: { identificationId, hasDuplicateApplication, emailVerificationResult }
		} = await axios.post(`${process.env.BASE_URL}api/Identification`, request);

		request.identification.id = identificationId;
		request.hasDuplicateApplication = hasDuplicateApplication;
		request.emailVerificationResult = emailVerificationResult;

		return request;
	} catch (error) {
		throw error;
	}
};

const updateIdentification = async request => {
	try {
		const {
			data: { updated, hasDuplicateApplication, emailVerificationResult }
		} = await axios.post(`${process.env.BASE_URL}api/Identification/Update`, request);

		if (!updated) {
			throw 'There was an error updating the identification record';
		}

		request.hasDuplicateApplication = hasDuplicateApplication;
		request.emailVerificationResult = emailVerificationResult;

		return request;
	} catch (error) {
		throw error;
	}
};

export default {
	getConfig,
	getIdentification,
	createIdentification,
	updateIdentification
};

export { getConfig, getIdentification, createIdentification, updateIdentification };
