<template functional>

    <path d="M17.6,44.9c-2.2-0.1-4-1.9-4-4.1V23c0-2.2,1.8-4,4-4.1H46c2.3,0.1,4,1.9,4,4.1v17.8c0,2.2-1.8,4-4,4.1H17.6z
	 M15.5,31.3v9.5c0,1.2,1,2.1,2.1,2.2H46c1.1,0,2.1-1,2.1-2.2v-9.5H15.5z M15.5,29.4h32.6v-3.3H15.5V29.4z M15.5,24.1h32.6V23
	c0-1.2-0.9-2.1-2.1-2.2H17.6c-1.1,0-2.1,1-2.1,2.2V24.1z M10.2,39.7c-1.6,0-3.2-1-3.8-2.6L0.2,20.3c-0.7-2.2,0.4-4.5,2.5-5.3
	l26.6-9.7c1-0.4,2.1-0.3,3.1,0.1c1,0.5,1.7,1.3,2.1,2.3l3.5,9.7l-1.8,0.7l-3.5-9.6c-0.2-0.6-0.6-1-1.1-1.3C31,7,30.5,7,29.9,7.2
	L3.4,16.8c-1.1,0.4-1.7,1.7-1.3,2.8l6.1,16.7c0.4,1.1,1.7,1.7,2.8,1.3l1.2-0.4l0.7,1.8l-1.2,0.4C11.2,39.6,10.7,39.7,10.2,39.7z
	 M25,35.5h-7.3v-1.9H25V35.5z"/>

</template>





<script>

    export default {

        name: 'HollowCardIcon'

    }

</script>