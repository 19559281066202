import axios from '@/utils/axios';

const getConfig = async () => {

    try {

        const {
            data: {
                inputConfigurations
            }
        } = await axios.get(`${process.env.BASE_URL}api/Application/Configuration/FormInput`);

        return { inputConfigurations };

    } catch (error) {

        throw error;

    };

};

export default {
    getConfig,
};

export {
    getConfig,
};