import axios from '@/utils/axios';

const getConfig = async () => {

    try {

        const {
            data: {
                incomeSources
            }
        } = await axios.get(`${process.env.BASE_URL}api/IncomeSource/Configuration`);
        
        return { incomeSources };

    } catch (error) {

        throw error;

    };

};

const getMaxMonthlyIncome = async () => {

    try {

        const {
            data: {
                maxMonthlyIncome
            }
        } = await axios.get(`${process.env.BASE_URL}api/IncomeSource/MaxMonthlyIncome`);

        return { maxMonthlyIncome };

    } catch (error) {

        throw error;

    };

};

const getIncomeSources = async applicantId => {

    try {

        const {
            data: {
                incomeSources
            }
        } = await axios.get(`${process.env.BASE_URL}api/IncomeSource/${applicantId}`);

        return incomeSources;

    } catch (error) {

        throw error;

    };

};

const createIncomeSource = async ({ applicantId, incomeSource }) => {

    try {

        const {
            data: {
                incomeSourceId: id
            }
        } = await axios.post(`${process.env.BASE_URL}api/IncomeSource`, {
            applicantId,
            incomeSource
        });

        return { ...incomeSource, id };

    } catch (error) {

        throw error;

    };

};

const deleteIncomeSource = async ({ applicantId, id }) => {

    try {

        const {
            data: {
                deleted
            }
        } = await axios.post(`${process.env.BASE_URL}api/IncomeSource/Delete`, {
            applicantId,
            incomeSourceId: id
        });

        if (!deleted) { throw 'There was an error removing an additional income record' };

        return id;

    } catch (error) {

        throw error;

    };

};

const updateIncomeSource = async ({ applicantId, incomeSource }) => {

    try {

        const {
            data: {
                updated
            }
        } = await axios.post(`${process.env.BASE_URL}api/IncomeSource/Update`, {
            applicantId,
            incomeSource
        });

        if (!updated) { throw 'There was an error updating an additional income record' };

        return incomeSource;

    } catch (error) {

        throw error;

    };

};

export default {

    getConfig,
    getIncomeSources,
    createIncomeSource,
    deleteIncomeSource,
    updateIncomeSource,
    getMaxMonthlyIncome

};

export {

    getConfig,
    getIncomeSources,
    createIncomeSource,
    deleteIncomeSource,
    updateIncomeSource,
    getMaxMonthlyIncome

};