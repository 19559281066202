import axios from '@/utils/axios';

const getVerificationConfig = async applicantId => {

    try {

        const {
            data: {
                challengeQuestions = [],
                isUnableToContinue = false,
                personVerificationId = 0
            }
        } = await axios.get(`${process.env.BASE_URL}api/Person/Verify/${applicantId}`);

        return {
            questions: challengeQuestions || [],
            isDeclined: isUnableToContinue,
            personVerificationId
        };

    } catch (error) {

        throw new Error(error);

    };

};

const createVerificationResponse = async request => {

    try {

        const {
            data: {
                isDeclined = false
            }
        } = await axios.post(`${process.env.BASE_URL}api/Person/Verify`, request);

        return isDeclined;

    } catch (error) {

        throw error;

    };

};

export default {

    getVerificationConfig,
    createVerificationResponse

};

export {

    getVerificationConfig,
    createVerificationResponse

};