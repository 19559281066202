import getCleanEmployment from './getCleanEmployment';

export default (employment, type) => {

    const foundEmployment = employment.filter(job => job.employmentType === type)

    return foundEmployment.length
        ? foundEmployment
        : [ getCleanEmployment(type) ];

};