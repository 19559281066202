<template>

    <div class="input-container">

        <header :class="[ 'input-header', { 'is-small': isSmall } ]">
            <label
                :class="{ 'has-error': error }"
                :for="inputId"
                data-test-label
            >
                {{ label }}<span v-if="isOptional & !disabled" data-test-optional> (OPTIONAL)</span>
            </label>
            
            <OaoTooltip
                v-if="tooltip"
                :description="filteredInputConfig.helpText"
                :title="filteredInputConfig.helpTextTitle"
                isSmall
            />
        </header>

        <textarea
            v-model.trim="innerValue"
            data-test-input
            :id="inputId"
            :name="label"
            :class="{ 'is-small': isSmall, 'has-error': error }"
            :placeholder="placeholder"
            :disabled="disabled"
            :autocomplete="disableAutocomplete ? 'off' : 'on'"
            :rows="rows"
            @change="$emit('change', $event)"
        />

        <transition name="fade">
            <p
                class="has-error"
                v-if="error"
                data-test-error
            >
                {{ error }}
            </p>
        </transition>
        
    </div>

</template>




<script>
    import { mapState } from 'vuex';

    export default {

        name: 'TextArea',
        components: {

            OaoTooltip: () => import('@/components/OaoTooltip')

        },
        data() {
            return {

                filteredInputConfig: {},
            }
        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            error: {
                type: String,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            id: {
                type: [ String, Number ],
                required: false
            },
            rows: {
                type: Number,
                required: false,
                default: 3
            },
            tooltip: {
                type: Boolean,
                required: false,
                /*validator: ({ title, description }) => title && description,*/
                default: null
            }

        },
        created() {
            this.filterInputConfigs();
        },
        computed: {
            ...mapState('config', ['inputConfigs']),

            inputId() {

                return this.id || `${ this.label.replace(/\s/g, '') }-${ this._uid }`;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    //Keystone can't handle certain special whitespace characters so they need replacing for cases where user copy/pastes info
                    const specialWhitespaceCharacters = new RegExp("[\t\r\n]", "g")
                    val = val.replaceAll(specialWhitespaceCharacters, " ");
                    const repeatingWhitespace = new RegExp("  *", "g");
                    val = val.replaceAll(repeatingWhitespace, " ");

                    this.$emit('input', val);

                }

            }

        },
        methods: {

            filterInputConfigs() {
                
                var currentConfig = this.inputConfigs.find(x => x.helpTextTitle.toLowerCase() == this.label.toLowerCase());
                
                if (currentConfig !== 'undefined') {
                    this.filteredInputConfig = currentConfig;
                }
            },
        }

    }

</script>




<style lang="scss" scoped>

    .input-container {
		
		&:not(:last-child) {
			margin-bottom: 1rem;
        }
        
        .has-error {
            color: var(--danger);
        }

        .input-header {
            display: flex;
            gap: 0.5rem;
            align-items: baseline;

            label {
                display: block;
                color: var(--text-primary);
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
            }

        }

		textarea {
            box-sizing: border-box;
			width: 100%;
			border: 1.5px solid rgb(215, 215, 215);
            background-color: transparent;
			font-size: 1.25rem;
			padding: 0.25rem;

			&::placeholder {
				color: var(--lightGrey);
				font-style: oblique;
            }
            
            &.has-error {
                border-bottom: 1.5px solid var(--danger);
            }

            &:disabled {
                color: var(--lightGrey);
                cursor: not-allowed;
            }

        }
        
        p {
            font-size: 0.85rem;
            margin-top: 0.25rem;
        }

        .is-small {
            width: auto;
            min-width: 12rem;
        }

	}

</style>