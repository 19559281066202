export default category => ({
    id: null,
    residenceStatus: null,
    category,
    street: '',
    secondaryLine: '',
    city: '',
    state: '',
    postalCode: '',
    yearsLived: null,
    monthsLived: null,
    monthlyHousingPaymentAmount: null,
    bypassVerification: false
});