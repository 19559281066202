export default {
    NOT_RUN: 0,
    NOT_TRIGGERED: 1,
    DUPLICATE_DETECTED_CANCELLED: 2,
    DUPLICATE_DETECTED_CONTINUED: 3,
    0: 'NotRun',
    1: 'NotTriggered',
    2: 'DuplicateDetectedCancelled',
    3: 'DuplicateDetectedContinued',
};
