import axiosLib from 'axios';
import logError from './logError';
import store from '@/store';

const axios = axiosLib.create({ timeout: process.env.NODE_ENV === 'production' ? 500000 : 0 });

axios.interceptors.request.use(

    async config => {

        const token = store.getters.xsrfToken;

        if (token) { config.headers['X-XSRF-TOKEN'] = token };

        config.headers['Content-Type'] = 'application/json';

        return config;

    },
    error => {
        return Promise.reject(error);
    }

)

axios.interceptors.response.use(

    async response => { return response; },
    async error => { 
        console.log('axios.interceptors.response', error.config);

		const applicantToken = store.getters["applicant/applicantToken"];
		const applicationToken = store.getters["application/applicationToken"];
		const requestId = error?.requestId || null;

        logError(error, requestId, applicantToken, applicationToken);

        return Promise.reject(error); 
    }

);

export default axios;
export { axios };