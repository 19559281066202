<template functional>

   <g>
        <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path class="primary-fill" d="M13.7,13.7h-1.5v-0.4h1v-3.1h-1V9.7h0.6V9.3h-0.6V8.9h1v0.8h0.4V13.7z M11.9,13.7H6.7V5.2h0.6V4.4H11v0.8h0.8
            V13.7z M10,13.3h1.5V5.6H7.1v7.7h1.5v-1.7H10V13.3z M9,13.3h0.6V12H9V13.3z M7.7,5.2h2.9V4.8H7.7V5.2z M6.3,13.7H4.6v-6h0.6V6.8h1
            v0.4H5.6v0.4h0.6v0.4H5v5.2h1.2V13.7z M10.8,10.8h-0.4v-0.6H10v0.6H9.6v-1h1.2V10.8z M9,10.8H8.5v-0.6H8.1v0.6H7.7v-1H9V10.8z
            M10.8,9.1h-0.4V8.5H10v0.6H9.6v-1h1.2V9.1z M9,9.1H8.5V8.5H8.1v0.6H7.7v-1H9V9.1z M10.8,7.5h-0.4V6.8H10v0.6H9.6v-1h1.2V7.5z
            M9,7.5H8.5V6.8H8.1v0.6H7.7v-1H9V7.5z"/>
    </g>

</template>


<script>

    export default {

        name: 'CondoIcon'
        
    };

</script>
