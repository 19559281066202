<template>

    <div class="input-container" data-test-label>

        <header :class="[ 'input-header', { 'is-small': isSmall } ]">
            <label
                :class="{ 'has-error': error }"
                :for="inputId"
                data-test-label
            >
                {{ label }}<span v-if="isOptional & !disabled" data-test-optional> (OPTIONAL)</span>
            </label>
        </header>

        <select
            :class="{ 'is-small': isSmall, 'has-error': error, 'is-placeholder': value === placeholderValue }"
            :id="inputId"
            :name="label"
            v-model.trim="innerValue"
            :disabled="disabled"
            data-test-input
            :autocomplete="disableAutocomplete ? 'off' : 'on'"
        >
            <option :value="placeholderValue" :disabled="!enablePlaceholder">{{ placeholder }}</option>
            <option
                v-for="option in options"
                :key="option.label"
                :value="option.value"
                :disabled="option.disabled"
            >
                {{ option.label }}
            </option>
        </select>

        <transition name="fade">
            <p
                class="has-error"
                v-if="error"
                data-test-error
            >
                {{ error }}
            </p>
        </transition>

    </div>

</template>


<script>

    export default {

        name: 'SelectField',
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            placeholderValue: {
                required: false,
                default: null
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            error: {
                type: String,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            enablePlaceholder: {
                type: Boolean,
                required: false
            },
            id: {
                type: [String, Number],
                required: false
            }

        },
        computed: {

            inputId() {

                return this.id || `${this.label.replace(/\s/g, '')}-${this._uid}`;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>


<style lang="scss" scoped>

    .input-container {

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .has-error {
            color: var(--danger);
        }

        .input-header {
            align-items: baseline;

            label {
                display: block;
                color: var(--text-primary);
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
            }

        }

        select {
            width: 100%;
            border: none;
            border-bottom: 1.5px solid rgb(215, 215, 215);
            background-color: transparent;
            font-size: 1.25rem;
            padding: 0.25rem;
            box-sizing: border-box;

            option {
                color: black;
                font-style: normal;
            }

            option:disabled,
            &.is-placeholder {
                color: var(--lightGrey);
                font-style: oblique;
            }

            &.has-error {
                border-bottom: 1.5px solid var(--danger);
            }

            &.is-small {
                width: auto;
                min-width: 12rem;
            }

        }

        p {
            font-size: 0.85rem;
            margin-top: 0.25rem;
        }

    }

</style>