<template>
    <nav>

        <section class="stretch-section">
            <div class="back-container" v-if="backRoute">

                <a v-if="linkType === 'external'" class="back-button" :href="backRoute" :title="backTitle">
                    <BaseIcon :name="backTitle" height="40" width="40">
                        <ActionArrowIcon />
                    </BaseIcon>
                </a>

                <router-link v-else class="back-button" :to="linkType === 'internal' ? { name: backRoute } : backRoute"
                    :title="backTitle">
                    <BaseIcon :name="backTitle" height="40" width="40">
                        <ActionArrowIcon />
                    </BaseIcon>
                </router-link>

            </div>
        </section>

        <section class="logo-container">
            <img @click="showLeavingModal"
                style="width: 150px"
                :class="`${canTriggerLeavingModal ? 'clickable' : ''}`"
                :src="logo" :alt="`${clientName} Logo`" />
        </section>

        <OaoButtonWrapper v-if="!hideHelp" class="help-wrapper" title="Contact Us" @click="$emit('contact')">
            <BaseIcon name="Contact Us" height="40" width="40" class="contact-us">
                <ContactUsIcon />
            </BaseIcon>
        </OaoButtonWrapper>

    </nav>
</template>





<script>

import { OaoButtonWrapper } from '@/components';
import { ActionArrowIcon, BaseIcon, ContactUsIcon } from '@/components/icons';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {

    name: 'Nav',
    components: {

        OaoButtonWrapper,
        ActionArrowIcon,
        BaseIcon,
        ContactUsIcon

    },
    props: {

        backRoute: {
            type: [Object, String],
            required: false
        },
        backTitle: {
            type: String,
            required: false
        },
        hideHelp: {
            type: Boolean,
            required: false
        }

    },
    data() {
        return {
            displayLeavingModalForMembers: false,
            displayLeavingModalForNonMembers: false,
            leavingReasons: null
        }
    },
    async created() {
        const [
            leaveReasons,
            displayLeavingModalConfig
        ] = await Promise.all([
            this.getLeaveReasons(),
            this.getDisplayLeavingModalConfig()
        ]);

        this.leavingReasons = leaveReasons;
        this.displayLeavingModalForMembers = displayLeavingModalConfig.displayLeavingModalForMembers;
        this.displayLeavingModalForNonMembers = displayLeavingModalConfig.displayLeavingModalForNonMembers
    },
    computed: {
        ...mapState('branding', {
            name: state => state.name,
            logo: state => state.images.logo
        }),
        ...mapState({
            applicationId: (state) => state.application.applicationId
        }),
        ...mapGetters('branding', ['clientName']),
        ...mapGetters('applicant', ['isMember']),
        linkType() {

            if (typeof this.backRoute === 'object') { return 'object' };
            if (this.backRoute.includes('http')) { return 'external' };
            return 'internal';
        },
        canTriggerLeavingModal() {
            return (this.applicationId > 0) ? true : false;
        }

        },
    methods: {
        ...mapActions('modal', ['openLeavingModal', 'closeLeavingModal']),
        ...mapActions('leavingModal', ['getLeaveReasons', 'getDisplayLeavingModalConfig']),
        handleLeavingModalSubmit(selected) {
            this.closeLeavingModal();
            const reasonObject = this.leavingReasons.find(x => x.reason === selected);
            return window.location.replace(reasonObject.externalLink);
        },
        async canDisplayLeavingModalForMembers() {
            return this.displayLeavingModalForMembers;
        },
        async canDisplayLeavingModalForNonMembers() {
            return this.displayLeavingModalForNonMembers;
        },
        async showLeavingModal() {
            if (this.canTriggerLeavingModal) {
                const canDisplay = this.isMember
                    ? await this.canDisplayLeavingModalForMembers()
                    : await this.canDisplayLeavingModalForNonMembers();
                if (canDisplay) {
                    this.openLeavingModal({
                        title: 'Sorry to see you leave!',
                        smallTitle: false,
                        preventClose: this.preventClose,
                        forceShow: true
                    });
                }
            }
        }
    }

};

</script>





<style lang="scss" scoped>
nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    padding: .1rem .25rem 0 .25rem;
    align-items: center;

    .back-container {

        a,
        ::v-deep button {
            color: var(--primary);
        }

        svg {
            height: 100%;
            width: auto;
        }
    }

    .logo-container{

        .clickable{
            cursor: pointer;
        }
    }

    .contact-us {
        color: var(--primary);
    }
}
</style>