<template functional>

    <section class="progress-wrapper">
        <div
            class="progress-bar"
            role="progressbar"
            :aria-valuenow="props.value"
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <div
                :class="[
                    'progress-value',
                    { 'completed': props.value >= 100 }
                ]"
                :style="{ 'width': props.value + '%' }"
            ></div>
        </div>
        <p class="progress-percent">{{ props.value }}%</p>
    </section>
    

</template>




<script>

    export default {

        name: 'ProgressBar',
        props: {

            value: {
                type: Number,
                required: true
            }

        }

    }

</script>




<style lang="scss" scoped>

    .progress-wrapper {
        display: flex;
        margin-bottom: 2rem;

        .progress-bar {
            box-sizing: border-box;
            flex: 1;
            height: 1.875rem;
            border: 2px solid var(--disabled);
            border-radius: 2rem;
            width: 100%;
            background-color: var(--white);
            padding: 0.2rem;

            .progress-value {
                height: 100%;
                border-radius: 2rem;
                background-color: var(--tertiary);

                &.completed { background-color: var(--success) }
            }

        }
        .progress-percent {
            margin-left: 1rem;
            font-size: 1.25rem;
            font-weight: 800;
            color: var(--success);
        }

    }

</style>