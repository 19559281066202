import axios from '@/utils/axios';

const getConfig = async ({ loanTypeId, applicationId }) => {

    try {

        const {
            data: {
                disclosures,
                showSlideShowAtSubmit
            }
        } = await axios.get(`${process.env.BASE_URL}api/Disclosure/Configuration/${loanTypeId}/${applicationId}`);

        return { disclosures, showSlideShowAtSubmit };

    } catch (error) {

        throw error;

    };

};

const getDisclosureAcceptance = async ({ applicantId }) => {

    try {

        await axios.get(`${process.env.BASE_URL}api/Disclosure/${applicantId}`);

        return;

    } catch (error) {

        throw error;

    };

};

const acceptDisclosure = async ({ applicantId, disclosureConfigurationId }) => {

    try {

        const {
            data: {
                id
            }
        } = await axios.post(`${process.env.BASE_URL}api/Disclosure`, {
            applicantId,
            disclosureConfigurationId
        });
        
        return id;

    } catch (error) {

        throw error;

    };

};

export default {

    getConfig,
    acceptDisclosure,
    getDisclosureAcceptance

};

export {

    getConfig,
    acceptDisclosure,
    getDisclosureAcceptance

};