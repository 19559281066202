import getListValuesFromLoanTerms from './getListValuesFromLoanTerms';

export default (configs, amount) => {

    const requestedLoanAmount = Number(amount);

    if (configs.length === 1 || !requestedLoanAmount) { return getListValuesFromLoanTerms(configs[0].loanTerms) };
    
    const matchingConfig = configs.find(({minLoanAmount, maxLoanAmount}) => {

        const min = minLoanAmount || -Infinity;
        const max = maxLoanAmount || Infinity;
        
        return min <= requestedLoanAmount && requestedLoanAmount <= max;

    });

    return getListValuesFromLoanTerms(matchingConfig.loanTerms);

};