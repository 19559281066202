import _cloneDeep from 'lodash.clonedeep';

import { paymentProtectionAPI } from '@/api';
import * as Sentry from "@sentry/vue";

export default {

    namespaced: true,
    state: {

        paymentProtectionSelections: [],

    },
    mutations: {

        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {

            state.paymentProtectionSelections = [];

        }

    },
    actions: {

        async getPaymentProtectionSelections({ commit, rootState }) {

            if (rootState.paymentProtectionSelections && rootState.paymentProtectionSelections.length) { return _cloneDeep(rootState.paymentProtectionSelections) };

            try {
                const applicationId = rootState.application.applicationId;

                const paymentProtectionSelections = await paymentProtectionAPI.getPaymentProtectionSelections(applicationId);

                commit('setData', { objName: 'paymentProtectionSelections', data: paymentProtectionSelections });

                return _cloneDeep(paymentProtectionSelections);

            } catch (error) {
                throw error;

            };

        },
        async updatePaymentProtectionSelections({ commit, rootState }, paymentProtectionSelections) {
            try {
                const applicationId = rootState.application.applicationId;

                const updated = await paymentProtectionAPI.updatePaymentProtectionSelections(applicationId, paymentProtectionSelections);

                commit('setData', { objName: 'paymentProtectionSelections', data: paymentProtectionSelections });

                return updated;

            } catch (error) {
                throw error;
            }
        }
    }

}