export default value => {
	switch (value) {
		case 1:
			return 'Auto';
			break;
		case 2:
			return 'Heloc';
			break;
		case 3:
			return 'AutoRefi';
			break;
		case 4:
			return 'Signature';
			break;
		case 5:
			return 'PLOC';
			break;
		case 6:
			return 'Motorcycle';
			break;
		case 7:
			return 'Boat';
			break;
		case 8:
			return 'RV';
			break;
		case 9:
			return 'CreditCard';
			break;
		case 10:
			return 'ShareSecured';
			break;
		case 11:
			return 'OtherSecured';
			break;
		case 12:
			return 'Seasonal';
			break;
		default:
			return '';
	}
};
