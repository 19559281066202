<template functional>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <!-- Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - 
        https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path d="M416 64c70.7 0 128 57.3 128 128V384H480V168c0-22.1-17.9-40-40-40H360c-22.1 
              0-40 17.9-40 40V384H282.5c-13.2-37.3-48.7-64-90.5-64s-77.4 26.7-90.5 64H80c-26.5 
              0-48-21.5-48-48V112c0-26.5 21.5-48 48-48H416zM80 416H96c0 53 43 96 96 96s96-43 96-96h64
              96 96 32l48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-48 0V192c0-88.4-71.6-160-160-160H80C35.8 
              32 0 67.8 0 112V336c0 44.2 35.8 80 80 80zM448 224H416c-8.8 0-16 7.2-16 16s7.2 16 16 
              16h32V384H352V168c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v56zM128 160H256v64H128V160zm-32 
              0v64c0 17.7 14.3 32 32 32H256c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H128c-17.7 
              0-32 14.3-32 32zm96 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
    </svg>

</template>





<script>

    export default {

        name: 'RVIcon'

    }

</script>