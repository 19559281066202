<template functional>

    <g>
        <g class="primary-fill">
            <path d="M12.6,25.3v-5h20.1v1.4h2V14c0-1.6-1.2-2.8-2.7-2.8H13.4c-1.6,0-2.8,1.3-2.8,2.8v11.3c0,1.5,1.3,2.8,2.8,2.8
                h1.7v-2h-1.7C12.9,26.1,12.6,25.7,12.6,25.3z M12.6,14c0-0.4,0.4-0.8,0.8-0.8h18.5c0.4,0,0.7,0.3,0.7,0.8v0.8H12.6V14z M12.6,16.8
                h20.1v1.6H12.6V16.8z"/>
            <path d="M36.7,21.9H18.2c-1.6,0-2.8,1.3-2.8,2.8V36c0,1.5,1.3,2.8,2.8,2.8h18.5c1.5,0,2.7-1.2,2.7-2.8V24.7
                C39.4,23.1,38.2,21.9,36.7,21.9z M37.4,36c0,0.4-0.3,0.8-0.7,0.8H18.2c-0.5,0-0.8-0.3-0.8-0.8V24.7c0-0.4,0.4-0.8,0.8-0.8h18.5
                c0.4,0,0.7,0.3,0.7,0.8V36z"/>
            <rect x="18.7" y="33.2" width="3" height="2"/>
            <rect x="23.5" y="33.2" width="3" height="2"/>
            <rect x="28.3" y="33.2" width="3" height="2"/>
            <rect x="33.1" y="33.2" width="3" height="2"/>
            <path d="M20.5,31.1h3c1.2,0,2.2-1,2.2-2.2v-1.2c0-1.2-1-2.2-2.2-2.2h-3c-1.2,0-2.2,1-2.2,2.2v1.2
                C18.3,30.1,19.3,31.1,20.5,31.1z M20.3,27.7c0-0.1,0.1-0.2,0.2-0.2h3c0.1,0,0.2,0.1,0.2,0.2v1.2c0,0.1-0.1,0.2-0.2,0.2h-3
                c-0.1,0-0.2-0.1-0.2-0.2V27.7z"/>
        </g>
        <path class="success-fill" d="M36.7,6.1C33.3,4,29.3,2.8,25,2.8C12.8,2.8,2.8,12.8,2.8,25s10,22.2,22.2,22.2s22.2-10,22.2-22.2
            c0-4.8-1.5-9.3-4.2-12.9V12c1-0.2,1.9-0.6,2.6-1.2l0,0c2.8,4,4.4,8.9,4.4,14.2c0,13.9-11.1,25-25,25S0,38.9,0,25S11.1,0,25,0
            c4.7,0,9,1.3,12.7,3.4l0,0C37.2,4.2,36.8,5.1,36.7,6.1L36.7,6.1z M42.1,1.9c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7
            s-4.7-2.1-4.7-4.7S39.5,1.9,42.1,1.9z M42.2,9.6L45.7,6c0.3-0.3,0.3-0.7,0-1s-0.7-0.3-1,0l-3.1,3.1l-1.8-1.8c-0.3-0.3-0.7-0.3-1,0
            s-0.3,0.7,0,1l2.3,2.3c0.1,0.1,0.3,0.2,0.5,0.2C41.8,9.8,42,9.7,42.2,9.6z"/>
    </g>

</template>





<script>

    export default {

        name: 'CardFundingSelected'

    }

</script>