<template functional>

    <g>
        <path class="secondary-fill" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,0.6C4.4,0.6,0.6,4.4,0.6,9
            c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C17.4,4.4,13.6,0.6,9,0.6z"/>
        <path class="primary-fill" d="M14.1,12.7h-10v-0.4h1V9.7L4.5,9c-0.1-0.1-0.1-0.2,0-0.3l1.4-1.4V4.9c0-0.1,0.1-0.2,0.2-0.2h1.2
            c0.1,0,0.2,0.1,0.2,0.2v0.7L9,4.1C9.1,4,9.2,4,9.3,4.1l4.6,4.6C14,8.7,14,8.9,13.9,9L13,9.8c0,0-0.1,0.1-0.1,0.1v2.5h1.2V12.7z
            M8.7,12.3h3.7V9.5L9.2,6.2L5.6,9.7v2.6h1V9.4c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2V12.3z M7.1,12.3h1.2V9.6H7.1V12.3z
            M4.9,8.8l0.5,0.5L9,5.8c0.1-0.1,0.2-0.1,0.3,0l3.6,3.6l0.5-0.5L9.2,4.5L4.9,8.8z M6.3,5.1v1.8L7.1,6v-1H6.3z M11.4,11.3H9.4
            c-0.1,0-0.2-0.1-0.2-0.2V9.4c0-0.1,0.1-0.2,0.2-0.2h2.1c0.1,0,0.2,0.1,0.2,0.2v1.7C11.7,11.2,11.6,11.3,11.4,11.3z M9.6,10.9h1.7
            V9.6H9.6V10.9z"/>
    </g>

</template>




<script>

    export default {

        name: 'SingleFamilyIcon'
        
    };

</script>
