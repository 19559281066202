import _cloneDeep from "lodash.clonedeep";

import { Module } from "vuex";
import { Route } from "vue-router";
import { IRootState } from "..";

export interface IRouteEnforcerState {
    routeHistory: Route[];
}

const routeEnforcer: Module<IRouteEnforcerState, IRootState> = {
    namespaced: true,
    state: {
        routeHistory: [],
    },
    mutations: {
        resetState(state) {
            state.routeHistory = [];
        },
        addHistoryItem(state, { matched, ...route }) {
            state.routeHistory.push(_cloneDeep(route));
        },
        removeLastRoute(state) {
            state.routeHistory.pop();
        },
        replaceHistory(state, routes) {
            state.routeHistory = _cloneDeep(routes);
        },
    },
    actions: {
        forceNavigation({ commit }, incoming) {
            commit("resetState");

            commit("addHistoryItem", incoming);

            return false;
        },
        enforceRoute({ commit, dispatch, getters }, incoming) {
            let appearsInHistory = false;

            if (getters.history.length > 0) {
                for (let i = 0; i < getters.history.length; i++) {
                    if (
                        getters.history[i].fullPath == incoming.fullPath &&
                        getters.current.fullPath != incoming.fullPath
                    ) {
                        appearsInHistory = true;
                    }
                }
            }

            const hasPrevious = !!getters.previous;

            if (hasPrevious && incoming.path === getters.previous.path) {
                if (incoming.meta.forceNavigation) {
                    return dispatch("forceNavigation", incoming);
                }

                commit("removeLastRoute");

                return false;
            }

            if (incoming.meta.bypassEnforcer) {
                if (incoming.meta.forceNavigation) {
                    return dispatch("forceNavigation", incoming);
                }

                if ((!getters.previous || incoming.path !== getters.previous.path)
                    && (!getters.current || incoming.path !== getters.current.path)) { commit('addHistoryItem', incoming) };

                return false;
            }

            const hasCurrent = !!getters.current;
            const fromRoute = incoming.params.fromRoute;

            if (hasCurrent && incoming.path === getters.current.path) {
                return incoming.meta.forceNavigation
                    ? dispatch("forceNavigation", incoming)
                    : false;
            }

            const fromMatchesHistory =
                (hasCurrent && fromRoute === getters.current.path) ||
                (hasPrevious && fromRoute === getters.previous.path);

            if (fromMatchesHistory) {
                if (incoming.meta.forceNavigation) {
                    return dispatch("forceNavigation", incoming);
                }

                commit("addHistoryItem", incoming);

                return false;
            }

            return getters.redirectRoute;
        },
    },
    getters: {
        current: (state) => state.routeHistory[state.routeHistory.length - 1],
        previous: (state) => state.routeHistory[state.routeHistory.length - 2],
        redirectRoute: (_, getters) => getters.current || { name: "Home" },
        history: (state) => state.routeHistory,
    },
};

export default routeEnforcer;
