import errorRoutes from './errorRoutes';
import mainRoutes from './mainRoutes';
import altRoutes from './altRoutes';
import noPaddingAltRoutes from './noPaddingAltRoutes';

export default [
	{
		path: '/',
		name: 'ProductSelect',
		component: () => import(/* webpackChunkName: "ProductSelect" */ '@/views/ProductSelect'),
		meta: {
			backButton: {
				clientWebsite: true
			},
			overflow: true,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			showFooter: true,
			hideNav: false
		}
	},
	{
		path: '/',
		component: () => import(/* webpackChunkName: "DefaultLayout" */ '@/layouts/Default'),
		props: route => ({ overflow: route.meta.overflow ? true : false }),
		children: [...mainRoutes, ...altRoutes, ...errorRoutes]
	},
	{
		path: '/',
		component: () => import(/* webpackChunkName: "NoPaddingLayout" */ '@/layouts/NoPadding'),
		props: route => ({ overflow: route.meta.overflow ? true : false }),
		children: [...noPaddingAltRoutes]
	},
	{
		path: `/*`,
		redirect: { name: 'Error', params: { statusType: 404 } }
	},
	{
		path: '*',
		redirect: to => ({ path: '/' + to.path })
	}
];
