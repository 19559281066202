<template>

    <div class="display-block">
        <p class="title">
            <strong>{{ title }}</strong>
        </p>
        <p v-if="formatter">{{ formattedDescription }}</p>
        <p v-else>{{ description }}</p>
    </div>

</template>





<script>

    import { formatMoney, formatNumber, formatPhoneNumber } from '@/utils';

    export default {

        name: 'ListDisplay',
        props: {

            title: {
                type: String,
                required: true
            },
            description: {
                required: true,
                validator: value => value === null || typeof value === 'string' || typeof value === 'number'
            },
            formatter: {
                type: String,
                validator: value => !value || value === 'phone' || value === 'money' || value === 'number'
            }

        },
        computed: {

            formattedDescription() {

                if (this.formatter === 'phone') { return formatPhoneNumber(this.description) };
                if (this.formatter === 'money') { return formatMoney(this.description) };
                if (this.formatter === 'number') { return formatNumber(this.description) };

                return this.description;

            }

        }

    };

</script>





<style lang="scss" scoped>

    .display-block:not(:last-child) { margin-bottom: 1rem; }
    .display-block .title { margin-bottom: 0.5rem; }

    .blank-input {
        font-size: 1rem;
        background: none;
        outline: 0;
        border: 0;
        padding: 0;
        margin: 0;
    }

    @media screen and (min-width: 850px) {

        .display-block {
            display: grid;
            grid-template-columns: 0.65fr 1fr;
            column-gap: 1rem;

            .title { margin-bottom: 0; }
        }

    }

</style>