export default {
    props: {
        delay: {
            type: Number,
            required: false,
            default: 1000
        },
        play: {
            type: Boolean,
            required: true
        },
        source: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.show = true;
        }, this.delay);
    }
}