<template>
	<div>
		<div id="captchaV3-wrapper">
			<div id="captchav3"></div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { CaptchaImplementation } from '@/constants';

	export default {
		name: 'ReCaptchaV3',
		data() {
			return {
				validationToken: '',
				captchaId: null
			};
		},
		computed: {
			...mapGetters(['captchaV3'])
		},
		watch: {
			async captchaV3(newVal, oldVal) {
				if (newVal !== oldVal && newVal.enabled) {
					if (newVal.triggerAction) {
						this.resetTrigger();
						await this.sendAction(newVal.action);
					}
				}
			},
			async validationToken(newVal, oldVal) {
				if (newVal !== oldVal && newVal) {
					this.updateToken();
				}
			}
		},
		mounted() {
			// Create script tag for reCaptcha and append it as the first child in the #captcha div
			let captchaScript = document.createElement('script');
			captchaScript.setAttribute('type', 'text/javascript');
			captchaScript.setAttribute(
				'src',
				'https://www.google.com/recaptcha/api.js?render=' + this.captchaV3.siteKey
			);

			document.getElementById('captchaV3-wrapper').appendChild(captchaScript);

			this.initCaptcha();
		},
		destroyed() {
			//grecaptcha.reset(this.captchaId);
		},
		methods: {
			...mapActions(['updateCaptcha']),
			updateToken() {
				this.updateCaptcha({
					implementation: CaptchaImplementation.RECAPTCHAV3,
					captcha: { token: this.validationToken }
				});
			},
			resetTrigger() {
				this.updateCaptcha({
					implementation: CaptchaImplementation.RECAPTCHAV3,
					captcha: { triggerAction: false }
				});
			},
			initCaptcha() {
				setTimeout(() => {
					if (typeof grecaptcha === 'undefined' || !grecaptcha.execute) {
						this.initCaptcha();
					} else {
						this.updateCaptcha({
							implementation: CaptchaImplementation.RECAPTCHAV3,
							captcha: {
								ready: true,
								triggerAction: false,
								token: '',
								reset: false
							}
						});
						return this.$emit('loaded');
					}
				}, 200);
			},
			async sendAction(action) {
				let validationToken = '';

				grecaptcha.ready(() => {
					grecaptcha.execute(this.captchaV3.siteKey, { action: action }).then(token => {
						this.validationToken = token;
						validationToken = token;
					});
				});

				while (validationToken == '') {
					await new Promise(r => setTimeout(r, 100));
				}

				return validationToken;
			}
		}
	};
</script>

<style lang="scss" scoped></style>
