var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container"},[_c('header',{class:[
        'input-header',
        {
            'is-small': _vm.isSmall,
            'sr-only': _vm.hideLabel,
        },
    ]},[_c('label',{class:{ 'has-error': _vm.error },attrs:{"for":_vm.inputId,"data-test-label":""}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.isOptional & !_vm.disabled)?_c('span',{attrs:{"data-test-optional":""}},[_vm._v(" (OPTIONAL)")]):_vm._e()]),(_vm.tooltip)?_c('OaoTooltip',{attrs:{"description":_vm.filteredInputConfig.helpText,"title":_vm.filteredInputConfig.helpTextTitle,"isSmall":""}}):_vm._e()],1),_c('div',{staticClass:"input-control-container"},[(_vm.type !== 'date' && !_vm.mask)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.innerValue),expression:"innerValue",modifiers:{"trim":true}}],class:{
            'is-small': _vm.isSmall,
            'has-error': _vm.error,
            'force-capitals': _vm.forceCapitalization,
        },attrs:{"id":_vm.inputId,"name":_vm.label,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"data-test-input":"","autocomplete":_vm.disableAutocomplete ? 'off' : 'on'},domProps:{"value":(_vm.innerValue)},on:{"change":function($event){return _vm.$emit('change', $event)},"blur":[function($event){_vm.focused = false},function($event){return _vm.$forceUpdate()}],"focus":function($event){_vm.focused = true},"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value.trim()}}}):_vm._e(),(_vm.type !== 'date' && _vm.mask)?_c('TheMask',{class:{
            'is-small': _vm.isSmall,
            'has-error': _vm.error,
            'force-capitals': _vm.forceCapitalization,
        },attrs:{"id":_vm.inputId,"name":_vm.label,"type":_vm.type,"placeholder":_vm.placeholder,"mask":_vm.mask,"masked":_vm.maskOutput,"disabled":_vm.disabled,"data-test-input":"","autocomplete":_vm.disableAutocomplete ? 'off' : 'on'},on:{"input":function($event){return _vm.$emit('change', $event)}},nativeOn:{"change":function($event){return _vm.$emit('change', $event)},"blur":function($event){_vm.focused = false},"focus":function($event){_vm.focused = true}},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"innerValue"}}):_vm._e(),(_vm.type === 'date')?_c('TheMask',{class:{
            'is-small': _vm.isSmall,
            'has-error': _vm.error,
            'force-capitals': _vm.forceCapitalization,
        },attrs:{"id":_vm.inputId,"name":_vm.label,"type":"tel","placeholder":_vm.placeholder,"mask":"##/##/####","masked":"","disabled":_vm.disabled,"data-test-input":"","autocomplete":_vm.disableAutocomplete ? 'off' : 'on'},on:{"input":function($event){return _vm.$emit('change', $event)}},nativeOn:{"change":function($event){return _vm.$emit('change', $event)},"blur":function($event){_vm.focused = false},"focus":function($event){_vm.focused = true}},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"innerValue"}}):_vm._e(),_vm._t("input-control")],2),(_vm.sublabel)?_c('p',{staticClass:"input-sublabel"},[(_vm.sublabelWarning)?_c('span',{staticClass:"sublabel-warning"},[_vm._v("⚠")]):_vm._e(),_vm._v(" "+_vm._s(_vm.sublabel)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.error)?_c('p',{staticClass:"has-error",attrs:{"data-test-error":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }