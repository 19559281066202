export default {

    props: {
        play: {
            type: Boolean,
            required: true
        },
        source: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            instance: null,
            completeEvent: 'loopComplete'
        }
    },
    watch: {
        play(shouldPlay) {
            if (this.instance) {
                return shouldPlay ? this.instance.play() : this.instance.stop();
            }
        }
    },
    methods: {
        cleanupListeners() { return this.instance.removeEventListener('loopComplete', this.emitCompleted); },
        emitCompleted() {
            this.$emit('completed');
            
            return this.cleanupListeners();
        },
        createListeners(instance) {
            this.instance = instance;

            return this.instance.addEventListener('loopComplete', this.emitCompleted)
        }
    }

}