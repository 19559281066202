<template>

    <div class="icon-card">
        <OaoCard class="card">

            <aside class="card-content">

                <h2>{{ title }}</h2>

                <slot/>

            </aside>

            <div class="card-menu">

                <div class="icon-top">
                    <BaseIcon
                        :name="iconTop.name"
                        width="48"
                        height="48"
                    >
                        <component :is="iconTop.component"/>
                    </BaseIcon>
                </div>

                <OaoButtonWrapper
                    v-if="iconBottom"
                    class="icon-bottom"
                    @click="$emit('click')"
                >
                    <BaseIcon
                        :name="iconBottom.name"
                        width="48"
                        height="48"
                    >
                        <component :is="iconBottom.component"/>
                    </BaseIcon>
                </OaoButtonWrapper>

            </div>
            
        </OaoCard>

    </div>

</template>





<script>

    import { OaoButtonWrapper, OaoCard } from '@/components';
    import { BaseIcon } from '@/components/icons';

    export default {

        name: 'IconCallout',
        components: {

            OaoButtonWrapper,
            OaoCard,
            BaseIcon

        },
        props: {

            title: {
                type: String,
                required: true
            },
            iconTop: {
                type: Object,
                required: true
            },
            iconBottom: {
                type: Object,
                required: false
            }

        }

    };

</script>





<style lang="scss" scoped>

    .icon-card {
        
        &:not(:last-child) { margin-bottom: 1.5rem; }

        .card,
        ::v-deep .card {
            display: flex;
            gap: 1rem;

            .card-content {
                flex: 1;
                word-break: break-word;

                h2 {
                    flex: 1;
                    font-size: 1.75rem;
                    line-height: 2.1875rem;
                    font-weight: 300;
                    color: var(--text-primary);
                    margin-bottom: 0.5rem;
                }

            }

            .card-menu {
                display: flex;
                flex-direction: column;

                .icon-top {
                    flex: 1;
                    color: var(--tertiary);
                    margin-bottom: 1.75rem;
                }
                .icon-bottom { color: var(--secondary); }

            }

        }

    }

    

</style>