import axios from '@/utils/axios';

const getDeclineConfig = async () => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Decline/Configuration`);

		return content;
	} catch (error) {
		throw error;
	}
};

const getReviewConfig = async productTypeId => {
	try {
		const {
			data: { content, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled }
		} = await axios.get(`${process.env.BASE_URL}api/Review/Configuration/${productTypeId}`);

		return { content, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled };
	} catch (error) {
		throw error;
	}
};

const getReviewError = async applicationId => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Review/${applicationId}`);

		return content;
	} catch (error) {
		throw error;
	}
};

const getDeclinedError = async applicationId => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Decline/${applicationId}`);

		return content;
	} catch (error) {
		throw error;
	}
};

const getEligibilityError = async applicationId => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Error/Eligibility/${applicationId}`);

		return content;
	} catch (error) {
		throw error;
	}
};

const getMembershipEligibilityError = async applicationId => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Error/MembershipEligibility/${applicationId}`);

		return content;
	} catch (error) {
		throw error;
	}
};

const getUnsupportedPropertyError = async applicationId => {
	try {
		const {
			data: { content }
		} = await axios.get(`${process.env.BASE_URL}api/Error/UnsupportedProperty/${applicationId}`);

		return content;
	} catch (error) {
		throw error;
	}
};

export default {
	getDeclineConfig,
	getReviewConfig,
	getReviewError,
	getDeclinedError,
	getEligibilityError,
	getMembershipEligibilityError,
	getUnsupportedPropertyError
};

export {
	getDeclineConfig,
	getReviewConfig,
	getReviewError,
	getDeclinedError,
	getEligibilityError,
	getMembershipEligibilityError,
	getUnsupportedPropertyError
};
