export default (employmentType = null) => ({
    id: null,
    employmentStatusConfigurationId: null,
    enteredEmploymentStatus: '',
    occupationConfigurationId: null,
    employmentType,
    enteredOccupation: '',
    yearsWorked: null,
    monthsWorked: null,
    monthlyIncome: null,
    employer: ''
});