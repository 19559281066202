<template>

    <div class="input-container">

        <header :class="[ 'input-header', { 'is-small': isSmall } ]">
            <label
                :for="inputId"
                data-test-label
            >
                {{ label }}<span v-if="isOptional & !disabled" data-test-optional> (OPTIONAL)</span>
            </label>
        </header>   

        <div class="wrapper">

            <ValidationProvider
                v-for="option in options"
                :key="option.value"
                rules="required"
                :name="`${inputName}-${option.value}`"
                slim
            >
                <OaoSvgRadio
                    v-model="innerValue"
                    :optionValue="option.value"
                    :label="option.label"
                    :icon="option.icon"
                    :selectedIcon="option.selectedIcon"
                    :name="`${inputName}-${option.value}`"
                    :disabled="disabled"
                />
            </ValidationProvider>

        </div>

    </div>

</template>





<script>

    import { ValidationProvider } from 'vee-validate';

    import { OaoSvgRadio } from '@/components/form';

    export default {

        name: 'FormSvgRadioGroup',
        components: {

            ValidationProvider,
            OaoSvgRadio

        },
        props: {

            value: {
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            id: {
                type: [Number, String],
                required: false
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            }

        },
        computed: {

            inputId() {

                return this.id || `${ this.label.replace(/\s/g, '') }-${ this._uid }`;

            },
            inputName() {

                return this.name || this.label;

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>





<style lang="scss" scoped>

    .input-container .input-header {
        align-items: baseline;

        label {
            display: block;
            color: var(--text-primary);
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
            font-weight: 400;
            text-transform: uppercase;
        }

    }

    .input-container .is-small {
        width: auto;
        min-width: 12rem;
    }

    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
    }

    @media only screen and (min-width: 925px) {

        .wrapper { grid-template-columns: 1fr 1fr; }

    }
 

</style>