<template>

    <header :style="{ 'background-image': `url(${image})` }" :class="{ 'can-overflow': overflow }">
        <slot />

    </header>

</template>





<script>

export default {

    name: 'Hero',
    props: {

        overflow: {
            type: Boolean,
            required: false
        },
        image: {
            type: String,
            required: false
        }

    }

};

</script>

<style lang="scss" scoped>
header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 150px;
    background: var(--darkGrey);
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
        flex: 1;
        margin: auto 0;
        width: auto;
        max-width: 60%;
        height: auto;
        max-height: 8rem;
    }

    h1 {
        /*font-size: 1.5rem;*/
        /*line-height: 2rem;*/
        /*font-weight: 400;*/
        text-align: center;
        color: var(--headerCopy);
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
    }
}

@media only screen and (max-width: 850px) {
    header.can-overflow {
        display: block;
        position: absolute;
        top: 0;
        height: 270px;
        padding-top: 50px;

        img {
            margin-top: 2rem;
            margin-bottom: 1rem;
            height: 4.5rem;
        }
    }
}

@media only screen and (min-width: 850px) {
    header {
        box-sizing: border-box;
        padding: 0 1rem;
        height: 100%;

        img {
            margin: 2rem 0;
            max-width: 90%;
        }
    }
}
</style>