<template>

    <video 
           :autoplay="isAutoplay" 
           :muted="isMuted">
        
        <source v-for="source in sources"
                :src="source.url" 
                :type="source.type"
                />

        Your browser does not support videos.

    </video>

</template>





<script>

    export default {

        name: 'VideoPlayer',
        props: {

            useControls: {
                type: Boolean,
                required: false,
                default: false
            },
            isAutoplay: {
                type: Boolean,
                required: false,
                default: false
            },
            isMuted: {
                type: Boolean,
                required: false,
                default: false
            },
            sources: {
                type: Array,
                required: true
            }
        }

    };

</script>





<style lang="scss" scoped>

</style>