import axios from '@/utils/axios';

import { AddressCategory, AddressStatus } from '@/constants';
import { getCleanAddress } from '@/utils';

const handleInvalidAddress = ({ addressSuggestions = [], isInvalidPOBox = false, verifyAddressStatus = AddressStatus.VALID }, address) => ({
    address,
    message: isInvalidPOBox ? 'The address field cannot be a P.O. Box' : 'Unable to validate address',
    suggestions: verifyAddressStatus === AddressStatus.ALTERNATE ? addressSuggestions : []
});

const getConfig = async () => {

    try {

        const {
            data: {
                states,
                residenceHistoryRequiredInMonths,
                additionalResidentialHistoryThresholdInMonths,
                residenceStatuses,
                enablePrimaryAddressAutoComplete,
                enableMailingAddressAutoComplete,
                enableHELOCAddressAutoComplete,
                enableReviewModalAddressAutoComplete,
                enablePreviousAddressAutoComplete
            }
        } = await axios.get(`${process.env.BASE_URL}api/Address/Configuration`);

        return {
            states,
            residenceHistoryRequiredInMonths,
            additionalResidentialHistoryThresholdInMonths,
            residenceStatuses,
            enablePrimaryAddressAutoComplete,
            enableMailingAddressAutoComplete,
            enableHELOCAddressAutoComplete,
            enableReviewModalAddressAutoComplete,
            enablePreviousAddressAutoComplete
        };

    } catch (error) {

        throw error;

    };

};

const getAddressVendorToken = async () => {

    try {

        const {
            data: {
                token
            }
        } = await axios.get(`${process.env.BASE_URL}api/Address/VendorToken`);

        return token;

    } catch (error) {

        throw error;

    };

};

const getPropertyAddressConfig = async () => {

    try {

        const {
            data: {
                autoPopulateCountyByPostalCode,
            }
        } = await axios.get(`${process.env.BASE_URL}api/PropertyAddress/Configuration`);

        return { autoPopulateCountyByPostalCode };

    } catch (error) {

        throw error;

    };

};

const getAddress = async ( applicantId, noAudit ) => {

    if (!applicantId) { return [ getCleanAddress(AddressCategory.PHYSICAL) ] }

    try {

        const {
            data: {
                addresses
            }
        } = await axios.get(`${process.env.BASE_URL}api/Address/${applicantId}/${noAudit}`);


        return addresses;

    } catch (error) {

        throw error;

    };

};

const createAddress = async ({ applicantId, address }) => {

    try {

        const {
            data: {
                addressId: id,
                addressVerification,
                isAddressHistorySufficient
            }
        } = await axios.post(`${process.env.BASE_URL}api/Address`, {
            applicantId,
            address
        });

        const { verifyAddressStatus, addressSuggestions } = addressVerification;

        if (verifyAddressStatus === AddressStatus.VALID) {

            let newAddress = {
                ...address,
                id
            };

            if (addressSuggestions.length) {

                newAddress = {
                    ...newAddress,
                    city: addressSuggestions[0].city,
                    postalCode: addressSuggestions[0].postalCode,
                    state: addressSuggestions[0].state,
                    street: addressSuggestions[0].street,
                    secondaryLine: addressSuggestions[0].secondaryLine
                };

            };

            return { address: newAddress, isAddressHistorySufficient };

        };

        throw handleInvalidAddress(addressVerification, address);

    } catch (error) {

        throw error;

    };

};

const deleteAddress = async ({ applicantId, addressId }) => {

    try {

        const {
            data: {
                deleted
            }
        } = await axios.post(`${process.env.BASE_URL}api/Address/Delete`, {
            applicantId,
            addressId
        });

        if (!deleted) { throw 'There was an error removing an address' };

        return addressId;

    } catch (error) {

        throw error;

    };

};

const updateAddress = async ({ applicantId, address }) => {

    try {

        const {
            data: {
                updated,
                addressVerification,
                isAddressHistorySufficient
            }
        } = await axios.post(`${process.env.BASE_URL}api/Address/Update`, {
            applicantId,
            address
        });

        if (updated) {

            if (addressVerification.addressSuggestions.length) {

                address = {
                    ...address,
                    city: addressVerification.addressSuggestions[0].city,
                    postalCode: addressVerification.addressSuggestions[0].postalCode,
                    state: addressVerification.addressSuggestions[0].state,
                    street: addressVerification.addressSuggestions[0].street,
                    secondaryLine: addressVerification.addressSuggestions[0].secondaryLine
                };

            };

            return { address, isAddressHistorySufficient };

        };

        throw handleInvalidAddress(addressVerification, address);

    } catch (error) {

        throw error;

    };

};

const getCountyOptionsByPostalCode = async ( postalCode ) => {

    try {

        const {
            data: {
                counties
            }
        } = await axios.post(`${process.env.BASE_URL}api/PropertyAddress/Counties`, {
            postalCode
        });

        return { counties };

    } catch (error) {

        throw error;

    };

};

export default {

    getConfig,
    getAddressVendorToken,
    getAddress,
    createAddress,
    deleteAddress,
    updateAddress,
    getPropertyAddressConfig,
    getCountyOptionsByPostalCode

};

export {

    getConfig,
    getAddressVendorToken,
    getAddress,
    createAddress,
    deleteAddress,
    updateAddress,
    getPropertyAddressConfig,
    getCountyOptionsByPostalCode

};