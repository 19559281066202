export default objectArray => {

    const enumObject = {};

    for (const object of objectArray) {

        enumObject[object.id] = object.displayText;
        enumObject[object.displayText.replace(/ /g,"_").toUpperCase()] = object.id;

    };

    return enumObject;

};