<template>
    <OaoFormInput
        v-model="innerValue"
        :label="label"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :dateConfig="dateConfig"
        :mask="mask"
        :validationRules="validationRules"
        :maskOutput="maskOutput"
        :isOptional="isOptional"
        :disableAutocomplete="disableAutocomplete"
        :isSmall="isSmall"
        @change="$emit('change', $event)"
        :id="id"
        :tooltip="tooltip"
        :hideLabel="hideLabel"
        :sublabel="parsedSublabel"
        :sublabelWarning="isOverMaxMonthlyIncome"
    />

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoFormInput } from '@/components';

    export default {

        name: 'FormIncomeInput',
        components: {

            ValidationProvider,
            OaoFormInput

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: false,
                default: 'text'
            },
            placeholder: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [String, Object],
                required: false
            },
            dateConfig: {
                type: Object,
                required: false
            },
            mask: {
                type: [String, Array],
                required: false
            },
            maskOutput: {
                type: Boolean,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            validationId: {
                type: String,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            id: {
                type: [String, Number],
                required: false
            },
            tooltip: {
                type: Boolean,
                required: false
            },
            hideLabel: {
                type: Boolean,
                required: false
            },
            maxMonthlyIncome: {
                type: Number,
                required: false
            },
            sublabelTemplate: {
                type: String,
                required: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },
            isOverMaxMonthlyIncome() {
                if (!this.value || !this.maxMonthlyIncome) { return false; }

                return (this.value >= this.maxMonthlyIncome);
            },
            parsedSublabel() {
                if (!this.value) { return; }

                let parsedString = this.sublabelTemplate.replaceAll("{0}", "$" + Number(this.annualIncome).toLocaleString('en-US'));

                return parsedString;
            },
            annualIncome() {
                if (this.value) {
                    return this.value * 12;
                } else {
                    return 0;
                }
            }
        }

    }

</script>