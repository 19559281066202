import { coApplicantAPI } from '@/api';
import { ApplicationType } from '@/constants';
import {
    addressActions, addressState, resetAddressState,
    applicantState, applicantActions, applicantMutations, resetApplicantState,
    arrayMutations,
    demographicActions, demographicState, resetDemographicState,
    employmentActions, employmentState, resetEmploymentState,
    incomeActions, incomeState, resetIncomeState,
    objectMutations
} from '../shared';

import * as Sentry from "@sentry/vue";

export default {

    namespaced: true,
    state: {

        ...addressState,
        ...applicantState,
        ...demographicState,
        ...employmentState,
        ...incomeState

    },
    mutations: {

        ...applicantMutations,
        ...arrayMutations,
        ...objectMutations,
        resetAddressState,
        resetApplicantState,
        resetDemographicState,
        resetEmploymentState,
        resetIncomeState

    },
    actions: {

        ...addressActions,
        ...applicantActions,
        ...demographicActions,
        ...employmentActions,
        ...incomeActions,
        resetState({ commit }) {

            commit('resetAddressState');
            commit('resetApplicantState');
            commit('resetDemographicState');
            commit('resetEmploymentState');
            commit('resetIncomeState');

        },
        async getCoApplicant({ rootState }) {

            try {

                const applicationId = rootState.application.applicationId;
                const { identification } = await coApplicantAPI.getCoApplicant(applicationId);

                return {
                    identification
                };

            } catch (error) {
                throw error;
            };

        },
        async createCoApplicantSelection({ commit, rootState }, hasCoApplicant) {

            try {

                const updated = await coApplicantAPI.createCoApplicantSelection({ applicationId: rootState.application.applicationId, hasCoApplicant });

                commit('application/setData',
                    {
                        objName: 'applicationType',
                        data: hasCoApplicant ? ApplicationType.JOINT : ApplicationType.SINGLE
                    },
                    { root: true });

                return updated;

            } catch (error) {
                throw error;
            };

        },
        async createCoApplicant({ commit, rootState }, coApplicant) {

            try {

                const applicationId = rootState.application.applicationId;
                const { applicantId, emailVerificationResult } = await coApplicantAPI.createCoApplicant({ applicationId, coApplicant });

                commit('setData', { objName: 'applicantId', data: applicantId });
                commit('setApplicant', coApplicant);

                return {
                    applicantId: applicantId, emailVerificationResult: emailVerificationResult
                };

            } catch (error) {
                throw error;
            };

        },
        async resumeApplicant({ commit }, applicant) {

            if (applicant) {

                commit('setData', { objName: 'applicantId', data: applicant.applicantId });

            }

        },
        async updateCoApplicant({ commit, rootState }, applicant) {

            try {
                const applicationId = rootState.application.applicationId;

                const { emailVerificationResult } = await coApplicantAPI.updateCoApplicant({
                    applicationId,
                    applicant
                });

                commit('setApplicant', applicant);

                return {
                    applicantId: applicant, emailVerificationResult: emailVerificationResult
                };

            } catch (error) {
                throw error;

            };

        }
    },
    getters: {
        hasApplicant: state => state.firstName && state.lastName ? true : false,
        fullName: state => state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : ''
    }
};