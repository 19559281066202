import axios from '@/utils/axios';

const createValuation = async request => {

    try {

        const {
            data: {
                price
            }
        } = await axios.post(`${process.env.BASE_URL}api/CollateralValuation`, {
            ...request
        });

        return price;

    } catch (error) {

        throw error;

    }

};

export default {
    createValuation
};
