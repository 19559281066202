import _cloneDeep from 'lodash.clonedeep';

import { leavingModalAPI } from '@/api';
import * as Sentry from "@sentry/vue";

export default {

    namespaced: true,
    state: {

        leaveReasons: [],
        displayLeavingModalForNonMembers: null,
        displayLeavingModalForMembers: null

    },
    mutations: {

        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {

            state.leaveReasons = [];
            state.displayLeavingModalForMembers = null;
            state.displayLeavingModalForNonMembers = null;

        }

    },
    actions: {

        async getLeaveReasons({ commit, state }) {

            if (state.leaveReasons.length) { return _cloneDeep(state.leaveReasons) };

            try {

                const leaveReasons = await leavingModalAPI.getLeaveReasons();

                commit('setData', { objName: 'leaveReasons', data: leaveReasons });

                return _cloneDeep(leaveReasons);

            } catch (error) {
                throw error;
            };

        },

        async getDisplayLeavingModalConfig({ commit, state }) {
            if (
                typeof state.displayLeavingModalForMembers !== 'undefined' &&
                state.displayLeavingModalForMembers !== null &&
                typeof state.displayLeavingModalForNonMembers != 'undefined' &&
                state.displayLeavingModalForNonMembers !== null
            ) {
                return {
                    displayLeavingModalForMembers: state.displayLeavingModalForMembers,
                    displayLeavingModalForNonMembers: state.displayLeavingModalForNonMembers,
                };
            }

            try {
                const {
                    displayLeavingModalForMembers,
                    displayLeavingModalForNonMembers,
                } = await leavingModalAPI.getDisplayLeavingModalConfig();

                commit('setData', { objName: 'displayLeavingModalForMembers', data: displayLeavingModalForMembers });
                commit('setData', { objName: 'displayLeavingModalForNonMembers', data: displayLeavingModalForNonMembers });

                return {
                    displayLeavingModalForMembers,
                    displayLeavingModalForNonMembers,
                };
            } catch (error) {
                throw error;
            }

        },
        async createApplicationLeaveReason({ commit, state }, request) {
            try {

                const leaveReason = await leavingModalAPI.createApplicationLeaveReason(request);

                return leaveReason;

            } catch (error) {
                throw error;
            }
        }
    }

}