import axios from '@/utils/axios';

const getApplicationStatus = async applicationId => {
	try {
		const {
			data: { loanDecision }
		} = await axios.get(`${process.env.BASE_URL}api/Application/Status/${applicationId}`);

		return loanDecision;
	} catch (error) {
		throw error;
	}
};

const submitApplication = async (applicationId, deviceIdSessionToken) => {
	try {
		const {
			data: { loanDecision, submitCompleted }
		} = await axios.post(`${process.env.BASE_URL}api/Application/Submit`, {
			applicationId,
			deviceIdSessionToken
		});

		return { loanDecision, submitCompleted };
	} catch (error) {
		throw error;
	}
};

const updateApplicationDuplicateAnswer = async (applicationId, applicationDuplicateActionType) => {
	try {
		const {
			data: { updated }
		} = await axios.post(`${process.env.BASE_URL}api/Application/Duplicate/Answer`, {
			applicationId,
			applicationDuplicateActionType
		});

		if (!updated) {
			throw 'There was an error updating the duplicate application answer.';
		}

		return updated;
	} catch (error) {
		throw error;
	}
};

const getLoanOffer = async applicationToken => {
	try {
		const {
			data: { loanOffer, loanApplication, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled, missingApplicationToken }
		} = await axios.get(`${process.env.BASE_URL}api/LoanOffer/${applicationToken}`);

		return { loanOffer, loanApplication, primaryDocumentUploadEnabled, coborrowerDocumentUploadEnabled, missingApplicationToken };
	} catch (error) {
		throw error;
	}
};

export default {
	getApplicationStatus,
	submitApplication,
	updateApplicationDuplicateAnswer,
	getLoanOffer
};

export { getApplicationStatus, submitApplication, updateApplicationDuplicateAnswer, getLoanOffer };
