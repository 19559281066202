export default {
    validate: value => {

        if (value && value.length) {

            const isValid = value.match(new RegExp("^[^0-9,]*$", 'i'));

            if (!isValid) { return `The {_field_} field cannot contain numbers or commas.` };

        }

        return true;

    }
};