import { render, staticRenderFns } from "./OaoVideoPlayer.vue?vue&type=template&id=08c8bf22&scoped=true"
import script from "./OaoVideoPlayer.vue?vue&type=script&lang=js"
export * from "./OaoVideoPlayer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c8bf22",
  null
  
)

export default component.exports