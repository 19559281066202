import { render, staticRenderFns } from "./OaoSessionExpired.vue?vue&type=template&id=94fd1660&scoped=true"
import script from "./OaoSessionExpired.vue?vue&type=script&lang=js"
export * from "./OaoSessionExpired.vue?vue&type=script&lang=js"
import style0 from "./OaoSessionExpired.vue?vue&type=style&index=0&id=94fd1660&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94fd1660",
  null
  
)

export default component.exports