import axios from '@/utils/axios';
import { getDateString } from '@/utils/dates';

const createCoApplicantSelection = async request => {
	try {
		const {
			data: { updated }
		} = await axios.post(`${process.env.BASE_URL}api/CoApplicant/Selection`, request);

		// if (!updated) { throw 'There was an error updating the Co-Applicant Selection record' };

		return request;
	} catch (error) {
		throw error;
	}
};

const getCoApplicant = async applicationId => {
	try {
		const {
			data: { identification }
		} = await axios.get(`${process.env.BASE_URL}api/CoApplicant/${applicationId}`);

		return {
			identification: {
				...identification,
				expirationDate: identification.expirationDate ? getDateString(identification.expirationDate) : '',
				issueDate: identification.issueDate ? getDateString(identification.issueDate) : '',
				identificationTypeConfigurationId: identification.identificationTypeConfigurationId
			}
		}
	} catch (error) {
		throw error;
	}
};

const createCoApplicant = async ({ applicationId, coApplicant }) => {
	try {
		const {
			data: { applicantId, emailVerificationResult }
		} = await axios.post(`${process.env.BASE_URL}api/CoApplicant`, {
			applicationId,
			coApplicant
		});

		return {
			applicantId: applicantId,
			emailVerificationResult: emailVerificationResult
		};
	} catch (error) {
		throw error;
	}
};

const updateCoApplicant = async ({ applicationId, applicant }) => {
	try {
		const {
			data: { updated, emailVerificationResult }
		} = await axios.post(`${process.env.BASE_URL}api/CoApplicant/Update`, {
			applicationId,
			applicant
		});

		if (!updated) {
			throw 'There was an error updating the Co-Applicant record';
		}

		return {
			applicant: applicant,
			emailVerificationResult: emailVerificationResult
		};
	} catch (error) {
		throw error;
	}
};

const getModalConfig = async () => {

	try {

		const {
			data: {
				coApplicantModalTitle,
				coApplicantModalContent
			}
		} = await axios.get(`${process.env.BASE_URL}api/CoApplicant/Modal/Configuration`);

		return { coApplicantModalTitle, coApplicantModalContent};

	} catch (error) {

		throw error;

	};

};

const getConfig = async () => {
	try {
		const {
			data: {
				allowCoApplicantFormOfIdentificationEntry,
				coApplicantIdentificationIsRequired
			}
		} = await axios.get(`${process.env.BASE_URL}api/CoApplicant/Configuration`);

		return {
			allowCoApplicantFormOfIdentificationEntry,
			coApplicantIdentificationIsRequired
		};
	} catch (error) {
		throw error;
	}
};

export default {
	createCoApplicantSelection,
	createCoApplicant,
	getCoApplicant,
	getModalConfig,
	getConfig,
	updateCoApplicant
};

export { createCoApplicantSelection, createCoApplicant, getCoApplicant, getModalConfig, getConfig, updateCoApplicant };
