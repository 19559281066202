import getCleanEmployment from './getCleanEmployment';

export default (employment, type) => {

    const foundEmployment = employment.find(job => job.employmentType === type)

    return foundEmployment
        ? foundEmployment
        : getCleanEmployment(type);

};