<template>
	<transition name="fade">
		<section v-show="!loading" class="document-upload-complete box-shadow">
			<div class="icon">
				<div class="sub">
					<BaseIcon :name="icon.name" height="48" width="48">
						<component :is="icon.component" />
					</BaseIcon>
				</div>
				<div class="title">{{ title }}</div>
			</div>
		</section>
	</transition>
</template>

<script>
	import { OaoIconCard, OaoCard } from '@/components';
	import { BaseIcon, CheckIcon } from '@/components/icons';
	import showSpinner from '@/mixins/showSpinner';

	export default {
		name: 'DocumentUploadComplete',
		components: {
			OaoIconCard,
			CheckIcon,
			BaseIcon,
			OaoCard
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				icon: {
					name: 'check',
					component: CheckIcon
				}
			};
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

				return this.showSpinner({ loading: false });
			} catch (error) {
				this.showSpinner({ submitting: false });
				return toast.error(error);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.document-upload-complete {
		background-color: var(--white);
		padding: 1rem;

		.icon {
			background: none;
			border: none;
			padding: 0 0.75rem;
			margin: 0;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: center;
			justify-content: center;
			color: #70b603;
		}

		.title {
			flex: initial;
			margin: 0;
			padding: 0;
			margin-left: 1rem;
			font-size: 1rem;
			color: #333333;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.sub {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		@media only screen and (min-width: 850px) {
			.title {
				font-size: 0.85rem;
			}
		}
	}
</style>
