<template>

    <ValidationProvider
        :rules="completeValidationRules"
        :name="name || label"
        :vid="validationId"
        v-slot="{ errors }"
        slim
    >

        <OaoTextArea
            v-model="innerValue"
            :label="label"
            :placeholder="placeholder"
            :disabled="disabled"
            :error="errors[0]"
            :isOptional="isOptional"
            :disableAutocomplete="disableAutocomplete"
            :isSmall="isSmall"
            @change="$emit('change', $event)"
            :id="id"
            :rows="rows"
            :tooltip="tooltip"
        />

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoTextArea } from '@/components/form';
    import {
        ruleBuilder,
    } from "@/utils";

    ruleBuilder(["custom_ascii_characters"]);

    export default {

        name: 'FormTextArea',
        components: {

            ValidationProvider,
            OaoTextArea

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            validationId: {
                type: String,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            id: {
                type: [ String, Number ],
                required: false
            },
            rows: {
                type: Number,
                required: false
            },
            tooltip: {
                type: Boolean,
                required: false
            }

        },
        computed: {

            completeValidationRules() {
                if (typeof this.validationRules == 'object') {
                    return { ...this.validationRules, custom_ascii_characters: true }
                } else if (typeof this.validationRules == 'string') {
                    return this.validationRules + "|custom_ascii_characters";
                } else if (typeof this.validationRules == 'undefined') {
                    return "custom_ascii_characters";
                }
            },
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>