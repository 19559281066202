<template functional>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - 
        https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path d="M411.8 96H480V64H453.4L411.8 96zM488 128H412.2L467 232.1c14-5.3 29.2-8.1 
              45-8.1c70.7 0 128 57.3 128 128s-57.3 128-128 128s-128-57.3-128-128c0-43.4 21.6-81.8 
              54.7-104.9l-15-28.5C380.5 247.2 352 296.3 352 352v16c0 8.8-7.2 16-16 16H304c-8.8 
              0-16-7.2-16-16v-8c0-92.8-75.2-168-168-168H48c-8.8 0-16-7.2-16-16s7.2-16 16-16h72c41.4 
              0 79.9 12.6 111.8 34.1l127.4-98L342.3 64H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h70.3c11.9 
              0 22.8 6.6 28.3 17.1L385 76.3 436.1 37c4.2-3.2 9.3-5 14.6-5H488c13.3 0 24 10.7 24 24v48c0 
              13.3-10.7 24-24 24zM257.5 214.7c36.7 34.7 60.2 83.3 62.4 137.3h.2c0-68 35.4-127.8 
              88.7-161.9l-34.4-65.3L257.5 214.7zM497.8 359.5l-44.1-83.8c-23 17.5-37.8 45.2-37.8 76.3c0 53 
              43 96 96 96s96-43 96-96s-43-96-96-96c-10.5 0-20.5 1.7-29.9 4.8l44.1 83.8c4.1 7.8 1.1 17.5-6.7 
              21.6s-17.5 1.1-21.6-6.7zM128 256c-53 0-96 43-96 96s43 96 96 96c47.6 0 87.1-34.6 94.7-80h-67c-5.5 
              9.6-15.9 16-27.7 16c-17.7 0-32-14.3-32-32s14.3-32 32-32c11.8 0 22.2 6.4 27.7 
              16h67c-7.6-45.4-47.1-80-94.7-80zM0 352a128 128 0 1 1 256 0A128 128 0 1 1 0 352z" />
    
    </svg>

</template>





<script>

    export default {

        name: 'MotorcycleIcon'

    }

</script>