<template functional>

    <g>
        <g class="primary-fill">
            <path d="M12.6,25.3v-5h20.1v1.4h2V14c0-1.6-1.2-2.8-2.7-2.8H13.4c-1.6,0-2.8,1.3-2.8,2.8v11.3c0,1.5,1.3,2.8,2.8,2.8
                h1.7v-2h-1.7C12.9,26.1,12.6,25.7,12.6,25.3z M12.6,14c0-0.4,0.4-0.8,0.8-0.8h18.5c0.4,0,0.7,0.3,0.7,0.8v0.8H12.6V14z M12.6,16.8
                h20.1v1.6H12.6V16.8z"/>
            <path d="M36.7,21.9H18.2c-1.6,0-2.8,1.3-2.8,2.8V36c0,1.5,1.3,2.8,2.8,2.8h18.5c1.5,0,2.7-1.2,2.7-2.8V24.7
                C39.4,23.1,38.2,21.9,36.7,21.9z M37.4,36c0,0.4-0.3,0.8-0.7,0.8H18.2c-0.5,0-0.8-0.3-0.8-0.8V24.7c0-0.4,0.4-0.8,0.8-0.8h18.5
                c0.4,0,0.7,0.3,0.7,0.8V36z"/>
            <rect x="18.7" y="33.2" width="3" height="2"/>
            <rect x="23.5" y="33.2" width="3" height="2"/>
            <rect x="28.3" y="33.2" width="3" height="2"/>
            <rect x="33.1" y="33.2" width="3" height="2"/>
            <path d="M20.5,31.1h3c1.2,0,2.2-1,2.2-2.2v-1.2c0-1.2-1-2.2-2.2-2.2h-3c-1.2,0-2.2,1-2.2,2.2v1.2
                C18.3,30.1,19.3,31.1,20.5,31.1z M20.3,27.7c0-0.1,0.1-0.2,0.2-0.2h3c0.1,0,0.2,0.1,0.2,0.2v1.2c0,0.1-0.1,0.2-0.2,0.2h-3
                c-0.1,0-0.2-0.1-0.2-0.2V27.7z"/>
        </g>
        <path class="secondary-fill" d="M25,50C11.1,50,0,38.9,0,25S11.1,0,25,0s25,11.1,25,25S38.9,50,25,50z M25,1.7C12.2,1.7,1.7,12.2,1.7,25
            S12.2,48.3,25,48.3S48.3,37.8,48.3,25S37.8,1.7,25,1.7z"/>
    </g>

</template>





<script>

    export default {

        name: 'CardFunding'

    }

</script>