import Vue from 'vue';
import PortalVue from 'portal-vue';
import VTooltip from 'v-tooltip';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { axios, constructUrl } from '@/utils';

Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 20,
	newestOnTop: true,
	position: 'top-center',
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	hideCloseButton: false,
	hideProgressBar: false,
	icon: true
});

Vue.prototype.$axios = axios;
Vue.prototype.$constructUrl = constructUrl;

Vue.config.productionTip = false;
Vue.config.performance = true;

Sentry.init({
	Vue,
	environment: window.location.hostname,
	dsn: 'https://01d12661b65d4016b18ee3204624924d@o1137879.ingest.sentry.io/6191130',
	logErrors: true,
	integrations: [Sentry.replayIntegration()],
	// Set tracesSampleRate to 1.0 to capture 100%
	// Of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.2
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
