var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"name":_vm.title,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{class:[
            'product',
            {
                'is-required': _vm.required,
                'is-selected': !_vm.required && _vm.valuesMatch,
                'is-disabled': _vm.isDisabled,
                'display-only':  _vm.displayOnly
            }
        ],attrs:{"for":_vm.id}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],attrs:{"type":"checkbox","id":_vm.id,"name":_vm.title,"disabled":_vm.isDisabled},domProps:{"value":_vm.optionValue,"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,_vm.optionValue)>-1:(_vm.innerValue)},on:{"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.optionValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}),(_vm.required && !_vm.displayOnly)?_c('p',{staticClass:"required"},[_vm._v("REQUIRED")]):_vm._e(),_c('section',[_c('BaseIcon',{attrs:{"name":_vm.productName,"width":"2.8125rem","height":"2.8125rem"}},[_c(_vm.productIcon,{tag:"component"})],1),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(_vm._s(_vm.description))])])],1),(!_vm.displayOnly)?_c('BaseIcon',{staticClass:"add-button",attrs:{"name":_vm.required || _vm.value === _vm.optionValue ? 'Product Selected' : 'Product Unselected'}},[(_vm.required || _vm.valuesMatch)?_c('SelectedIcon'):_c('AddIcon')],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }