export default {
	NONE: 0,
	DRIVERSLICENSE: 1,
	STATEID: 2,
	PASSPORT: 3,
	MATRICULACONSULAR: 4,
	0: 'None',
	1: 'Drivers License',
	2: 'State ID',
	3: 'Passport',
	4: 'Matricula Consular'
};
