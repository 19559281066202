import axios from '@/utils/axios';

const simulateCreateIdentification = async (
	identificationNumber,
	identificationTypeConfigurationId,
	state,
	issuedDate,
	expirationDate
) => {
	try {
		const { data } = await axios.post(`${process.env.BASE_URL}api/SimulatedUpdate/CreateIdentification`, {
			identificationNumber,
			identificationTypeConfigurationId,
			state,
			issuedDate,
			expirationDate
		});

		return data;
	} catch (error) {
		throw error;
	}
};

const simulateCreatePhone = async phoneNumber => {
	try {
		const { data } = await axios.post(`${process.env.BASE_URL}api/SimulatedUpdate/CreatePhone`, { phoneNumber });

		return data;
	} catch (error) {
		throw error;
	}
};

export default {
	simulateCreateIdentification,
	simulateCreatePhone
};

export { simulateCreateIdentification, simulateCreatePhone };
