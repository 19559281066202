<template functional>

    <g>
        <path class="primary-fill" d="M29.8,46.7c0.1,0,0.2,0,0.3-0.1c8.4-2,14.9-8.7,16.7-17.1l0,0c-2-4.8-6.3-4.8-6.3-4.8
            c-3.9,3.9-6.9,3.3-6.9,3.3c-3.6,0.6-7.5-2.8-7.5-2.8c-5-0.3-6.1,4.4-6.1,4.4c-2.2,4.4-1.1,13.1-1.1,13.1
            C22.3,45,26.6,46.1,29.8,46.7L29.8,46.7z M33.5,9.8c4.6,0,8.3,3.7,8.3,8.3s-3.7,8.3-8.3,8.3s-8.3-3.7-8.3-8.3S28.9,9.8,33.5,9.8z"
            />
        <path fill="#ADB4BA" d="M30.1,46.6c0.2-5.3-0.5-8.2-0.5-8.2c-1.1-5.3-5.6-5.3-5.6-5.3c-3.1,3.1-5.8,2.8-5.8,2.8
            c-3.3,0.3-6.7-2.5-6.7-2.5c-4.2-0.3-5,3.6-5,3.6c0,0,0,0.1-0.1,0.1l0,0c4,6,10.8,10.1,18.6,10.1c1.6,0,3.2-0.2,4.8-0.5 M17.9,20.6
            c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9S11,31.3,11,27.5S14.1,20.6,17.9,20.6z"/>

        <path class="success-fill" d="M36.7,6.1C33.3,4,29.3,2.8,25,2.8C12.8,2.8,2.8,12.8,2.8,25s10,22.2,22.2,22.2s22.2-10,22.2-22.2
            c0-4.8-1.5-9.3-4.2-12.9V12c1-0.2,1.9-0.6,2.6-1.2l0,0c2.8,4,4.4,8.9,4.4,14.2c0,13.9-11.1,25-25,25S0,38.9,0,25S11.1,0,25,0
            c4.7,0,9,1.3,12.7,3.4l0,0C37.2,4.2,36.8,5.1,36.7,6.1L36.7,6.1z M42.1,1.9c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7
            s-4.7-2.1-4.7-4.7S39.5,1.9,42.1,1.9z M42.2,9.6L45.7,6c0.3-0.3,0.3-0.7,0-1s-0.7-0.3-1,0l-3.1,3.1l-1.8-1.8c-0.3-0.3-0.7-0.3-1,0
            s-0.3,0.7,0,1l2.3,2.3c0.1,0.1,0.3,0.2,0.5,0.2C41.8,9.8,42,9.7,42.2,9.6z"/>
    </g>

</template>





<script>

    export default {

        name: 'JointOwnerSelected'

    }

</script>