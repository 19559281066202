import { render, staticRenderFns } from "./OaoCard.vue?vue&type=template&id=1fa0c491&scoped=true&functional=true"
import script from "./OaoCard.vue?vue&type=script&lang=js"
export * from "./OaoCard.vue?vue&type=script&lang=js"
import style0 from "./OaoCard.vue?vue&type=style&index=0&id=1fa0c491&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1fa0c491",
  null
  
)

export default component.exports