export default {
    CITY: 40,
    COUNTY: 64,
    EMAIL: 64,
    EMPLOYER_NAME: 64,
    FIRST_NAME: 30,
    JOB_TITLE: 40,
    LAST_NAME: 30,
    OTHER_INCOME_SOURCE: 40,
    RACE: 64,
    STREET: 50,
    VEHICLE_MAKE: 40,
    VEHICLE_MODEL: 40,
    VEHICLE_IDENTIFICATION_NUMBER: 20,
    VEHICLE_BODY_STYLE: 40
}