import store from '@/store';
import router from '@/router';

export default (name, newParams = {}) => {

    const { params, query, path: fromRoute } = router.currentRoute;

    return {
        name,
        params: {
            ...params,
            ...newParams,
            fromRoute
        },
        query: {
            ...query,
            memberType: store.state.applicant.memberType,
            applicationType: store.state.application.applicationType,
            //applicantId: store.state.applicant.applicantId,
            applicationToken: store.state.application.applicationToken
        }
    };

};