import _cloneDeep from 'lodash.clonedeep';

import { employmentAPI } from '@/api';
import { MemberType } from '@/constants';
import * as Sentry from "@sentry/vue";

const state = {

    employmentList: []

};

function resetState(state) { state.employmentList = [] };

const actions = {

    async getEmploymentHistory({ commit, state }) {

        //if (state.employmentList.length) { return _cloneDeep(state.employmentList) };

        //if (state.memberType === MemberType.NEW) { return [] };

        try {

            const employments = await employmentAPI.getEmploymentHistory(state.applicantId);

            commit('setData', { objName: 'employmentList', data: employments });

            return _cloneDeep(employments);

        } catch (error) {

            throw error;

        };

    },
    async createEmployment({ commit, state }, employment) {

        try {
            
            const {
                employment: createdEmployment,
                isEmploymentHistorySufficient
            } = await employmentAPI.createEmployment({
                applicantId: state.applicantId,
                employment
            });
            
            commit('addItem', { objName: 'employmentList', data: createdEmployment });

            return { employment: createdEmployment, isEmploymentHistorySufficient };

        } catch (error) {

            throw error;

        };

    },
    async deleteEmployment({ commit, state }, { id: employmentId }) {

        try {

            const id = await employmentAPI.deleteEmployment({
                applicantId: state.applicantId,
                employmentId
            });

            commit('deleteItem', { objName: 'employmentList', id });

            return id;

        } catch (error) {

            throw error;

        };

    },
    async updateEmployment({ commit, state }, employment) {

        try {

            const {
                employment: updatedEmployment,
                isEmploymentHistorySufficient
            } = await employmentAPI.updateEmployment({
                applicantId: state.applicantId,
                employment
            });

            commit('updateItem', { objName: 'employmentList', data: updatedEmployment });

            return { employment: updatedEmployment, isEmploymentHistorySufficient };

        } catch (error) {

            throw error;

        };

    }

};

export {

    state,
    resetState,
    actions

};

export default {

    state,
    resetState,
    actions

};