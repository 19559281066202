import * as SmartySDK from 'smartystreets-javascript-sdk';
import * as sdkUtils from 'smartystreets-javascript-sdk-utils';

const SmartyCore = SmartySDK.core;
let websiteKey = ''; // Your website key here
let smartySharedCredentials;
let autoCompleteClient;
let zipCodeLookupClient;
let usStreetClient;

export function formatSuggestion(suggestion) {
	//const secondary = suggestion.secondary ? ` ${suggestion.secondary}` : '';
	//const entries = suggestion.entries > 1 ? ` (${suggestion.entries} more entries)` : '';
	const address = suggestion.streetLine + ' ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zipcode;
	const selected = suggestion.streetLine + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zipcode;
	return {
		address,
		selected
	};
}

export function setSmartyAuthKey(smartyAuthkey) {
	websiteKey = smartyAuthkey;
	smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);
	const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials);
	const usStreetClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials);
	const zipCodeLookupClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials);
	autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();
	zipCodeLookupClient = zipCodeLookupClientBuilder.buildUsZipcodeClient();
	usStreetClient = usStreetClientBuilder.buildUsStreetApiClient();
}

export async function queryAutocompleteForSuggestions(query) {
	const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
	if (query) {
		try {
			let response = await autoCompleteClient.send(lookup);
			response = response.result.filter(suggestion => suggestion.entries === 0);
			this.suggestions = response.map(suggestion => {
				return {
					...suggestion,
					formattedSuggestion: formatSuggestion(suggestion).address
				};
			});
		} catch (e) {
			this.error = e.error;
		}
	} else {
		this.suggestions = [];
	}
}

export async function selectSuggestion(suggestion) {
	this.address1 = suggestion.streetLine;
	this.city = suggestion.city;
	this.state = suggestion.state;
	this.zipCode = suggestion.zipcode;
	this.suggestions = [];
}

export function validateAddress() {
	let lookup = new SmartySDK.usStreet.Lookup();
	lookup.street = this.address1;
	lookup.street2 = this.address2;
	lookup.city = this.city;
	lookup.state = this.state;
	lookup.zipCode = this.zipCode;
	if (!!lookup.street) {
		usStreetClient
			.send(lookup)
			.then(this.updateStateFromValidatedAddress)
			.catch(e => (this.error = e.error));
	} else {
		this.error = 'A street address is required.';
	}
}

export function updateStateFromValidatedAddress(response) {
	const lookup = response.lookups[0];
	const isValid = sdkUtils.isValid(lookup);
	const isAmbiguous = sdkUtils.isAmbiguous(lookup);
	const isMissingSecondary = sdkUtils.isMissingSecondary(lookup);
	if (!isValid) {
		this.error = 'The address is invalid.';
	} else if (isAmbiguous) {
		this.error = 'The address is ambiguous.';
	} else if (isMissingSecondary) {
		this.error = 'The address is missing a secondary number.';
	} else if (isValid) {
		const candidate = lookup.result[0];
		this.address1 = candidate.deliveryLine1;
		this.address2 = candidate.deliveryLine2;
		this.city = candidate.components.cityName;
		this.state = candidate.components.state;
		this.zipCode = candidate.components.zipCode + '-' + candidate.components.plus4Code;
		this.error = '';
	}
}
const getStateAndCityFromZip = async zipCode => {
	try {
		let zipcodeLookupRequest = new Lookup();
		zipcodeLookupRequest.zipCode = zipCode;

		const lookupResponse = await zipCodeLookupClient.send(zipcodeLookupRequest);
		const zipLocation = lookupResponse.lookups[0].result[0].cities[0];

		return { city: zipLocation.city, stateAbbreviation: zipLocation.stateAbbreviation };
	} catch (e) {
		throw e;
	}
};

export { getStateAndCityFromZip };
