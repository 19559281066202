import { render, staticRenderFns } from "./OaoIconCallout.vue?vue&type=template&id=56cb3d26&scoped=true&functional=true"
import script from "./OaoIconCallout.vue?vue&type=script&lang=js"
export * from "./OaoIconCallout.vue?vue&type=script&lang=js"
import style0 from "./OaoIconCallout.vue?vue&type=style&index=0&id=56cb3d26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "56cb3d26",
  null
  
)

export default component.exports