import { render, staticRenderFns } from "./OaoFormSvgRadioGroup.vue?vue&type=template&id=ed6c4804&scoped=true"
import script from "./OaoFormSvgRadioGroup.vue?vue&type=script&lang=js"
export * from "./OaoFormSvgRadioGroup.vue?vue&type=script&lang=js"
import style0 from "./OaoFormSvgRadioGroup.vue?vue&type=style&index=0&id=ed6c4804&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6c4804",
  null
  
)

export default component.exports