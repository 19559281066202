import { AddressCategory } from "@/constants";
import store from "@/store";
import { addressAPI } from '@/api';
import { getAddressByCategory } from "@/utils";
import * as sentry from "@sentry/vue";

export default async (applicantId) => {
    try {
        const addresses = await addressAPI.getAddress(applicantId, true);
        const currentAddress = getAddressByCategory(addresses, AddressCategory.PHYSICAL);

        return currentAddress ?? addresses[0];
    } catch (error) {

        throw error;
    }
};
