export default {
    validate: value => {

        const isValid = value.match(new RegExp("^[a-z .-]+$", 'i'));

        if (!isValid) { return `Valid characters are Aa-Az, periods, and hyphens.` };

        return true;

    }
};