<template functional>

    <g>
        <path style="fill:#325F8B;" d="M13.3,3.5c0.1,0.5,0.2,0.9,0.2,1.7l3,0L17,3.5L13.3,3.5z"/>
        <path style="fill:#325F8B;" d="M5.5,2C5.4,2.7,5.5,3.5,6.2,4.1C6.9,4.7,8.2,5,9,5.6c0.8,0.6,1.3,1.4,1.1,2.3C10,8.8,9.1,9.8,8,10.1
            c-1.1,0.3-2.5,0.1-2.6,0.1c-0.1,0,1.3,0.3,2.5,0.2c1.3-0.1,2.5-0.7,3.4-1.5C12.3,8.1,12.8,7,13,5.8c0.2-1.2-0.1-2.4-0.7-3.4
            c-0.6-1-1.5-1.7-2.4-2C9.1,0,8.3,0,7.7,0s-1,0.2-1.4,0.5C5.9,0.9,5.6,1.4,5.5,2L5.5,2z"/>
        <path style="fill:#5DC9F4;" d="M2.9,0.1h2L2,10.3H0L2.9,0.1z"/>
        <path style="fill:#325F8B;" d="M10.6,0.1c1,0.5,1.3,0.9,2,1.7h4.9L18,0.1L10.6,0.1z"/>
    </g>

</template>





<script>

    export default {

        name: 'DFMark'

    }

</script>