import getCleanAddress from './getCleanAddress';

export default (addresses, category) => {

    const foundAddresses = addresses.filter(address => address.category === category)

    return foundAddresses.length
        ? foundAddresses
        : [ getCleanAddress(category) ];

};