import _cloneDeep from 'lodash.clonedeep';

import { demographicAPI } from '@/api';
import * as Sentry from "@sentry/vue";

const state = {

    hasDemographic: false,
    demographic: null

};

function resetState(state) {

    state.hasDemographic = false;
    state.demographic = null;

};

const actions = {

    async getDemographic({ commit, state }) {

        if (state.demographic) {

            return {
                hasDemographic: state.hasDemographic,
                demographic: _cloneDeep(state.demographic)
            }

        };

        try {
            
            const {
                ethnicGroups,
                genders,
                races
            } = await demographicAPI.getDemographic(state.applicantId);

            const demographic = {
                ethnicGroups: ethnicGroups ? ethnicGroups : [],
                genders: genders ? genders : [],
                races: races ? races : []
            };
            const hasDemographic = !!demographic.ethnicGroups.length || !!demographic.genders.length || !!demographic.races.length;

            commit('setData', { objName: 'hasDemographic', data: hasDemographic });
            commit('setData', { objName: 'demographic', data: demographic });

            return { hasDemographic, demographic: _cloneDeep(demographic) };

        } catch (error) {
            throw error;
        };

    },
    async createDemographic({ commit, state }, demographic) {

        try {
            
            await demographicAPI.createDemographic({ applicantId: state.applicantId, demographic });
            
            commit('setData', { objName: 'hasDemographic', data: true });
            commit('setData', { objName: 'demographic', data: demographic });

            return demographic;

        } catch (error) {
            throw error;
        };

    },
    async updateDemographic({ commit, state }, demographic) {

        try {

            await demographicAPI.updateDemographic({ applicantId: state.applicantId, demographic });

            commit('setData', { objName: 'demographic', data: demographic });

            return demographic;

        } catch (error) {
            throw error;
        };

    },
    async skipDemographic({ commit, state }) {

        try {

            await demographicAPI.skipDemographic(state.applicantId);

            commit('setData', { objName: 'hasDemographic', data: true });

            return state.hasDemographic;

        } catch (error) {
            throw error;
        };

    }

};

export {

    state,
    resetState,
    actions

};

export default {

    state,
    resetState,
    actions

};