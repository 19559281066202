<template>

    <OaoOverlay isSubmit v-if="show" :style="{ visibility: play ? 'visible' : 'hidden' }">
        <NonMemberLoader :source="source" :play="play" />
    </OaoOverlay>

</template>




<script>

import { OaoOverlay } from '@/components';
import NonMemberLoader from './NonMemberLoader';
import overlayData from './overlayData';

export default {

    name: 'NonMemberLoaderOverlay',
    mixins: [overlayData],
    components: {
        OaoOverlay,
        NonMemberLoader
    }

}

</script>