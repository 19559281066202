export default {
    validate: value => {

        const isValid = value.match(new RegExp('^([A-Za-z./]|[0-9]|\\s)+$', 'i'))

        if (!isValid) { return `The {_field_} field can only include letters, numbers, and some symbols` };

        return true;

    }
};