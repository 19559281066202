import { collateralValuationAPI } from "@/api";
import * as sentry from "@sentry/vue";

export default {
    namespaced: true,
    state: {

        valuation: null

    },
    mutations: {

        setData(state, { objName, data }) {
            state[objName] = data;
        },
        resetState(state) {

            state.valuation = null;

        }

    },
    actions: {
        async createValuation({ commit, state }, request) {
            try {
            
                const valuation = await collateralValuationAPI.createValuation(request);

                commit("setData", { objName: "collateralValuation", data: valuation });

                return valuation;

            } catch (error) {
                throw error;
            }
        }
    }
};
