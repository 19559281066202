import store from "@/store";

import handleGetError from "@/utils/handleGetError";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import Router from "vue-router";
import { resetAppState, setPageData } from "./middleware";
import routes from "./routes";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return typeof window.scroll === "function"
            ? window.scroll({ top: 0, left: 0, behavior: "smooth" })
            : { x: 0, y: 0 };
    },
    routes,
});

router.afterEach(async (incoming, last, next) => {
    try {
        if (this.hasModule('product') && incoming.meta.resetState) {
            commit('product/resetState');
            store.unregisterModule('product');
        }
    }
    catch (e) {
       console.log("caught in after each", e) 
    }
});

router.beforeEach(async (incoming, last, next) => {
    try {

        await store.dispatch("branding/setBranding",{
                                hostName: window.location.hostname,
                                productName: 'OLA',
                                themeCode: incoming.query.theme || null
                            });

        await store.dispatch('personVerification/attachDeviceIdSDK');
		await store.dispatch('personVerification/refreshDeviceIdSessionToken');

        // let redirect = await enforceRoute(incoming);
        const redirect = await store.dispatch(
            "routeEnforcer/enforceRoute",
            incoming
        );

        if (redirect) {
            return next();
        }

        if (incoming.meta.resetState) {
            resetAppState();
        }

        await setPageData(incoming);

        if (incoming.meta.bypassEnforcer && !incoming.meta.overrideTimeout) {
            store.commit("sessionTimer/resetTimers");
        } else if (!store.state.sessionTimer.sessionExpired) {
            await store.dispatch("sessionTimer/reset");
        }

        return next();
    } catch (error) {
        return handleGetError(error, incoming);
    }
});

export default router;
