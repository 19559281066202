import {applicationAPI} from '@/api';
import store from '@/store';

export default async (applicationId, duplicateApplicationActionType) => {                // Record in the DB that the member wants to continue
    await applicationAPI.updateApplicationDuplicateAnswer(
        applicationId,
        duplicateApplicationActionType
    );

    // Save the member's choice in vuex so we don't ask again later on
    store.state.application.duplicateApplicationActionType = duplicateApplicationActionType;
};
