import _cloneDeep from 'lodash.clonedeep';

import { addressAPI } from '@/api';

import * as Sentry from "@sentry/vue";

const state = {

    addressList: []
    
};

function resetState(state) { state.addressList = [] };

const actions = {

    async getAddresses({ commit, state }, noAudit = false) {
        
        try {

            const addresses = await addressAPI.getAddress(state.applicantId, noAudit);

            commit('setData', { objName: 'addressList', data: addresses });

            return _cloneDeep(addresses);

        } catch (error) {
            throw error;
        };

    },
    async addAddress({ commit, state }, newAddress) {

        try {

            commit('updateItem', { objName: 'addressList', data: newAddress });

            return true;

        } catch (error) {
            throw error;
        };

    },
    async createAddress({ commit, state }, newAddress) {

        try {

            const {
                address,
                isAddressHistorySufficient
            } = await addressAPI.createAddress({
                applicantId: state.applicantId,
                address: newAddress
            });
            
            commit('addItem', { objName: 'addressList', data: address });

            return { address, isAddressHistorySufficient };

        } catch (error) {
            throw error;
        };

    },
    async deleteAddress({ commit, state }, { id }) {
        
        try {

            const addressId = await addressAPI.deleteAddress({
                applicantId: state.applicantId,
                addressId: id
            });

            commit('deleteItem', { objName: 'addressList', addressId });

            return addressId;

        } catch (error) {
            throw error;
        };

    },
    async updateAddress({ commit, state }, incomingAddress) {

        try {

            const {
                address,
                isAddressHistorySufficient
            } = await addressAPI.updateAddress({
                applicantId: state.applicantId,
                address: incomingAddress
            });

            commit('updateItem', { objName: 'addressList', data: address });

            return { address, isAddressHistorySufficient };

        } catch (error) {
            throw error;
        };

    }

};

export {

    state,
    resetState,
    actions

    };

export default {

    state,
    resetState,
    actions

};